import React from 'react';
import {
    Row,
    Col,
    Form,
    Input,
    Progress,
} from 'reactstrap';
import Select, {components} from "react-select";
import {
    getHouseTypesOptions,
    getSelectedHouseType,
    displayPropertySubType,
    getPropertySubTypesOptions,
    getPropertySubHouseType,
    getBedroomsOptions,
    getBedroomsType,
    getBathroomsOptions,
    getBathroomsType,
    getCarsOptions,
    getCarsType,
    getDefaultMapLat,
    getDefaultMapLng, getClientId, getLoanType,
} from "../../../services/config-helper";
import PropertySnapshot from "../sections/property-snapshot";
import Projection from "../sections/projection";
import {addEditProperty, getProperty} from "../../../services/services";
import config from "config/config";
import {getMyProfile, getNotificationObject, setPropertyTabState} from "../../../services/helper";
import {Redirect} from 'react-router';
import ReactNotification from "react-notifications-component";
import RightButton from "../../../components/properlytics/right-button";
import Autocomplete from 'react-google-autocomplete';
import ExtraRepayment from "../sections/extra-repayment";
import PriceField from "../../../components/numbers/PriceField";

/*------------------------------------*/
/* For Select Plugin - (4)            */
/*------------------------------------*/
const groupStyles = {
    borderRadius: '0px',
    background: '#ffffff',
    padding: "0"
};

const Group = (props) => (
    <div style={groupStyles}>
        <components.Group {...props} />
    </div>
);

const style1 = {
    control: (base, state) => ({
        ...base,
        padding: "0 !important",
        margin: "0 !important",
        border: "0 !important",
        boxShadow: "0 !important",
        "&:hover": {
            border: "0 !important"
        }
    })
};


export default class PropertyDetail extends React.Component {

    constructor(props) {
        super(props);
        let lat = getDefaultMapLat();
        let long = getDefaultMapLng();
        let bed = getBedroomsType().value;
        let bath = getBathroomsType().value;
        let car = getCarsType().value;
        this.state = {
            is_edit: false,
            property_id: '',
            client_id: '',
            property_address: '',
            latitude: lat,
            longitude: long,
            property_type: getSelectedHouseType().value,
            bedrooms: bed,
            bathrooms: bath,
            car_space: car,
            year_build: '',
            builder_name: '',
            purchase_price: '',
            property_sub_type: getPropertySubHouseType().value,
            land_price: '',
            build_price: '',
            adv_link: '',
            progress: 0,

            images: [],
            overview: {},

            /*extra repayment*/
            loan_amount: 0,
            additional_loan_amount: 0,
            interest_rate: 0,
            to_year: 40,
            loan_type: getLoanType().value,
            loan_amount_back: 0,
            additional_loan_amount_back: 0,
            interest_rate_back: 0,
            to_year_back: 40,
            loan_type_back: getLoanType().value,

            property: {},
            loading: false,
            display_sub_type: false,
            redirect_to_dashboard: false,
            next: false,
        }

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();

        this.onAddressChange = this.onAddressChange.bind(this);
        this.onPropertyTypeChange = this.onPropertyTypeChange.bind(this);
        this.onPropertySubTypeChange = this.onPropertySubTypeChange.bind(this);
        this.onBedroomsChange = this.onBedroomsChange.bind(this);
        this.onBathroomsChange = this.onBathroomsChange.bind(this);
        this.onCarsChange = this.onCarsChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.setStateData = this.setStateData.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    onInputChange(event) {
        let name = event.target.name;

        if (name === 'purchase_price' || name === 'land_price' ||
            name === 'build_price' || name === 'year_build'
        ) {
            if (!isNaN(event.target.value) || event.target.value === '') {
                this.setState({
                    [name]: event.target.value
                });
            }
        } else {
            this.setState({
                [name]: event.target.value
            });
        }
    }

    onAddressChange(place) {
        this.setState({
            property_address: place.formatted_address,
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
        });
    }

    onPropertyTypeChange(selected) {
        if (displayPropertySubType(selected.value)) {
            this.setState({
                property_type: selected.value,
                display_sub_type: true,
                property_sub_type: getPropertySubHouseType().value,
            });
        } else {
            this.setState({
                property_type: selected.value,
                display_sub_type: false,
                property_sub_type: getPropertySubHouseType().value,
                land_price: '',
                build_price: '',
            });
        }
    }

    onPropertySubTypeChange(selected) {
        this.setState({
            property_sub_type: selected.value,
        });
    }

    onBedroomsChange (selected) {
        this.setState({
            bedrooms: selected.value,
        });
    }

    onBathroomsChange (selected) {
        this.setState({
            bathrooms: selected.value,
        });
    }

    onCarsChange (selected) {
        this.setState({
            car_space: selected.value,
        });
    }

    onSubmit() {
        this.setState({
            loading: true
        })
        let postData = new FormData();
        if (this.state.is_edit) {
            postData.append('property_id', this.state.property_id);
        }

        postData.append('client_id', this.state.client_id);
        postData.append('property_address', this.state.property_address);
        postData.append('latitude', this.state.latitude);
        postData.append('longitude', this.state.longitude);
        postData.append('property_type', this.state.property_type);
        postData.append('bedrooms', this.state.bedrooms);
        postData.append('bathrooms', this.state.bathrooms);
        postData.append('car_space', this.state.car_space);
        postData.append('year_build', this.state.year_build);
        postData.append('builder_name', this.state.builder_name);
        postData.append('purchase_price', this.state.purchase_price);
        postData.append('property_sub_type', this.state.property_sub_type);
        postData.append('land_price', this.state.land_price);
        postData.append('build_price', this.state.build_price);
        postData.append('year_build', this.state.year_build);
        postData.append('adv_link', this.state.adv_link);

        addEditProperty(postData).then(res => {
            if (res.success) {
                this.showNotification(res.message, 'success')
                let property_id = (typeof res.data.property.property_detail !== 'undefined') ? res.data.property.property_detail.property_id : '';
                this.setState({
                    property_id: property_id
                })
                this.setState({
                    next: true,
                    property: res.data.property
                })
            } else {
                this.showNotification(res.message, 'danger')
            }
            this.setState({
                loading: false
            })
        });
    }

    setStateData(property) {
        this.setState(setPropertyTabState(property));

        this.setState({
            is_edit: true,
        })
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.match.path === config.site_url.property_detail + '/:id') {
            //is edit call
            let property_id = this.props.match.params.id;
            if (property_id) {

                if (typeof this.props.location.property !== 'undefined' && this.props.location.property) {
                    this.setStateData(this.props.location.property);
                } else {
                    let postData = {
                        property_id: property_id
                    }

                    getProperty(postData).then(res => {
                        if (res.success) {
                            this.setStateData(res.data.property);
                        } else {
                            if (res.error) {
                                if (typeof res.error !== 'undefined' && typeof res.error.auth_code !== 'undefined' &&
                                    res.error.auth_code === 401
                                ) {
                                    this.setState({
                                        redirect_to_dashboard: true,
                                    })
                                }
                            }
                            this.showNotification(res.message, 'danger')
                        }
                    });
                }

            } else {
                this.setState({
                    redirect_to_dashboard: true,
                })
            }
        } else {
            let profile = getMyProfile();
            if (profile) {
                if (profile.account_type === 2) {
                    let client_id = (getClientId()) || '';
                    if (client_id === '') {
                        this.setState({
                            redirect_to_dashboard: true,
                        })
                    } else {
                        this.setState({
                            client_id: client_id
                        })
                    }
                }
            }
        }
    }

    render() {

        //if business account and client value not pass as params then redirect to dashboard
        if (this.state.redirect_to_dashboard) {
            return <Redirect to={config.site_url.dashboard} />;
        }

        //if next success then redirect to next tab
        if (this.state.next) {
            return <Redirect
                to={{
                    pathname: config.site_url.acquisition_cost + '/' + this.state.property_id,
                    property: this.state.property
                }}
            />;
        }


        return <div className="">

            <ReactNotification ref={this.notificationDOMRef} />

            <Row>
                <Col md="6">
                    {/*--------------------------------------------------------------------------------*/}
                    {/*PROPERTY DETAIL*/}
                    {/*--------------------------------------------------------------------------------*/}
                    <div className="prpty_flipper blocks ui-sortable">
                        <div className="block">
                            <div className="plisting_form rect_bx flip_bx">
                                <div className="head_tron d-flex">
                                    <h2 className="head  fx_w t_green">Property Details</h2>

                                    <Progress value={this.state.progress}/>

                                    <div className="progress-no t_green">{this.state.progress}%</div>
                                </div>

                                <div className="inner_bx">
                                    <Form id="prp_detail">
                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Property Address*</h4>
                                                <Autocomplete
                                                    className="form-control"
                                                    onPlaceSelected={this.onAddressChange}
                                                    types={[]}
                                                    name="property_address"
                                                    value={this.state.property_address}
                                                    onChange={this.onInputChange}
                                                    componentRestrictions={{country: "au"}}
                                                />
                                            </div>
                                        </div>

                                        <div className="tp_row pbtm0 ptop0">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Property Type*</h4>
                                                <div className="slct d-flex">
                                                    <div className="p_type_opt">
                                                        <Select
                                                            defaultValue={getSelectedHouseType()}
                                                            options={getHouseTypesOptions()}
                                                            components={{ Group }}
                                                            styles={style1}
                                                            onChange={this.onPropertyTypeChange}
                                                            value={getSelectedHouseType(this.state.property_type)}
                                                        />
                                                    </div>

                                                    <div className={this.state.display_sub_type ? "p_type_cat" : "hide"}>
                                                        <Select
                                                            defaultValue={getPropertySubHouseType()}
                                                            options={getPropertySubTypesOptions()}
                                                            components={{ Group }}
                                                            styles={style1}
                                                            onChange={this.onPropertySubTypeChange}
                                                            value={getPropertySubHouseType(this.state.property_sub_type)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Purchase Price*</h4>
                                                <PriceField
                                                    placeholder="Enter Property Price"
                                                    name="purchase_price"
                                                    value={this.state.purchase_price}
                                                    onChange={this.onInputChange}
                                                />
                                            </div>
                                        </div>

                                        <div className={this.state.display_sub_type ? "tp_row" : "hide"}>
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Land Price*</h4>
                                                <PriceField
                                                    placeholder="Enter Land Price"
                                                    name="land_price"
                                                    value={this.state.land_price}
                                                    onChange={this.onInputChange}
                                                />
                                            </div>
                                        </div>

                                        <div className={this.state.display_sub_type ? "tp_row" : "hide"}>
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Build Price*</h4>
                                                <PriceField
                                                    placeholder="Enter Build Price"
                                                    name="build_price"
                                                    value={this.state.build_price}
                                                    onChange={this.onInputChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Bedrooms*</h4>
                                                <div className="slct">
                                                    <Select
                                                        defaultValue={getBedroomsType()}
                                                        options={getBedroomsOptions()}
                                                        components={{Group}}
                                                        styles={style1}
                                                        onChange={this.onBedroomsChange}
                                                        value={getBedroomsType(this.state.bedrooms)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Bathrooms</h4>
                                                <div className="slct">
                                                    <Select
                                                        defaultValue={getBathroomsType()}
                                                        options={getBathroomsOptions()}
                                                        components={{Group}}
                                                        styles={style1}
                                                        onChange={this.onBathroomsChange}
                                                        value={getBathroomsType(this.state.bathrooms)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Cars*</h4>
                                                <div className="slct">
                                                    <Select
                                                        defaultValue={getCarsType()}
                                                        options={getCarsOptions()}
                                                        components={{Group}}
                                                        styles={style1}
                                                        onChange={this.onCarsChange}
                                                        value={getCarsType(this.state.car_space)}
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Builder</h4>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Builder Name"
                                                    name="builder_name"
                                                    id="builder_name"
                                                    value={this.state.builder_name}
                                                    onChange={this.onInputChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Year Built*</h4>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Built Year"
                                                    maxLength={4}
                                                    name="year_build"
                                                    id="year_build"
                                                    value={this.state.year_build}
                                                    onChange={this.onInputChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Advertised Link</h4>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Advertised Link"
                                                    name="adv_link"
                                                    id="adv_link"
                                                    value={this.state.adv_link}
                                                    onChange={this.onInputChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="tp_row_btm">
                                            <RightButton text="Next" onClick={this.onSubmit} loading={this.state.loading} />
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <PropertySnapshot
                        property_id={this.state.property_id}
                        lat={this.state.latitude}
                        lng={this.state.longitude}
                        address={this.state.property_address}
                        price={this.state.purchase_price}
                        bed={this.state.bedrooms}
                        bath={this.state.bathrooms}
                        car={this.state.car_space}
                        adv_link={this.state.adv_link}
                        images={this.state.images}
                        showNotification={this.showNotification}
                    />
                </Col>

                <Col md={6}>
                    <Projection
                        property_id={this.state.property_id}
                        progress={this.state.progress}
                        overview={this.state.overview}
                        showNotification={this.showNotification}
                    />

                    <ExtraRepayment
                        property_id={this.state.property_id}
                        loan_amount={this.state.loan_amount}
                        additional_loan_amount={this.state.additional_loan_amount}
                        interest_rate={this.state.interest_rate}
                        to_year={this.state.to_year}
                        loan_type={this.state.loan_type}
                        loan_amount_back={this.state.loan_amount_back}
                        additional_loan_amount_back={this.state.additional_loan_amount_back}
                        interest_rate_back={this.state.interest_rate_back}
                        to_year_back={this.state.to_year_back}
                        loan_type_back={this.state.loan_type_back}
                    />
                </Col>
            </Row>
        </div>

    }
}
