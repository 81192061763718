import React from 'react';
import {
    Row,
    Col,
    Card,
    CardTitle,
    CardBody,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Form,
} from 'reactstrap';
import {addClient, editClient, getBase64Image, getClient} from "../../services/services";
import {dataURLtoFile, getNotificationObject} from "../../services/helper";
import ReactNotification from "react-notifications-component";
import validator from "./client-validators";
import {Redirect} from 'react-router';
import config from 'config/config';
import moment from 'moment';
import RightButton from "../../components/properlytics/right-button";
import LeftLink from "../../components/properlytics/left-link";
import Autocomplete from 'react-google-autocomplete';
import DOBField from "../../components/numbers/DOBField";
import UploadAvtar from "../../components/properlytics/upload-avtar";

class ClientForm extends React.Component {
    constructor(props) {
        super(props);

        this.validators = validator;
        this.updateState = this.updateState.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.formValidators = this.formValidators.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleSwitch = this.handleSwitch.bind(this);
        this.onAddressChange = this.onAddressChange.bind(this);
        this.showErrors = this.showErrors.bind(this);
        this.onJAddressChange = this.onJAddressChange.bind(this);

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();

        this.state = {
            is_edit: false,
            client_id: '',
            profile_pic: '',
            img_display: false,
            profile_pic_url: '',
            remove_profile_pic: false,
            email: '',
            first_name: '',
            last_name: '',
            dob: '',
            occupation: '',
            phone: '',
            address: '',
            has_joint_client: 0,
            j_email: '',
            j_first_name: '',
            j_last_name: '',
            j_dob: '',
            j_occupation: '',
            j_phone: '',
            j_address: '',
            loading: false,
            page_loading: false,

            redirect: false,
            redirect_to_add: false,
        }
    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    onAddressChange(place) {
        this.setState({
            address: place.formatted_address,
            j_address: place.formatted_address
        });
        this.formValidators('address', place.formatted_address);
        this.formValidators('j_address', place.formatted_address);
    }

    onJAddressChange(place) {
        this.setState({
            address: place.formatted_address,
            j_address: place.formatted_address
        });
        this.formValidators('address', place.formatted_address);
        this.formValidators('j_address', place.formatted_address);
    }

    updateState(name, value) {
        this.setState({
            [name]: value,
        })
    }

    onInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        this.formValidators([event.target.name], event.target.value);
    }

    formValidators(fieldName, value) {
        this.validators[fieldName].errors = [];
        this.validators[fieldName].state = value;
        this.validators[fieldName].valid = true;
        this.validators[fieldName].rules.forEach((rule) => {
            if (rule.test instanceof RegExp) {
                if (!rule.test.test(value)) {
                    this.validators[fieldName].errors.push(rule.message);
                    this.validators[fieldName].valid = false;
                }
            } else if (typeof rule.test === 'function') {
                if (!rule.test(value)) {
                    this.validators[fieldName].errors.push(rule.message);
                    this.validators[fieldName].valid = false;
                }
            }
        });
    }

    validForm() {
        let status = true;
        Object.keys(this.validators).forEach((field) => {
            if (this.state.has_joint_client) {
                if (field === 'first_name' || field === 'last_name' || field === 'email' || field === 'dob' ||
                    field === 'occupation' || field === 'phone' || field === 'address' || field === 'j_first_name' ||
                    field === 'j_last_name' || field === 'j_email' || field === 'j_dob' || field === 'j_occupation' ||
                    field === 'j_phone' || field === 'j_address') {
                    if (!this.validators[field].valid) {
                        status = false;
                    }
                }
            } else {
                if (field === 'first_name' || field === 'last_name' || field === 'email' || field === 'dob' ||
                    field === 'occupation' || field === 'phone' || field === 'address') {
                    if (!this.validators[field].valid) {
                        status = false;
                    }
                }
            }
        });
        return status;
    }

    showErrors() {
        let fields = [];
        if (this.state.has_joint_client) {
            fields = ['first_name', 'last_name', 'email', 'dob', 'occupation', 'phone', 'address', 'j_first_name', 'j_last_name', 'j_email', 'j_dob', 'j_occupation', 'j_phone', 'j_address'];
        } else {
            fields = ['first_name', 'last_name', 'email', 'dob', 'occupation', 'phone', 'address'];
        }

        let errors = '';
        let return_error = false;
        for (let index = 0; index < fields.length ; index++) {
            const validator = this.validators[fields[index]];

            if (validator && !validator.valid) {
                for (let i =0 ;i<validator.errors.length;i++) {
                    errors += validator.errors[i];
                    break;
                }
            }

            if (return_error) {
                break;
            }
        }
        return errors;
    }

    onSubmit() {
        if (this.validForm()) {
            this.setState({
                loading: true
            })
            let postData = new FormData();
            if (this.state.is_edit) {
                postData.append('client_id', this.state.client_id);
                postData.append('remove_profile_pic', (this.state.remove_profile_pic) ? 1 : 0);
            }
            if (this.state.profile_pic) {
                postData.append('profile_pic', dataURLtoFile(this.state.profile_pic));
            }
            postData.append('email', this.state.email);
            postData.append('first_name', this.state.first_name);
            postData.append('last_name', this.state.last_name);
            postData.append('dob', this.state.dob);
            postData.append('occupation', this.state.occupation);
            postData.append('phone', this.state.phone);
            postData.append('address', this.state.address);
            postData.append('has_joint_client', this.state.has_joint_client);

            if (this.state.has_joint_client) {
                postData.append('j_email', this.state.j_email);
                postData.append('j_first_name', this.state.j_first_name);
                postData.append('j_last_name', this.state.j_last_name);
                postData.append('j_dob', this.state.j_dob);
                postData.append('j_occupation', this.state.j_occupation);
                postData.append('j_phone', this.state.j_phone);
                postData.append('j_address', this.state.j_address);
            }

            let callService = (this.state.is_edit) ? editClient(postData) : addClient(postData);

            callService.then(res => {
                if (res.success) {
                    this.showNotification(res.message, 'success')
                    this.setState({
                        redirect: true
                    })
                } else {
                    this.showNotification(res.message, 'danger')
                }
                this.setState({
                    loading: false
                })
            });
        } else {
            let msg = this.showErrors();
            if (msg.toString() === '') {
                this.showNotification('Please enter all required data', 'danger')
            } else {
                this.showNotification(msg, 'danger')
            }
        }
    }

    componentDidMount() {
        if (this.props.match.path === config.site_url.edit_client + '/:id') {
            //is edit call
            let client_id = this.props.match.params.id;
            if (client_id) {

                this.setState({
                    page_loading: true
                })

                let postData = {
                    client_id: client_id
                }

                getClient(postData).then(res => {
                    if (res.success) {

                        let client = res.data.client;
                        this.setState({
                            is_edit: true,
                            client_id: client.client_id,
                            img_display: (client.profile_pic),
                            //profile_pic_url: (client.profile_pic_base64) ? client.profile_pic_base64 : '',
                            email: client.email,
                            first_name: client.first_name,
                            last_name: client.last_name,
                            dob: (client.dob) ? moment(client.dob, 'YYYY-MM-DD').format('DD/MM/YYYY') : "",
                            occupation: client.occupation,
                            phone: client.phone,
                            address: client.address.address,
                            has_joint_client: (client.has_joint_client) ? 1 : 0,
                            j_email: client.j_email,
                            j_first_name: client.j_first_name,
                            j_last_name: client.j_last_name,
                            j_dob: (client.j_dob) ? moment(client.j_dob, 'YYYY-MM-DD').format('DD/MM/YYYY') : "",
                            j_occupation: client.j_occupation,
                            j_phone: client.j_phone,
                            j_address: client.j_address.address,
                        })


                        if (client.profile_pic) {
                            let postDataImage = {
                                id: client.client_id,
                                type: 2
                            };

                            getBase64Image(postDataImage).then(res => {
                                if (res.success) {
                                    this.setState({
                                        profile_pic_url: res.data.image
                                    })
                                }
                                this.setState({
                                    page_loading: false
                                })
                            });
                        } else {
                            this.setState({
                                page_loading: false
                            })
                        }

                        this.formValidators('email', client.email);
                        this.formValidators('first_name', client.first_name);
                        this.formValidators('last_name', client.last_name);
                        this.formValidators('dob', client.dob);
                        this.formValidators('phone', client.phone);
                        this.formValidators('occupation', client.occupation);
                        this.formValidators('address', client.address.address);
                        this.formValidators('j_email', client.j_email);
                        this.formValidators('j_first_name', client.j_first_name);
                        this.formValidators('j_last_name', client.j_last_name);
                        this.formValidators('j_dob', client.j_dob);
                        this.formValidators('j_phone', client.j_phone);
                        this.formValidators('j_occupation', client.j_occupation);
                        this.formValidators('j_address', client.j_address.address);

                    } else {
                        this.setState({
                            redirect_to_add: true,
                        })
                    }
                });


            } else {
                this.setState({
                    redirect_to_add: true,
                })
            }
        }
    }

    handleSwitch() {
        if (this.state.has_joint_client) {
            this.setState({
                has_joint_client: 0,
                j_address: ''
            })
        } else {
            this.setState({
                has_joint_client: 1,
                j_address: this.state.address
            })
        }
    }

    render() {

        if (this.state.redirect) {
            return <Redirect to={config.site_url.dashboard}/>;
        }

        if (this.state.redirect_to_add) {
            return <Redirect to={config.site_url.add_client}/>;
        }

        return <div className="">
            {/*--------------------------------------------------------------------------------*/}
            {/* Start Notification */}
            {/*--------------------------------------------------------------------------------*/}
            <ReactNotification ref={this.notificationDOMRef} />
            {/*--------------------------------------------------------------------------------*/}
            {/* End Notification */}
            {/*--------------------------------------------------------------------------------*/}

            {/*--------------------------------------------------------------------------------*/}
            {/*Login Cards*/}
            {/*--------------------------------------------------------------------------------*/}
            <div className="auth-wrapper no-block justify-content-center align-items-center">

                <div className={this.state.page_loading ? `page-loader show` : `page-loader hide`} />

                <div className={(this.state.page_loading) ? 'auth-box hide' : 'auth-box'}>
                    <Row>
                        <Col md="12">
                            <CardTitle className="f32 text-center mb-0">
                                Client Details
                            </CardTitle>
                            <p className="mt-3 txt-gray text-center">
                                {(this.state.is_edit ? 'Update ' : 'Add new ')}client information
                            </p>
                        </Col>
                    </Row>

                    <div id="loginform">
                        <Card>
                            <CardBody className="">
                                <Row>
                                    <Col xs="12">
                                        <Form id="userdetail">

                                            <UploadAvtar
                                                from="clients"
                                                img_display={this.state.img_display}
                                                profile_pic_url={this.state.profile_pic_url}
                                                updateState={this.updateState}
                                            />

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Email*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter your email"
                                                    name="email"
                                                    id="email"
                                                    value={this.state.email}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        First Name*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter First Name"
                                                    name="first_name"
                                                    id="first_name"
                                                    value={this.state.first_name}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Surname*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter Last Name"
                                                    name="last_name"
                                                    id="last_name"
                                                    value={this.state.last_name}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        D.O.B*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <DOBField
                                                    name="dob"
                                                    value={this.state.dob}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Job Title*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter Your Job Title"
                                                    name="occupation"
                                                    id="occupation"
                                                    value={this.state.occupation}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Contact no.*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter Your Contact Number"
                                                    name="phone"
                                                    id="phone"
                                                    value={this.state.phone}
                                                    maxLength={15}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Address*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Autocomplete
                                                    className="form-control"
                                                    onPlaceSelected={this.onAddressChange}
                                                    types={[]}
                                                    name="address"
                                                    onChange={this.onInputChange}
                                                    value={this.state.address}
                                                    componentRestrictions={{country: "au"}}
                                                />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Joint Client*
                                                    </InputGroupText>
                                                </InputGroupAddon>

                                                <div className="field check-round slide-inverse">
                                                    <span className="sts">No </span>
                                                    <input
                                                        type="checkbox"
                                                        name="has_joint_client"
                                                        id="check-slide-i"
                                                        checked={(this.state.has_joint_client)}
                                                    />
                                                    <label for="check-slide-i">
                                                        <span onClick={this.handleSwitch}/>
                                                    </label>
                                                    <span className="sts">Yes </span>
                                                </div>
                                            </InputGroup>


                                            <div className={(this.state.has_joint_client) ? '' : 'hide'}>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            Email
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type="text"
                                                        placeholder="Enter your email"
                                                        name="j_email"
                                                        id="j_email"
                                                        value={this.state.j_email}
                                                        onChange={this.onInputChange}
                                                    />
                                                </InputGroup>

                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            First Name*
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type="text"
                                                        placeholder="Enter First Name"
                                                        name="j_first_name"
                                                        id="j_first_name"
                                                        value={this.state.j_first_name}
                                                        onChange={this.onInputChange}
                                                    />
                                                </InputGroup>

                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            Surname*
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type="text"
                                                        placeholder="Enter Last Name"
                                                        name="j_last_name"
                                                        id="j_last_name"
                                                        value={this.state.j_last_name}
                                                        onChange={this.onInputChange}
                                                    />
                                                </InputGroup>

                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            D.O.B*
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <DOBField
                                                        name="j_dob"
                                                        value={this.state.j_dob}
                                                        onChange={this.onInputChange}
                                                    />
                                                </InputGroup>

                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            Job Title*
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type="text"
                                                        placeholder="Enter Your Job Title"
                                                        name="j_occupation"
                                                        id="j_occupation"
                                                        value={this.state.j_occupation}
                                                        onChange={this.onInputChange}
                                                    />
                                                </InputGroup>

                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            Contact no.
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type="text"
                                                        placeholder="Enter Your Contact Number"
                                                        name="j_phone"
                                                        id="j_phone"
                                                        value={this.state.j_phone}
                                                        maxLength={15}
                                                        onChange={this.onInputChange}
                                                    />
                                                </InputGroup>

                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            Address*
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Autocomplete
                                                        className="form-control"
                                                        onPlaceSelected={this.onJAddressChange}
                                                        types={[]}
                                                        name="j_address"
                                                        onChange={this.onInputChange}
                                                        value={this.state.j_address}
                                                        componentRestrictions={{country: "au"}}
                                                    />
                                                </InputGroup>
                                            </div>


                                            <div className="d-flex no-block align-items-cente auth-footer">
                                                <LeftLink text="Cancel" url={config.site_url.dashboard} />

                                                <RightButton text={(this.state.is_edit) ? 'Update' : 'Create'} onClick={this.onSubmit} loading={this.state.loading} />
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default ClientForm;
