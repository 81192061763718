import React from 'react';
import {
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Form,
    Row,
    Col,
} from 'reactstrap';

/*import SocialLogin from "../../components/social-login/social-login";*/
import {performSingup} from "../../services/services";
import validator from "./signup-validators";
import {Redirect} from 'react-router';
import Autocomplete from 'react-google-autocomplete';
import config from 'config/config';
import RightButton from "../../components/properlytics/right-button";
import DOBField from "../../components/numbers/DOBField";
import UploadAvtar from "../../components/properlytics/upload-avtar";
import {dataURLtoFile} from "../../services/helper";
import ReactPixel from 'react-facebook-pixel';

class SignupForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            signup_with: 1,
            account_type: props.accountType,
            profile_pic: '',
            img_display: false,
            profile_pic_url: '',
            first_name: '',
            last_name: '',
            dob: '',
            company_name: '',
            occupation: '',
            address: '',
            phone: '',
            email: '',
            password: '',

            redirect: false,
            loading: false,
        }

        this.validators = validator;
        this.updateState = this.updateState.bind(this);
        this.onAddressChange = this.onAddressChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.showErrors = this.showErrors.bind(this);
        this.formValidators = this.formValidators.bind(this);
        this.onSignup = this.onSignup.bind(this);
        this.handleAccountType = this.handleAccountType.bind(this);
    }

    updateState(name, value) {
        this.setState({
            [name]: value,
        })
    }

    onAddressChange(place) {
        this.setState({
            address: place.formatted_address
        });
        this.formValidators('address', place.formatted_address);
    }

    onInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        this.formValidators([event.target.name], event.target.value);
    }

    formValidators(fieldName, value) {
        this.validators[fieldName].errors = [];
        this.validators[fieldName].state = value;
        this.validators[fieldName].valid = true;
        this.validators[fieldName].rules.forEach((rule) => {
            if (rule.test instanceof RegExp) {
                if (!rule.test.test(value)) {
                    this.validators[fieldName].errors.push(rule.message);
                    this.validators[fieldName].valid = false;
                }
            } else if (typeof rule.test === 'function') {
                if (!rule.test(value)) {
                    this.validators[fieldName].errors.push(rule.message);
                    this.validators[fieldName].valid = false;
                }
            }
        });
    }

    validForm() {
        let status = true;
        Object.keys(this.validators).forEach((field) => {
            if (field === 'email' || field === 'password' || field === 'first_name' || field === 'last_name' ||
                field === 'phone') {
                if (!this.validators[field].valid) {
                    status = false;
                }
            }
        });
        return status;
    }

    showErrors() {
        const fields = ['email', 'password', 'first_name', 'last_name', 'phone'];

        let errors = '';
        let return_error = false;
        for (let index = 0; index < fields.length ; index++) {
            const validator = this.validators[fields[index]];

            if (validator && !validator.valid) {
                for (let i =0 ;i<validator.errors.length;i++) {
                    errors += validator.errors[i];
                    break;
                }
            }

            if (return_error) {
                break;
            }
        }
        return errors;
    }

    onSignup() {
        if (this.validForm()) {
            this.setState({
                loading: true
            })
            let postData = new FormData();
            postData.append('signup_with', this.state.signup_with);
            postData.append('account_type', this.state.account_type);
            if (this.state.profile_pic) {
                postData.append('profile_pic', dataURLtoFile(this.state.profile_pic));
            }
            postData.append('first_name', this.state.first_name);
            postData.append('last_name', this.state.last_name);
            postData.append('dob', this.state.dob);
            postData.append('company_name', this.state.company_name);
            postData.append('occupation', this.state.occupation);
            postData.append('address', this.state.address);
            postData.append('phone', this.state.phone);
            postData.append('email', this.state.email);
            postData.append('password', this.state.password);

            performSingup(postData).then(res => {
                if (res.success) {
                    this.setState({
                        redirect: true,
                    })
                    this.props.showNotification(res.message, 'success')
                } else {
                    this.setState({
                        loading: false
                    })
                    this.props.showNotification(res.message, 'danger')
                }
            });
        } else {
            let msg = this.showErrors();
            if (msg.toString() === '') {
                this.props.showNotification('Please enter all required data', 'danger')
            } else {
                this.props.showNotification(msg, 'danger')
            }
        }
    }

    handleAccountType() {
        let account_type = 1;
        if (parseInt(this.state.account_type) === 2) {
            account_type = 1;
        } else {
            account_type = 2;
        }

        this.setState({
            account_type: account_type
        })
    }

    componentDidMount() {
        ReactPixel.init(config.fb_pixel_id);
        ReactPixel.pageView();
    }

    render() {

        if (this.state.redirect) {
            return <Redirect to={{
                pathname: config.site_url.thank_you,
                title: 'Thank You',
                message: 'Your account has been created, please verify your account from email',
            }} />;
        }

        return <div className="">
            {/*--------------------------------------------------------------------------------*/}
            {/*Login Cards*/}
            {/*--------------------------------------------------------------------------------*/}
            <Row>
                <Col xs="12">
                    <Form id="signupform">

                        <UploadAvtar
                            from="signup-form"
                            img_display={this.state.img_display}
                            profile_pic_url={this.state.profile_pic_url}
                            updateState={this.updateState}
                        />

                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    Account Type*
                                </InputGroupText>
                            </InputGroupAddon>

                            <div className="field check-round slide-inverse">
                                <span className="sts">Personal </span>
                                <input
                                    type="checkbox"
                                    id="check-slide-i"
                                    checked={(parseInt(this.state.account_type) === 2)}
                                />
                                <label for="check-slide-i">
                                    <span onClick={this.handleAccountType}/>
                                </label>
                                <span className="sts">Business </span>
                            </div>
                        </InputGroup>

                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    First Name*
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                type="text"
                                placeholder="Enter First Name"
                                name="first_name"
                                id="first_name"
                                value={this.state.first_name}
                                onChange={this.onInputChange}
                            />
                        </InputGroup>

                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    Surname*
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                type="text"
                                placeholder="Enter Last Name"
                                name="last_name"
                                id="last_name"
                                value={this.state.last_name}
                                onChange={this.onInputChange}
                            />
                        </InputGroup>

                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    D.O.B
                                </InputGroupText>
                            </InputGroupAddon>
                            <DOBField
                                name="dob"
                                value={this.state.dob}
                                onChange={this.onInputChange}
                            />
                        </InputGroup>

                        <InputGroup className={(this.state.account_type === 2) ? '' : 'hide'}>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    Company Name
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                type="text"
                                placeholder="Enter Company Name"
                                name="company_name"
                                id="company_name"
                                value={this.state.company_name}
                                onChange={this.onInputChange}
                            />
                        </InputGroup>

                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    Job Title
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                type="text"
                                placeholder="Enter Your Job Title"
                                name="occupation"
                                id="occupation"
                                value={this.state.occupation}
                                onChange={this.onInputChange}
                            />
                        </InputGroup>

                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    Address
                                </InputGroupText>
                            </InputGroupAddon>
                            <Autocomplete
                                className="form-control"
                                onPlaceSelected={this.onAddressChange}
                                types={[]}
                                componentRestrictions={{country: "au"}}
                            />
                        </InputGroup>

                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    Contact no.*
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                type="text"
                                placeholder="Enter Your Contact Number"
                                name="phone"
                                id="phone"
                                value={this.state.phone}
                                onChange={this.onInputChange}
                            />
                        </InputGroup>

                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    Email*
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                type="text"
                                placeholder="Enter your email"
                                name="email"
                                id="email"
                                value={this.state.email}
                                onChange={this.onInputChange}
                            />
                        </InputGroup>

                        <InputGroup className="password-note-group">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    Password*
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                type="password"
                                placeholder="Enter your password"
                                name="password"
                                id="password"
                                value={this.state.password}
                                onChange={this.onInputChange}
                            />
                            <label className="password-note">
                                Use 5 or more characters with a mix of letters, numbers & symbols
                            </label>
                        </InputGroup>

                        <div className="d-flex no-block align-items-cente auth-footer">

                            {/*Hide social login as per client request*/}

                            {/*<SocialLogin
                             callbackFB={this.props.callbackFB}
                             callbackLinkedIn={this.props.callbackLinkedIn}
                             callbackLinkedInFail={this.props.callbackLinkedInFail}
                             is_signup={true}
                             />*/}

                            <div className="right_bx d-flex">
                            </div>

                            <RightButton text="Sign up" onClick={this.onSignup} loading={this.state.loading} />
                        </div>
                    </Form>
                </Col>
            </Row>
        </div>;
    }
}

export default SignupForm;
