import React from 'react';
import {
    Form,
} from 'reactstrap';
import RightButton from "components/properlytics/right-button";
import LeftButton from "components/properlytics/left-button";
import PercentageField from "../../../../components/numbers/PercentageField";
import PriceField from "../../../../components/numbers/PriceField";

export default class CurrentTaxableIncome extends React.Component {

    render() {
        const {
            is_joint,
            investor,
            partner,
            i_other_income,
            p_other_income,
            other_income,
            i_work_expenses,
            p_work_expenses,
            work_expenses,
            i_other_expenses,
            p_other_expenses,
            other_expenses,

            onInputChange
        } = this.props;

        return <div className={(this.props.show_dialog1) ? "grd_bx popup-wrap flip_bx1 taxable_income go" : "grd_bx popup-wrap flip_bx1 taxable_income"}>
            <div className="rect_bx flip_bx1">
                <div className="head_tron d-flex">
                    <span className="flipbtn t_black rotate">
                        <i className="icon-arrow-up-circle"/>
                    </span>
                    <h2 className="head pl-2 t_black">Current Taxable Income</h2>
                </div>

                <div className="inner_bx">
                    <Form>
                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span/>
                                    <span>Investor</span>
                                    <span className={is_joint ? '' : 'hide'}>Partner</span>
                                    <span className="text-right">Total</span>
                                </div>
                            </div>
                        </div>

                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span>Ownership</span>
                                    <span>
                                        <PercentageField
                                            className="form-control lpad0"
                                            placeholder="100%"
                                            name="cti_investor"
                                            value={investor}
                                            readOnly={true}
                                        />
                                    </span>
                                    <span className={is_joint ? '' : 'hide'}>
                                        <PercentageField
                                            className="form-control lpad0"
                                            placeholder="0%"
                                            name="cti_partner"
                                            value={partner}
                                            readOnly={true}
                                        />
                                    </span>
                                    <span>
                                        <PercentageField
                                            className="form-control text-right rpad0"
                                            placeholder="100%"
                                            name="cti_total"
                                            value={'100'}
                                            readOnly={true}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span>Other Income</span>
                                    <span>
                                        <PriceField
                                            className="form-control lpad0"
                                            placeholder="$0"
                                            name="i_other_income"
                                            value={i_other_income}
                                            onChange={onInputChange.bind(this)}
                                        />
                                    </span>
                                    <span className={is_joint ? '' : 'hide'}>
                                        <PriceField
                                            className="form-control lpad0"
                                            placeholder="$0"
                                            name="p_other_income"
                                            value={p_other_income}
                                            onChange={onInputChange.bind(this)}
                                        />
                                    </span>
                                    <span>
                                        <PriceField
                                            className="form-control text-right rpad0"
                                            placeholder="$0"
                                            name="other_income"
                                            value={other_income}
                                            readOnly={true}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span>Work Expenses</span>
                                    <span>
                                        <PriceField
                                            className="form-control lpad0"
                                            placeholder="$0"
                                            name="i_work_expenses"
                                            value={i_work_expenses}
                                            onChange={onInputChange.bind(this)}
                                        />
                                    </span>
                                    <span className={is_joint ? '' : 'hide'}>
                                        <PriceField
                                            className="form-control lpad0"
                                            placeholder="$0"
                                            name="p_work_expenses"
                                            value={p_work_expenses}
                                            onChange={onInputChange.bind(this)}
                                        />
                                    </span>
                                    <span>
                                        <PriceField
                                            className="form-control text-right rpad0"
                                            placeholder="$0"
                                            name="work_expenses"
                                            value={work_expenses}
                                            readOnly={true}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span>Other Expenses</span>
                                    <span>
                                        <PriceField
                                            className="form-control lpad0"
                                            placeholder="$0"
                                            name="i_other_expenses"
                                            value={i_other_expenses}
                                            onChange={onInputChange.bind(this)}
                                        />
                                    </span>
                                    <span className={is_joint ? '' : 'hide'}>
                                        <PriceField
                                            className="form-control lpad0"
                                            placeholder="$0"
                                            name="p_other_expenses"
                                            value={p_other_expenses}
                                            onChange={onInputChange.bind(this)}
                                        />
                                    </span>
                                    <span>
                                        <PriceField
                                            className="form-control text-right rpad0"
                                            placeholder="$0"
                                            name="other_expenses"
                                            value={other_expenses}
                                            readOnly={true}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tp_row_btm d-flex justify-content-between">
                            <LeftButton text="Close" onClick={this.props.hideShowDialog1} />

                            <RightButton text="Save" onClick={this.props.hideShowDialog1} />
                        </div>
                    </Form>
                </div>
            </div>`
        </div>

    }
}