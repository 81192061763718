import React from 'react';
import {
    Row,
    Col,
    Form,
    Card,
    CardTitle,
    CardBody,
} from 'reactstrap';
import config from 'config/config';
import ReactPixel from 'react-facebook-pixel';

export default class ThankYou extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: '',
            message: '',
        };
    }


    componentDidMount() {
        let message = 'Invalid Link';
        let title = 'Error';

        if (typeof this.props.location.title !== 'undefined' && this.props.location.title) {
            title = this.props.location.title
        }

        if (typeof this.props.location.message !== 'undefined' && this.props.location.message) {
            message = this.props.location.message
        }

        this.setState({
            title:title,
            message: message,
        })

        ReactPixel.init(config.fb_pixel_id);
        ReactPixel.pageView();
    }

    render() {

        return <div className="">
            {/*--------------------------------------------------------------------------------*/}
            {/*Login Cards*/}
            {/*--------------------------------------------------------------------------------*/}
            <div className="auth-wrapper no-block justify-content-center align-items-center">

                <div className="auth-box">
                    <Row>
                        <Col md="12">
                            <CardTitle className="f32 text-center mb-0">
                                {this.state.title}
                            </CardTitle>
                        </Col>
                    </Row>

                    <div id="loginform">
                        <Card>
                            <CardBody className="">
                                <Row>
                                    <Col xs="12">
                                        <Form id="thank-you">

                                            <div className="text-center">
                                                {this.state.message}
                                            </div>

                                            <div align="center" className="btn-div">
                                                <span>
                                                   <a href={config.hash + config.site_url.login} className="thank-you-btn">
                                                       Go to Login
                                                   </a>
                                                </span>
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </div>;
    }
}

