import config from '../config/config';
import {getHeaders, clearLocalStorage, updateTimeOut, storeInLocalStorage} from "./helper";
const axios = require('axios');

//base service call
export const callAuthService = (url, postData) => {
    return axios.post(config.base_url + url, postData)
        .then(function (response) {
            // handle success
            return response.data;
        })
        .catch(function (error) {
            // handle error
            console.log(error);
            return false;
        });
}

// log In
export const performLogin = (postData) => {
    return callAuthService(config.login, postData);
}

// log linkedin
export const loginWithLinkedIn = (postData) => {
    return callAuthService(config.linkedin_login, postData);
}

// log fb
export const loginWithFacebook = (postData) => {
    return callAuthService(config.facebook_login, postData);
}

// singup
export const performSingup = (postData) => {
    return callAuthService(config.register, postData);
}

// forgot password
export const forgotPassword = (postData) => {
    return callAuthService(config.forgot_password, postData);
}

// check reset password link
export const checkResetPassword = (postData) => {
    return callAuthService(config.check_reset_password, postData);
}

// reset password
export const resetPassword = (postData) => {
    return callAuthService(config.reset_password, postData);
}

// log out
export const performLogout = () => {
    clearLocalStorage();
}

//base service call
export const callService = (url, postData) => {
    return axios.post(config.base_url + url, postData, getHeaders())
        .then(function (response) {
            //redirect to the login screen if api token is invalid
            if (!response.data.success) {
                if (response.data.error) {
                    if (typeof response.data.error !== 'undefined' && typeof response.data.error.code !== 'undefined' &&
                        response.data.error.code === 401
                    ) {
                        clearLocalStorage();
                        window.location.reload();
                    }

                    if (typeof response.data.error !== 'undefined' && typeof response.data.error.expired_code !== 'undefined' &&
                        response.data.error.expired_code === 401
                    ) {
                        getProfile();
                        window.location.reload();
                    }
                }
            }

            // update time out local storage
            updateTimeOut();
            // handle success
            return response.data;
        })
        .catch(function (error) {
            // handle error
            console.log(error);
            return false;
        });
}

export const isSuperAdmin = () => {
    return callService(config.get_profile, {}).then(res => {
        if (res.success) {
            storeInLocalStorage(res.data.profile_details);

            if (res.data.profile_details.is_super_admin) {
                return true;
            } else {
                return false;
            }
        } else {
            return false
        }
    });
}

export const getProfile = () => {
    return callService(config.get_profile, {}).then(res => {
        if (res.success) {
            storeInLocalStorage(res.data.profile_details);
        }
    });
}

export const updateAccount = (postData) => {
    return callService(config.update_account, postData);
}

export const getBase64Image = (postData) => {
    return callService(config.get_base64_image, postData);
}

export const changePassword = (postData) => {
    return callService(config.change_password, postData);
}

export const customAccount = (postData) => {
    return callService(config.request_custom_account, postData);
}

export const getSubscription = () => {
    return callService(config.get_subscription, {});
}

export const addSubscription = (postData) => {
    return callService(config.add_subscription, postData);
}

export const updateSubscription = (postData) => {
    return callService(config.update_subscription, postData);
}

export const resumeSubscription = () => {
    return callService(config.resume_subscription, {});
}

export const cancelSubscription = () => {
    return callService(config.cancel_subscription, {});
}

export const applyCoupon = (postData) => {
    return callService(config.apply_coupon, postData);
}

export const addSubUser = (postData) => {
    return callService(config.add_sub_user, postData);
}

export const getSubUser = (postData) => {
    return callService(config.get_sub_user, postData);
}

export const editSubUser = (postData) => {
    return callService(config.edit_sub_user, postData);
}

export const deleteSubUser= (postData) => {
    return callService(config.delete_sub_user, postData);
}

export const getMyDashboard = () => {
    return callService(config.get_my_dashboard, {});
}

export const getClients = (postData) => {
    return callService(config.get_clients, postData);
}

export const addClient = (postData) => {
    return callService(config.add_clients, postData);
}

export const getClient = (postData) => {
    return callService(config.get_client, postData);
}

export const editClient = (postData) => {
    return callService(config.edit_clients, postData);
}

export const deleteClient = (postData) => {
    return callService(config.delete_clients, postData);
}

export const getProperties = (postData) => {
    return callService(config.get_properties, postData);
}

export const deleteProperty = (postData) => {
    return callService(config.delete_property, postData);
}

export const getProperty = (postData) => {
    return callService(config.get_property, postData);
}

export const addEditProperty = (postData) => {
    return callService(config.add_property_detail, postData);
}

export const uploadImage = (postData) => {
    return callService(config.upload_image, postData);
}

export const deleteImage = (postData) => {
    return callService(config.delete_image, postData);
}

export const getStampDuty = (postData) => {
    return callService(config.get_stamp_duty, postData);
}

export const addStampDuty = (postData) => {
    return callService(config.add_stamp_duty, postData);
}

export const calculateStampDuty = (postData) => {
    return callService(config.cal_stamp_duty, postData);
}

export const addEditAcquisitionCosts = (postData) => {
    return callService(config.add_acquisition_costs, postData);
}

export const getFundingStructure = (postData) => {
    return callService(config.get_funding_structure, postData);
}

export const calExtraRepayment = (postData) => {
    return callService(config.cal_extra_repayment, postData);
}

export const calLoanRepayment = (postData) => {
    return callService(config.cal_loan_repayment, postData);
}

export const addEditFundingStructure = (postData) => {
    return callService(config.add_funding_structure, postData);
}

export const addEditInvestmentOutcomes = (postData) => {
    return callService(config.add_investment_outcomes, postData);
}

export const calAgentCommission = (postData) => {
    return callService(config.cal_agent_commission, postData);
}

export const addEditInvestmentExpenses = (postData) => {
    return callService(config.add_investment_expenses, postData);
}

export const addEditEconomicAssumption = (postData) => {
    return callService(config.add_economic_assumption, postData);
}

export const getDeduction = (postData) => {
    return callService(config.get_deduction, postData);
}

export const calDP = (postData) => {
    return callService(config.cal_depreciation_of_fittings, postData);
}

export const addEditDeduction = (postData) => {
    return callService(config.add_deduction, postData);
}

export const getITScales = (postData) => {
    return callService(config.get_income_scales, postData);
}

export const addITScales = (postData) => {
    return callService(config.add_income_scales, postData);
}

export const getTaxOffsetScales = (postData) => {
    return callService(config.get_tax_offset, postData);
}

export const addTaxOffsetScales = (postData) => {
    return callService(config.add_tax_offset, postData);
}

export const calTaxBenefits = (postData) => {
    return callService(config.cal_tax_benefits, postData);
}

export const addEditIncome = (postData) => {
    return callService(config.add_income, postData);
}

export const getWhoPay = (postData) => {
    return callService(config.get_who_pay, postData);
}

export const addEditCashflow = (postData) => {
    return callService(config.add_cashflow, postData);
}

export const exportReport = (postData) => {
    return callService(config.export_report, postData);
}

/*Admin apis*/
export const getAdminDashboard = (postData) => {
    return callService(config.admin_dashboard, postData);
}

export const addUserFromAdmin = (postData) => {
    return callService(config.admin_add_user, postData);
}

export const getUserFromAdmin = (postData) => {
    return callService(config.admin_get_user, postData);
}

export const editUserFromAdmin = (postData) => {
    return callService(config.admin_edit_user, postData);
}

export const deleteUserFromAdmin = (postData) => {
    return callService(config.admin_delete_user, postData);
}

export const getAdminUserList = (postData) => {
    return callService(config.admin_user_list, postData);
}
