import React from 'react';
import {
    Button,
} from 'reactstrap';

export default class RightButton extends React.Component {

	render() {
        let {text, onClick} = this.props;

        let loading = (this.props.loading) || false;

        let bigBtn = (this.props.bigBtn) || false;

        if (text === '') {
            return '';
        }

        let style = {
            width: '220px'
        }

        return (
            <div className="left_bx" style={(bigBtn) ? style : {}}>
                <Button className="t_green" size="lg" type="button" onClick={onClick} disabled={loading} block >
                    <div>
                        {text} <i aria-hidden="true" className="icon-arrow-right f10" />
                    </div>

                    <div className={(loading) ? 'btn-loading right' : 'hide'}>
                        <div className="btn-loader" />
                    </div>
                </Button>
            </div>
        );
	}
}