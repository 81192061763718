import React from 'react';

export default class Paginator extends React.Component {

	render() {
        let {onClick, paginator} = this.props;

        let links = [];
        if (paginator.on_first_page) {
            links.push({
                class_name: 'disabled',
                text: '«',
                param: '',
            });
        } else {
            links.push({
                class_name: '',
                text: '«',
                param: paginator.prev_page,
            });
        }

        if (paginator.current_page > 3) {
            links.push({
                class_name: '',
                text: '1',
                param: 1,
            });
        }

        if (paginator.current_page > 4) {
            links.push({
                class_name: 'disabled',
                text: '...',
                param: '',
            });
        }

        for (let i=1;i<=paginator.last_page;i++) {
            if ((i >= (paginator.current_page - 1)) && (i <= paginator.current_page + 1)) {
                if (i === paginator.current_page) {
                    links.push({
                        class_name: 'active',
                        text: i,
                        param: i,
                    });
                } else {
                    links.push({
                        class_name: '',
                        text: i,
                        param: i,
                    });
                }
            }
        }

        if (paginator.current_page < (paginator.last_page - 3)) {
            links.push({
                class_name: 'disabled',
                text: '...',
                param: '',
            });
        }

        if (paginator.current_page < (paginator.last_page - 2)) {
            links.push({
                class_name: '',
                text: paginator.last_page,
                param: paginator.last_page,
            });
        }

        if (paginator.has_more_page) {
            links.push({
                class_name: '',
                text: '»',
                param: paginator.next_page,
            });
        } else {
            links.push({
                class_name: 'disabled',
                text: '»',
                param: '',
            });
        }

        let link_details = links.map((item, key) => {
            return (
                <li className={'page-item ' + (item.class_name)}>
                    {
                        (item.class_name === 'active' || item.param === '')
                        ?
                            <button className="page-link">
                                {item.text}
                            </button>
                        :
                            <button
                                onClick={onClick.bind(this, item.param)}
                                className="page-link"
                                disabled={(item.class_name === 'active' || item.param === '')}
                            >
                                {item.text}
                            </button>
                    }
                </li>
            );
        });

        return (
            <nav className="pull-right" aria-label="Page navigation example">
                <ul className="pagination">
                    {link_details}
                </ul>
            </nav>
        );
	}
}
