import React from 'react';
import {
    Row,
    Col,
    Table,
} from 'reactstrap';
import Paginator from "../properlytics/paginator";

export default class UserListTable extends React.Component {

    render() {
        let {users, paginator, loading, getUserList} = this.props;

        return (
            <div id="prpty_list">
                <Row>
                    <Col md="12">
                        <div className="table-wrap">
                            <Table responsive className="custmize">
                                <thead>
                                <tr className="stripe">
                                    <th>First Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Platform</th>
                                    <th>Plan</th>
                                    <th>Status</th>
                                    <th>Trial</th>
                                    <th>Renew On</th>
                                    <th>Created</th>
                                </tr>
                                </thead>

                                <UserMap
                                    loading={loading}
                                    users={users}
                                />

                            </Table>
                        </div>
                    </Col>

                    <Col lg="12">
                        <Paginator
                            paginator={paginator}
                            onClick={getUserList}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const UserMap = ({loading, users}) => {

    if (!users || users.length === 0) {
        return (
            <tbody>
            <tr className="strip">
                <td colSpan={10} className="text-center">
                    <div className={(loading) ? 'table-loader' : 'hide'}/>
                    <div className={(loading) ? 'hide' : ''}>Users not available</div>
                </td>
            </tr>
            </tbody>
        )
    }

    return users.map(user => {
        let id = 'user_' + user.id;
        return (
            <tbody id={id}>
            <tr className="main_row">
                <td>
                    {user.full_name}
                </td>

                <td>
                    {user.email}
                </td>

                <td>
                    {user.phone}
                </td>

                <td>
                    {user.platform}
                </td>

                <td>
                    {user.subscription_plan}
                </td>

                <td>
                    {user.status}
                </td>

                <td>
                    {user.trial_period}
                </td>

                <td>
                    {user.renew_on}
                </td>

                <td>
                    {user.created_at}
                </td>
            </tr>
            </tbody>
        );
    })
}
