import React from 'react';
import {
    Row,
    Col,
    CardTitle,
    Form,
    Input,
    Button,
} from 'reactstrap';
import {getMyProfile, getNotificationObject, getPlanPrice} from "../../services/helper";
import ReactNotification from "react-notifications-component";
import {Redirect} from 'react-router';
import config from 'config/config';
import moment from 'moment';
import Select, { components } from "react-select";
import LeftLink from "../../components/properlytics/left-link";
import {
    getLicence,
    getLicenceOptions,
} from "../../services/config-helper";
import {
    getSubscription,
    cancelSubscription,
    updateSubscription,
    resumeSubscription
} from "../../services/services";
import SubUserTable from "../../components/subscription/sub-user-table";
import {confirmAlert} from "react-confirm-alert";

const groupStyles = {
    borderRadius: '0px',
    background: '#ffffff',
    padding: "0"
};

const Group = (props) => (
    <div style={groupStyles}>
        <components.Group {...props} />
    </div>
);

const style1 = {
    control: (base, state) => ({
        ...base,
        // This line disable the blue border
        background: state.isFocused ? 0 : 0,
        padding: "0 !important",
        margin: "0 !important",
        border: "0 !important",
        boxShadow: "0 !important",
        "&:hover": {
            border: "0 !important"
        }
    })
};

class SubscriptionManage extends React.Component {
    constructor(props) {
        super(props);

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();

        this.onChangeQty = this.onChangeQty.bind(this);
        this.setSubscriptionState = this.setSubscriptionState.bind(this);
        this.getSubscriptionDetail = this.getSubscriptionDetail.bind(this);
        this.updateSubscription = this.updateSubscription.bind(this);
        this.cancelSubscription = this.cancelSubscription.bind(this);
        this.resumeSubscription = this.resumeSubscription.bind(this);

        this.state = {
            user_id: '',
            email: '',
            subscription_type: 1, //1 =personal, 2=business
            subscription_interval: 1, // 1=monthly, 2=yearly
            type: 1, //1 = web, 2=ios, 3=android
            has_trial: true,
            coupon_code: '',

            //plan prices
            start_date:'',
            end_date: '',
            qty: 1,
            sub_price: 0,
            tax: 0,
            discount: 0,
            total: 0,
            redirect_purchase_subscription: false,
            redirect_choose_subscription: false,
            loading: false,
            page_loading: false,


            subscription_detail: {},
            in_trial: false,
            trial_end_at: '',
            cancelled: false,
            can_resume: false,
            sub_users: [],
            display_users: true,
            subscription_from_admin: false
        }
    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    onChangeQty(selected) {

        let sub_total = getPlanPrice(this.state.subscription_type, this.state.subscription_interval, selected.value);
        let discount = 0;
        let tax = 0;
        let total = parseFloat(sub_total).toFixed(2);
        let gst_percentage = config.gst_percentage;

        if (total && gst_percentage) {
            tax = parseFloat((gst_percentage * total) / (100 + gst_percentage)).toFixed(2);
            sub_total = parseFloat(total - tax).toFixed(2);
        }

        this.setState({
            qty: selected.value,
            sub_price: sub_total,
            tax: tax,
            discount: discount,
            total: total,
        });
    }

    setSubscriptionState(res) {
        let sub = res.data.subscription_detail;
        let subscription_type= 1;
        let subscription_interval= 1;
        let type = 1;
        let qty = 1;
        let redirect_purchase_subscription = false;
        let start_date = '';
        let end_date = '';
        let redirect_choose_subscription = false;

        if (sub.subscription_type === config.subscription.no) {
            redirect_choose_subscription = true;
        }

        if (sub.subscription_type === config.subscription.web) {
            type = 1;
            if (sub.subscription_expire) {
                redirect_purchase_subscription = true;
            }
        } else if (sub.subscription_type === config.subscription.ios) {
            type = 2;
        } else if (sub.subscription_type === config.subscription.android) {
            type = 3;
        }


        if (sub.subscription_plan === config.subscription.business_type) {
            subscription_type = 2;
        }

        if (sub.payment_interval === config.subscription.year) {
            subscription_interval = 2;
        }

        if (sub.subscription_expirty_date) {
            end_date = moment(sub.subscription_expirty_date, 'YYYY-MM-DD HH:ii:ss').format('DD/MM/YYYY');
            let interval = (subscription_interval === 2) ? 'year' : 'month';
            start_date = moment(end_date, 'DD/MM/YYYY').subtract(1, interval).format('DD/MM/YYYY');
        }

        if (sub.no_of_licence) {
            qty = sub.no_of_licence;
        }

        let sub_total = getPlanPrice(subscription_type, subscription_interval, qty);
        let discount = 0;
        let tax = 0;
        let total = parseFloat(sub_total).toFixed(2);
        let gst_percentage = config.gst_percentage;

        if (total && gst_percentage) {
            tax = parseFloat((gst_percentage * total) / (100 + gst_percentage)).toFixed(2);
            sub_total = parseFloat(total - tax).toFixed(2);
        }

        let trial_end_at = '';
        if (res.data.trial_end_at) {
            trial_end_at = moment(res.data.trial_end_at, 'YYYY-MM-DD HH:ii:ss').format('DD/MM/YYYY');
        }

        this.setState({
            type: type,
            subscription_type: subscription_type,
            subscription_interval: subscription_interval,
            qty: qty,
            start_date: start_date,
            end_date: end_date,
            sub_price: sub_total,
            tax: tax,
            discount: discount,
            total: total,
            redirect_purchase_subscription: redirect_purchase_subscription,
            redirect_choose_subscription: redirect_choose_subscription,

            subscription_detail: res.data.subscription_detail,
            in_trial: res.data.in_trial,
            trial_end_at: trial_end_at,
            cancelled: res.data.cancelled,
            sub_users: res.data.sub_users.sub_users,
            display_users: res.data.display_users,
            can_resume: res.data.can_resume,
            subscription_from_admin: res.data.subscription_from_admin,
        })
    }

    getSubscriptionDetail() {
        this.setState({
            page_loading: true
        });

        getSubscription().then(res => {
            if (res.success) {
                this.setSubscriptionState(res);
            } else {
                this.showNotification(res.message, 'danger')
            }

            this.setState({
                page_loading: false
            });
        });
    }

    updateSubscription() {
        this.setState({
            loading: true
        });

        let postData = {
            qty: this.state.qty
        };
        updateSubscription(postData).then(res => {
            if (res.success) {
                this.showNotification(res.message, 'success')
                this.setSubscriptionState(res);
            } else {
                this.showNotification(res.message, 'danger')
            }

            this.setState({
                loading: false
            });
        });
    }

    cancelSubscription() {
        confirmAlert({
            title: 'Confirm to cancel',
            message: 'Are you sure you want to cancel your subscription?',
            buttons: [
                {
                    label: 'NO',
                },
                {
                    label: 'YES',
                    onClick: () => this.cancel()
                }
            ]
        });
    }

    cancel() {
        this.setState({
            page_loading: true
        });

        cancelSubscription().then(res => {
            if (res.success) {
                this.showNotification(res.message, 'success')
                this.setSubscriptionState(res);
            } else {
                this.showNotification(res.message, 'danger')
            }

            this.setState({
                page_loading: false
            });
        });
    }

    resumeSubscription() {
        this.setState({
            loading: true
        });

        resumeSubscription().then(res => {
            if (res.success) {
                this.showNotification(res.message, 'success')
                this.setSubscriptionState(res);
            } else {
                this.showNotification(res.message, 'danger')
            }

            this.setState({
                loading: false
            });
        });
    }

    componentDidMount() {
        let profile = getMyProfile();
        if (profile) {
            this.setState({
                user_id: profile.user_id,
                email: profile.email,
            });

            this.getSubscriptionDetail();
        } else {
            this.setState({
                redirect_purchase_subscription: true,
            })
        }
    }

    render() {

        if (this.state.redirect_purchase_subscription) {
            return <Redirect
                to={{
                    pathname: config.site_url.subscription_purchase,
                    subscription_type: this.state.subscription_type,
                    subscription_interval: this.state.subscription_interval,
                    has_trial: false
                }}
            />;
        }

        if (this.state.redirect_choose_subscription) {
            return <Redirect to={config.site_url.subscription} />;
        }

        //ios or android
        if (this.state.type === 2 || this.state.type === 3) {
            let from = 'Apple';
            let device = 'IOS';
            if (this.state.type === 3) {
                from = 'Google';
                device = 'Android';
            }

            return <div className="">
                {/*--------------------------------------------------------------------------------*/}
                {/* Start Notification */}
                {/*--------------------------------------------------------------------------------*/}
                <ReactNotification ref={this.notificationDOMRef} />
                {/*--------------------------------------------------------------------------------*/}
                {/* End Notification */}
                {/*--------------------------------------------------------------------------------*/}

                {/*--------------------------------------------------------------------------------*/}
                {/*Login Cards*/}
                {/*--------------------------------------------------------------------------------*/}
                <div className="auth-wrapper no-block justify-content-center align-items-center">

                    <div className={this.state.page_loading ? `page-loader show` : `page-loader hide`} />

                    <div className={(this.state.page_loading) ? 'auth-box hide' : 'auth-box'}>
                        <Row>
                            <Col md="12">
                                <CardTitle className="f32 text-center mb-0">
                                    Subscription
                                </CardTitle>
                                <p className="mt-3 txt-gray text-center">Update plan and payment details</p>
                            </Col>
                        </Row>

                        <div id="subscription">
                            <div className="rect_bx mt-5">
                                <div className="head_tron">
                                    <h2 className="head t_green">Your subscription</h2>
                                    <div className="description">
                                        Your subscription is purchased from {from} account.<br/>
                                        So for manage subscription login to your {from} account or open properlytics app in your {device} device.
                                    </div>
                                </div>

                                <div className="inner_bx">
                                    <Form id="prp_detail">
                                        <SubUserTable
                                            user_id={this.state.user_id}
                                            sub_users={this.state.sub_users}
                                            display_users={this.state.display_users}
                                        />

                                        <div className="tp_row_btm d-flex justify-content-between">
                                            <LeftLink url={config.site_url.dashboard} text="Exit" />
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>;
        }

        //by super admin
        if (this.state.subscription_from_admin) {
            return <div className="">
                {/*--------------------------------------------------------------------------------*/}
                {/* Start Notification */}
                {/*--------------------------------------------------------------------------------*/}
                <ReactNotification ref={this.notificationDOMRef} />
                {/*--------------------------------------------------------------------------------*/}
                {/* End Notification */}
                {/*--------------------------------------------------------------------------------*/}

                {/*--------------------------------------------------------------------------------*/}
                {/*Login Cards*/}
                {/*--------------------------------------------------------------------------------*/}
                <div className="auth-wrapper no-block justify-content-center align-items-center">

                    <div className={this.state.page_loading ? `page-loader show` : `page-loader hide`} />

                    <div className={(this.state.page_loading) ? 'auth-box hide' : 'auth-box'}>
                        <Row>
                            <Col md="12">
                                <CardTitle className="f32 text-center mb-0">
                                    Subscription
                                </CardTitle>
                                <p className="mt-3 txt-gray text-center">Update plan and payment details</p>
                            </Col>
                        </Row>

                        <div id="subscription">
                            <div className="rect_bx mt-5">
                                <div className="head_tron">
                                    <h2 className="head t_green">Your subscription</h2>
                                    <div className="description">
                                        Your subscription is free from properlytics.<br/>
                                        You can purchase subscription after your free subscription over.<br/>
                                        Subscription expired on: {this.state.end_date}
                                    </div>
                                </div>

                                <div className="inner_bx">
                                    <Form id="prp_detail">
                                        <SubUserTable
                                            user_id={this.state.user_id}
                                            sub_users={this.state.sub_users}
                                            display_users={this.state.display_users}
                                        />

                                        <div className="tp_row_btm d-flex justify-content-between">
                                            <LeftLink url={config.site_url.dashboard} text="Exit" />
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>;
        }

        //web
        return <div className="">
            {/*--------------------------------------------------------------------------------*/}
            {/* Start Notification */}
            {/*--------------------------------------------------------------------------------*/}
            <ReactNotification ref={this.notificationDOMRef} />
            {/*--------------------------------------------------------------------------------*/}
            {/* End Notification */}
            {/*--------------------------------------------------------------------------------*/}

            {/*--------------------------------------------------------------------------------*/}
            {/*Login Cards*/}
            {/*--------------------------------------------------------------------------------*/}
            <div className="auth-wrapper no-block justify-content-center align-items-center">

                <div className={this.state.page_loading ? `page-loader show` : `page-loader hide`} />

                <div className={(this.state.page_loading) ? 'auth-box hide' : 'auth-box'}>
                    <Row>
                        <Col md="12">
                            <CardTitle className="f32 text-center mb-0">
                                Subscription
                            </CardTitle>
                            <p className="mt-3 txt-gray text-center">Update plan and payment details</p>
                        </Col>
                    </Row>

                    <div id="subscription">
                        <div className="rect_bx mt-5">
                            <div className="head_tron d-flex justify-content-between">
                                <h2 className="head t_green">Your subscription</h2>
                                <h2 className="head t_black">
                                    {(this.state.subscription_type === 1) ? 'Personal' : 'Business'}
                                    {(this.state.subscription_interval === 1) ? '(Monthly)' : '(Yearly)'}
                                </h2>
                                <h2 className="head t_gray">
                                    {this.state.in_trial ? 'Trial end at ' + this.state.trial_end_at : this.state.start_date + ' to ' + this.state.end_date}
                                </h2>
                            </div>

                            <div className="inner_bx">
                                <Form id="prp_detail">
                                    <div className="tp_row">
                                        <div className="hd_row d-flex">
                                            <h4 className="sm_tit fx_w">Licences</h4>
                                            <div className={(this.state.subscription_type === 2) ? 'slct' : 'hide'}>
                                                <Select
                                                    defaultValue={getLicence()}
                                                    options={getLicenceOptions}
                                                    components={{ Group }}
                                                    styles={style1}
                                                    value={getLicence(this.state.qty)}
                                                    onChange={this.onChangeQty}
                                                />
                                            </div>

                                            <div className={(this.state.subscription_type === 1) ? 'slct' : 'hide'}>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.qty}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tp_row pr-0 pt-0 pb-0 height60">
                                        <div className="hd_row d-flex inner_row">
                                            <h4 className="sm_tit fx_w">
                                                Subscription Cost
                                            </h4>

                                            <div className="d-flex w100 height38  justify-content-between">
                                                <h4 className="sm_tit fx_w">
                                                    Sub total
                                                </h4>

                                                <span className="d-flex  height60">
                                                    <span className="value  pmedium">${this.state.sub_price}</span>
                                                </span>
                                            </div>
                                        </div>


                                        <div className="hd_row d-flex inner_row">
                                            <h4 className="sm_tit fx_w">
                                            </h4>

                                            <div className="d-flex w100 height38 brd-top justify-content-between">
                                                <h4 className="sm_tit fx_w">
                                                    GST {config.gst_percentage}%
                                                </h4>

                                                <span className="d-flex  height60">
                                                    <span className="value  pmedium">${this.state.tax}</span>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="hd_row d-flex inner_row">
                                            <h4 className="sm_tit fx_w">
                                            </h4>

                                            <div className="d-flex w100 height38 brd-top justify-content-between">
                                                <h4 className="sm_tit fx_w">
                                                    Discount
                                                </h4>

                                                <span className="d-flex  height60">
                                                    <span className="value  pmedium">${this.state.discount}</span>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="hd_row d-flex inner_row">
                                            <h4 className="sm_tit fx_w">
                                            </h4>

                                            <div className="d-flex w100 height38 brd-top justify-content-between">
                                                <h4 className="f14 t_black pbold fx_w">
                                                    Total
                                                </h4>

                                                <span className="d-flex  height60">
                                                    <span className="value t_black pbold">${this.state.total}</span>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="hd_row d-flex inner_row">
                                            <h4 className="sm_tit fx_w">
                                            </h4>

                                            <div className="d-flex  height60 brd-top w100  justify-content-end">
                                                <Button
                                                    size="lg"
                                                    type="button"
                                                    className={(!this.state.cancelled) ? "t_green value wauto" : 'hide'}
                                                    block
                                                    disabled={this.state.loading}
                                                    onClick={this.updateSubscription}
                                                >
                                                    <div>
                                                        {/*Update Licence*/}
                                                        Your Active Subscription Details <i aria-hidden="true" className="icon-arrow-right f10" />
                                                    </div>

                                                    <div className={(this.state.loading) ? 'btn-loading btn-s-loading right' : 'hide'}>
                                                        <div className="btn-loader" />
                                                    </div>
                                                </Button>

                                                <Button
                                                    size="lg"
                                                    type="button"
                                                    className={(this.state.cancelled && this.state.can_resume) ? "t_green value wauto" : 'hide'}
                                                    block
                                                    disabled={this.state.loading}
                                                    onClick={this.resumeSubscription}
                                                >
                                                    <div>
                                                        Resume Subscription <i aria-hidden="true" className="icon-arrow-right f10" />
                                                    </div>

                                                    <div className={(this.state.loading) ? 'btn-loading btn-s-loading right' : 'hide'}>
                                                        <div className="btn-loader" />
                                                    </div>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>

                                    <SubUserTable
                                        user_id={this.state.user_id}
                                        sub_users={this.state.sub_users}
                                        display_users={this.state.display_users}
                                    />

                                    <div className="tp_row_btm d-flex justify-content-between">
                                        <LeftLink url={config.site_url.dashboard} text="Exit" />
                                    </div>
                                </Form>
                            </div>
                        </div>
                        {
                            (!this.state.cancelled) &&

                            <p className="mt-1 txt-gray text-center">
                                <Button
                                    className="link t_gray cancel-sub"
                                    onClick={this.cancelSubscription}
                                >
                                    Cancel Subscription
                                </Button>
                            </p>
                        }
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default SubscriptionManage;
