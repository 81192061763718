import React from 'react';
import {
    Row,
    Col,
    Form,
    Progress,
    CustomInput,
    Input
} from 'reactstrap';
import Select, {components} from "react-select";
import {
    getBedroomsType,
    getBathroomsType,
    getCarsType,
    getDefaultMapLat,
    getDefaultMapLng,
    getClientId,
    getLoanTypeOptions,
    getLoanType,
    getDefaultLoanCosts,
    getLoanTermOptions,
    getLoanTerm
} from "../../../services/config-helper";
import PropertySnapshot from "../sections/property-snapshot";
import Projection from "../sections/projection";
import {
    addEditFundingStructure,
    getFundingStructure,
    getProperty,
    calLoanRepayment
} from "../../../services/services";
import config from "config/config";
import {
    convertToNumber, formatMoney,
    getMyProfile, getNotificationObject, setFundingTabState,
} from "../../../services/helper";
import {Redirect} from 'react-router';
import ReactNotification from "react-notifications-component";
import RightButton from "../../../components/properlytics/right-button";
import LeftButton from "../../../components/properlytics/left-button";
import MiddleButton from "../../../components/properlytics/middle-button";
import LoanCosts from "./dialogs/loan-costs";
import ExtraRepayment from "../sections/extra-repayment";
import PriceField from "../../../components/numbers/PriceField";
import PercentageField from "../../../components/numbers/PercentageField";
import NumberFormat from 'react-number-format';

/*------------------------------------*/
/* For Select Plugin - (4)            */
/*------------------------------------*/
const groupStyles = {
    borderRadius: '0px',
    background: '#ffffff',
    padding: "0"
};

const Group = (props) => (
    <div style={groupStyles}>
        <components.Group {...props} />
    </div>
);

const style1 = {
    control: (base, state) => ({
        ...base,
        background: state.isFocused ? 0 : 0,
        padding: "0 !important",
        margin: "0 !important",
        border: "0 !important",
        boxShadow: "0 !important",
        "&:hover": {
            border: "0 !important"
        }
    })
};

export default class FundingStructure extends React.Component {

    constructor(props) {
        super(props);
        let lat = getDefaultMapLat();
        let long = getDefaultMapLng();
        let bed = getBedroomsType().value;
        let bath = getBathroomsType().value;
        let car = getCarsType().value;

        let defaultLoanCosts = getDefaultLoanCosts();
        this.state = {
            is_edit: false,
            property_id: '',

            pro_price: 0,

            invested_cash: false,
            deposite_cash: 0,

            front_end_loan: false,
            loan_amount: 0,
            lender_name: '',
            loan_type: getLoanType().value,
            interest_rate: 0,
            from_year: 1,
            to_year: '',
            establishment_fees: 0,
            mortgage_insurance: 0,
            loan_repayment: 0,
            additional_loan_amount: 0,

            back_end_loan: false,
            loan_amount_back: 0,
            lender_name_back: '',
            loan_type_back: getLoanType().value,
            interest_rate_back: 0,
            from_year_back: 1,
            to_year_back: '',
            establishment_fees_back: 0,
            mortgage_insurance_back: 0,
            loan_repayment_back: 0,
            additional_loan_amount_back: 0,

            other_costs: [],

            morgagees_fees: defaultLoanCosts.morgagees_fees,
            valuation_fees: defaultLoanCosts.valuation_fees,
            reg_morgage_1: defaultLoanCosts.reg_morgage_1,
            reg_of_title: defaultLoanCosts.reg_of_title,
            search_fees: defaultLoanCosts.search_fees,

            next: false,
            previous: false,

            progress: 0,

            /*snap shot*/
            latitude: lat,
            longitude: long,
            property_address: '',
            purchase_price: '',
            bedrooms: bed,
            bathrooms: bath,
            car_space: car,
            adv_link: '',
            images: [],

            /*projection*/
            overview: {},

            property: {},
            loading: false,
            loading_previous: false,
            show_dialog: false,
            redirect_to_dashboard: false,
        }

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();

        this.getFundingStructureData = this.getFundingStructureData.bind(this);
        this.onFrontEndLoanChange = this.onFrontEndLoanChange.bind(this);
        this.onBackEndLoanChange = this.onBackEndLoanChange.bind(this);
        this.onOtherCostsChange = this.onOtherCostsChange.bind(this);
        this.addOtherCost = this.addOtherCost.bind(this);
        this.removeOtherCost = this.removeOtherCost.bind(this);
        this.getOtherCosts = this.getOtherCosts.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.calculateLoanRepayment = this.calculateLoanRepayment.bind(this);
        this.hideShowDialog = this.hideShowDialog.bind(this);
        this.setStateData = this.setStateData.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.onNext = this.onNext.bind(this);
        this.onPrevious = this.onPrevious.bind(this);
        this.getAllLoanCosts = this.getAllLoanCosts.bind(this);
        this.calculateAmount = this.calculateAmount.bind(this);
        this.mainAmountChange = this.mainAmountChange.bind(this);
        this.onLoanTermChange = this.onLoanTermChange.bind(this);
        this.additionalAmountChange = this.additionalAmountChange.bind(this);
    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    hideShowDialog() {
        this.setState({
            show_dialog: (!this.state.show_dialog)
        })
        window.scrollTo(0, 0);
    }

    onInputChange(event) {

        let name = event.target.name;
        let value = event.target.value;
        if (name === 'lender_name' || name === 'lender_name_back') {
            this.setState({
                [name]: value
            });
        } else {
            if (!isNaN(value) || value === '') {
                this.setState({
                    [name]: value
                });

                if (name === 'deposite_cash' || name === 'loan_amount' || name === 'loan_amount_back' ||
                    name === 'establishment_fees' || name === 'mortgage_insurance' ||
                    name === 'morgagees_fees' || name === 'valuation_fees' || name === 'reg_morgage_1' ||
                    name === 'reg_of_title' || name === 'search_fees'
                ) {
                    this.calculateAmount(name, value);
                }

                if (name === 'interest_rate') {
                    this.calculateLoanRepayment(
                        this.state.loan_amount,
                        this.state.additional_loan_amount,
                        value,
                        this.state.loan_type,
                        this.state.to_year,
                        this.state.loan_amount_back,
                        this.state.additional_loan_amount_back,
                        this.state.interest_rate_back,
                        this.state.loan_type_back,
                        this.state.to_year_back
                    );
                } else if (name === 'interest_rate_back') {
                    this.calculateLoanRepayment(
                        this.state.loan_amount,
                        this.state.additional_loan_amount,
                        this.state.interest_rate,
                        this.state.loan_type,
                        this.state.to_year,
                        this.state.loan_amount_back,
                        this.state.additional_loan_amount_back,
                        value,
                        this.state.loan_type_back,
                        this.state.to_year_back
                    );
                }
            }
        }
    }

    onLoanTermChange (name, selected) {
        this.setState({
            [name]: selected.value,
        });

        if (name === 'to_year') {
            this.calculateLoanRepayment(
                this.state.loan_amount,
                this.state.additional_loan_amount,
                this.state.interest_rate,
                this.state.loan_type,
                selected.value,
                this.state.loan_amount_back,
                this.state.additional_loan_amount_back,
                this.state.interest_rate_back,
                this.state.loan_type_back,
                this.state.to_year_back
            );
        } else if (name === 'to_year_back') {
            this.calculateLoanRepayment(
                this.state.loan_amount,
                this.state.additional_loan_amount,
                this.state.interest_rate,
                this.state.loan_type,
                this.state.to_year,
                this.state.loan_amount_back,
                this.state.additional_loan_amount_back,
                this.state.interest_rate_back,
                this.state.loan_type_back,
                selected.value
            );
        }
    }

    additionalAmountChange(event) {
        let name = event.target.name;
        let value = convertToNumber(event.target.value);
        if (!isNaN(value) || value === '') {
            this.setState({
                [name]: value
            });

            if (name === 'additional_loan_amount') {
                this.calculateLoanRepayment(
                    this.state.loan_amount,
                    value,
                    this.state.interest_rate,
                    this.state.loan_type,
                    this.state.to_year,
                    this.state.loan_amount_back,
                    this.state.additional_loan_amount_back,
                    this.state.interest_rate_back,
                    this.state.loan_type_back,
                    this.state.to_year_back
                );
            } else if (name === 'additional_loan_amount_back') {
                this.calculateLoanRepayment(
                    this.state.loan_amount,
                    this.state.additional_loan_amount,
                    this.state.interest_rate,
                    this.state.loan_type,
                    this.state.to_year,
                    this.state.loan_amount_back,
                    value,
                    this.state.interest_rate_back,
                    this.state.loan_type_back,
                    this.state.to_year_back
                );
            }
        }
    }

    mainAmountChange(event) {
        let name = event.target.name;
        let value = convertToNumber(event.target.value);
        if (!isNaN(value) || value === '') {
            this.setState({
                [name]: value
            });

            this.calculateAmount(name, value);
        }
    }

    onCheckboxChange(name, event) {
        event.preventDefault();
        let state = this.state;
        let checked = !(state[name]);

        this.setState({
            [name]: checked
        });
        this.calculateAmount(name, checked);
    }

    handleOtherCostOperation = (key, name, value) => {
        let other_costs = this.state.other_costs;

        if (typeof other_costs[key] !== 'undefined') {
            if (name === 'cost_amount') {
                value = (value === '') ? '' : parseInt(value);
                if (typeof other_costs[key] !== 'undefined') {
                    other_costs[key].cost_amount = value;
                }
            }
            if (name === 'cost_name') {
                other_costs[key].cost_name = value;
            }
        }

        this.setState({
            other_costs: other_costs
        })
    }

    onOtherCostsChange = (key, name, event) => {
        if (name === 'cost_amount') {
            if (!isNaN(event.target.value) || event.target.value === '') {
                this.handleOtherCostOperation(key, name, event.target.value)
                this.calculateAmount();
            }
        } else {
            this.handleOtherCostOperation(key, name, event.target.value)
        }
    }

    addOtherCost = () => {
        let other_costs = this.state.other_costs;

        let length = other_costs.length;
        other_costs[length] = {
            cost_name: '',
            cost_amount: '',
        }

        this.setState({
            other_costs: other_costs
        })
    }

    removeOtherCost = (key) => {
        let other_costs = this.state.other_costs;

        if (typeof other_costs[key] !== 'undefined') {
            other_costs.splice(key, 1);
        }

        this.setState({
            other_costs: other_costs
        })
        this.calculateAmount();
    }

    onFrontEndLoanChange(selected) {
        this.setState({
            loan_type: selected.value,
        });

        this.calculateLoanRepayment(
            this.state.loan_amount,
            this.state.additional_loan_amount,
            this.state.interest_rate,
            selected.value,
            this.state.to_year,
            this.state.loan_amount_back,
            this.state.additional_loan_amount_back,
            this.state.interest_rate_back,
            this.state.loan_type_back,
            this.state.to_year_back
        );
    }

    onBackEndLoanChange(selected) {
        this.setState({
            loan_type_back: selected.value,
        });

        this.calculateLoanRepayment(
            this.state.loan_amount,
            this.state.additional_loan_amount,
            this.state.interest_rate,
            this.state.loan_type,
            this.state.to_year,
            this.state.loan_amount_back,
            this.state.additional_loan_amount_back,
            this.state.interest_rate_back,
            selected.value,
            this.state.to_year_back
        );
    }

    calculateLoanRepayment(loan_amount, additional_loan_amount, interest_rate, loan_type, to_year, loan_amount_back, additional_loan_amount_back, interest_rate_back, loan_type_back, to_year_back) {

        let postData = new FormData();
        postData.append('property_id', this.state.property_id);

        postData.append('loan_amount', loan_amount);
        postData.append('additional_loan_amount', additional_loan_amount);
        postData.append('interest_rate', interest_rate);
        postData.append('loan_type', loan_type);
        postData.append('to_year', to_year);

        postData.append('loan_amount_back', loan_amount_back);
        postData.append('additional_loan_amount_back', additional_loan_amount_back);
        postData.append('interest_rate_back', interest_rate_back);
        postData.append('loan_type_back', loan_type_back);
        postData.append('to_year_back', to_year_back);

        calLoanRepayment(postData).then(res => {
            if (res.success) {
                this.setState({
                    loan_repayment: res.data.repayment_detail.front_repayment,
                    loan_repayment_back: res.data.repayment_detail.back_repayment,
                })
            }
        });
    }

    getFundingStructureData(property_id) {
        let postData = new FormData();
        postData.append('property_id', property_id);
        getFundingStructure(postData).then(res => {
            if (res.success) {
                this.setState({
                    pro_price: res.data.funding_structure.property_price,
                })
            }
        });
    }

    getOtherCosts(name) {
        let other_costs = this.state.other_costs;

        let data = [];
        for (let i=0;i<other_costs.length;i++) {
            if (name === 'cost_name') {
                data.push(other_costs[i].cost_name);
            }
            if (name === 'cost_amount') {
                data.push(other_costs[i].cost_amount);
            }
        }

        return data;
    }

    calculateAmount(name = '', value = '') {
        if (this.state.property_id) {
            let prop_price = (name === 'pro_price') ? parseFloat(value) : parseFloat(this.state.pro_price);

            let state_loan_amount = 0;
            let state_loan_amount_back = 0;

            if (name === 'deposite_cash') {

                prop_price += this.getAllLoanCosts();
                if (prop_price > value) {

                    let loan_amount = parseFloat((prop_price - value));

                    loan_amount += parseFloat(this.state.establishment_fees);
                    loan_amount += parseFloat(this.state.mortgage_insurance);

                    if (this.state.front_end_loan && this.state.back_end_loan) {
                        if (this.state.loan_amount && this.state.loan_amount !== '') {
                            loan_amount -= parseFloat(this.state.loan_amount);
                            this.setState({
                                loan_amount_back: parseFloat(loan_amount).toFixed(2)
                            })
                            state_loan_amount_back = parseFloat(loan_amount).toFixed(2);
                        } else {
                            this.setState({
                                front_end_loan: (this.state.additional_loan_amount) ? this.state.front_end_loan : false,
                                loan_amount: 0,
                                loan_amount_back: parseFloat(loan_amount).toFixed(2)
                            })
                            state_loan_amount = 0;
                            state_loan_amount_back = parseFloat(loan_amount).toFixed(2);
                        }
                    } else if (this.state.back_end_loan) {
                        this.setState({
                            loan_amount_back: parseFloat(loan_amount).toFixed(2)
                        })
                        state_loan_amount_back = parseFloat(loan_amount).toFixed(2);
                    } else if (this.state.front_end_loan) {
                        this.setState({
                            loan_amount: parseFloat(loan_amount).toFixed(2)
                        })
                        state_loan_amount = parseFloat(loan_amount).toFixed(2);
                    } else {
                        this.setState({
                            back_end_loan: true,
                            loan_amount_back: parseFloat(loan_amount).toFixed(2)
                        })
                        state_loan_amount_back = parseFloat(loan_amount).toFixed(2);
                    }
                } else {
                    this.setState({
                        front_end_loan: (this.state.additional_loan_amount) ? this.state.front_end_loan : false,
                        back_end_loan: (this.state.additional_loan_amount_back) ? this.state.back_end_loan : false,
                        loan_amount: 0,
                        loan_amount_back: 0
                    })
                    state_loan_amount = 0;
                    state_loan_amount_back = 0;
                }
            } else if (name === 'loan_amount') {
                prop_price += this.getAllLoanCosts();
                prop_price += parseFloat(this.state.establishment_fees);
                prop_price += parseFloat(this.state.mortgage_insurance);

                state_loan_amount = value;
                if (prop_price > value) {
                    let loan_amount = prop_price - value;
                    if (this.state.invested_cash && this.state.back_end_loan) {
                        if (this.state.deposite_cash && this.state.deposite_cash !== '') {
                            loan_amount -= parseFloat(this.state.deposite_cash);
                        }

                        if (loan_amount < 0) {
                            this.setState({
                                back_end_loan: (this.state.additional_loan_amount_back) ? this.state.back_end_loan : false,
                                loan_amount_back: parseFloat(0).toFixed(2)
                            })
                            state_loan_amount_back = 0;
                            if (this.state.deposite_cash && this.state.deposite_cash !== '') {
                                loan_amount += parseFloat(this.state.deposite_cash);
                                if (loan_amount < 0) {
                                    loan_amount = 0;
                                    this.setState({
                                        invested_cash: false,
                                    })
                                }
                                this.setState({
                                    deposite_cash: parseFloat(loan_amount).toFixed(2)
                                })
                            }
                        } else {
                            this.setState({
                                loan_amount_back: parseFloat(loan_amount).toFixed(2)
                            })
                            state_loan_amount_back = parseFloat(loan_amount).toFixed(2);
                            if (parseInt(loan_amount) === 0) {
                                this.setState({
                                    back_end_loan: (this.state.additional_loan_amount_back) ? this.state.back_end_loan : false,
                                })

                                if (this.state.deposite_cash && this.state.deposite_cash !== '') {
                                    let deposite_cash = parseFloat(this.state.deposite_cash);
                                    if (deposite_cash < 0) {
                                        deposite_cash = 0;
                                        this.setState({
                                            invested_cash: false,
                                        })
                                    }
                                    this.setState({
                                        deposite_cash: parseFloat(deposite_cash).toFixed(2)
                                    })
                                }
                            }
                        }
                    } else if (this.state.back_end_loan) {
                        if (loan_amount < 0) {
                            loan_amount = 0;
                            this.setState({
                                back_end_loan: (this.state.additional_loan_amount_back) ? this.state.back_end_loan : false,
                            })
                        }
                        this.setState({
                            loan_amount_back: parseFloat(loan_amount).toFixed(2)
                        })
                        state_loan_amount_back = parseFloat(loan_amount).toFixed(2);
                    } else if (this.state.invested_cash) {
                        if (loan_amount < 0) {
                            loan_amount = 0;
                            this.setState({
                                invested_cash: false,
                            })
                        }
                        this.setState({
                            deposite_cash: parseFloat(loan_amount).toFixed(2)
                        })
                    } else {
                        this.setState({
                            back_end_loan: true,
                            loan_amount_back: parseFloat(loan_amount).toFixed(2)
                        })
                        state_loan_amount_back = parseFloat(loan_amount).toFixed(2);
                    }
                } else {
                    this.setState({
                        invested_cash: false,
                        back_end_loan: (this.state.additional_loan_amount_back) ? this.state.back_end_loan : false,
                    })
                }
            } else if (name === 'loan_amount_back') {
                state_loan_amount_back = value;

                prop_price += this.getAllLoanCosts();
                prop_price += parseFloat(this.state.establishment_fees);
                prop_price += parseFloat(this.state.mortgage_insurance);

                if (prop_price > value) {
                    let loan_amount = prop_price - value;
                    if (this.state.invested_cash && this.state.front_end_loan) {

                        if (this.state.deposite_cash && this.state.deposite_cash !== '') {
                            loan_amount -= parseFloat(this.state.deposite_cash);
                        }

                        if (loan_amount < 0) {
                            this.setState({
                                front_end_loan: (this.state.additional_loan_amount) ? this.state.front_end_loan : false,
                                loan_amount: parseFloat(0).toFixed(2)
                            })

                            state_loan_amount = 0;

                            if (this.state.deposite_cash && this.state.deposite_cash !== '') {
                                loan_amount += parseFloat(this.state.deposite_cash);
                                if (loan_amount < 0) {
                                    loan_amount = 0;
                                    this.setState({
                                        invested_cash: false,
                                    })
                                }
                                this.setState({
                                    deposite_cash: parseFloat(loan_amount).toFixed(2)
                                })
                            }
                        } else {
                            this.setState({
                                loan_amount: parseFloat(loan_amount).toFixed(2)
                            })

                            state_loan_amount = parseFloat(loan_amount).toFixed(2);
                            if (parseInt(loan_amount) === 0) {
                                this.setState({
                                    front_end_loan: (this.state.additional_loan_amount) ? this.state.front_end_loan : false,
                                })

                                if (this.state.deposite_cash && this.state.deposite_cash !== '') {
                                    let deposite_cash = parseFloat(this.state.deposite_cash);
                                    if (deposite_cash < 0) {
                                        deposite_cash = 0;
                                        this.setState({
                                            invested_cash: false,
                                        })
                                    }
                                    this.setState({
                                        deposite_cash: parseFloat(deposite_cash).toFixed(2)
                                    })
                                }
                            }
                        }
                    } else if (this.state.front_end_loan) {
                        if (loan_amount < 0) {
                            loan_amount = 0;
                            this.setState({
                                front_end_loan: (this.state.additional_loan_amount) ? this.state.front_end_loan : false,
                            })
                        }
                        this.setState({
                            loan_amount: parseFloat(loan_amount).toFixed(2)
                        })
                        state_loan_amount = parseFloat(loan_amount).toFixed(2);
                    } else if (this.state.invested_cash) {
                        if (loan_amount < 0) {
                            loan_amount = 0;
                            this.setState({
                                invested_cash: false,
                            })
                        }
                        this.setState({
                            deposite_cash: parseFloat(loan_amount).toFixed(2)
                        })
                    } else {
                        this.setState({
                            front_end_loan: true,
                            loan_amount: parseFloat(loan_amount).toFixed(2)
                        })
                        state_loan_amount = parseFloat(loan_amount).toFixed(2);
                    }
                } else {
                    this.setState({
                        invested_cash: false,
                        front_end_loan: (this.state.additional_loan_amount) ? this.state.front_end_loan : false,
                    })
                }
            } else {
                //when loan cost change

                let invested_cash = (name === 'invested_cash') ? value : this.state.invested_cash;
                let front_end_loan = (name === 'front_end_loan') ? value : this.state.front_end_loan;
                let back_end_loan = (name === 'back_end_loan') ? value : this.state.back_end_loan;

                let loan_costs = 0;
                let other_costs = this.getOtherCosts('cost_amount');
                for (let i=0;i<other_costs.length;i++) {
                    loan_costs += parseFloat(other_costs[i]);
                }

                if (front_end_loan || back_end_loan) {
                    let morgagees_fees = (name === 'morgagees_fees') ? value : this.state.morgagees_fees;
                    let valuation_fees = (name === 'valuation_fees') ? value : this.state.valuation_fees;
                    let reg_morgage_1 = (name === 'reg_morgage_1') ? value : this.state.reg_morgage_1;
                    let reg_of_title = (name === 'reg_of_title') ? value : this.state.reg_of_title;
                    let search_fees = (name === 'search_fees') ? value : this.state.search_fees;
                    if (morgagees_fees) {
                        loan_costs += parseFloat(morgagees_fees);
                    }
                    if (valuation_fees) {
                        loan_costs += parseFloat(valuation_fees);
                    }
                    if (reg_morgage_1) {
                        loan_costs += parseFloat(reg_morgage_1);
                    }
                    if (reg_of_title) {
                        loan_costs += parseFloat(reg_of_title);
                    }
                    if (search_fees) {
                        loan_costs += parseFloat(search_fees);
                    }

                    let establishment_fees = (name === 'establishment_fees') ? value : this.state.establishment_fees;
                    let mortgage_insurance = (name === 'mortgage_insurance') ? value : this.state.mortgage_insurance;
                    if (establishment_fees) {
                        loan_costs += parseFloat(establishment_fees);
                    }
                    if (mortgage_insurance) {
                        loan_costs += parseFloat(mortgage_insurance);
                    }

                    if (back_end_loan) {
                        prop_price += loan_costs;

                        if (invested_cash && this.state.deposite_cash && this.state.deposite_cash !== '') {
                            prop_price -= parseFloat(this.state.deposite_cash);
                        }

                        if (front_end_loan && this.state.loan_amount && this.state.loan_amount !== '') {
                            prop_price -= parseFloat(this.state.loan_amount);
                        }

                        if (prop_price < 0) {
                            prop_price = 0;
                        }

                        this.setState({
                            loan_amount_back: parseFloat(prop_price).toFixed(2)
                        })
                        state_loan_amount_back = parseFloat(prop_price).toFixed(2);
                    } else if (front_end_loan) {
                        prop_price += loan_costs;

                        if (invested_cash && this.state.deposite_cash && this.state.deposite_cash !== '') {
                            prop_price -= parseFloat(this.state.deposite_cash);
                        }

                        if (prop_price < 0) {
                            prop_price = 0;
                        }

                        this.setState({
                            loan_amount: parseFloat(prop_price).toFixed(2)
                        })
                        state_loan_amount = parseFloat(prop_price).toFixed(2);
                    }
                } else {
                    prop_price += loan_costs;

                    if (prop_price < 0) {
                        prop_price = 0;
                    }

                    this.setState({
                        invested_cash: true,
                        deposite_cash: parseFloat(prop_price).toFixed(2)
                    })
                }
            }

            this.calculateLoanRepayment(
                state_loan_amount,
                this.state.additional_loan_amount,
                this.state.interest_rate,
                this.state.loan_type,
                this.state.to_year,
                state_loan_amount_back,
                this.state.additional_loan_amount_back,
                this.state.interest_rate_back,
                this.state.loan_type_back,
                this.state.to_year_back
            );
        }
    }

    getAllLoanCosts() {
        let total = 0;

        let other_costs = this.getOtherCosts('cost_amount');
        for (let i=0;i<other_costs.length;i++) {
            total += parseFloat(other_costs[i]);
        }

        if (this.state.morgagees_fees) {
            total += parseFloat(this.state.morgagees_fees);
        }
        if (this.state.valuation_fees) {
            total += parseFloat(this.state.valuation_fees);
        }
        if (this.state.reg_morgage_1) {
            total += parseFloat(this.state.reg_morgage_1);
        }
        if (this.state.reg_of_title) {
            total += parseFloat(this.state.reg_of_title);
        }
        if (this.state.search_fees) {
            total += parseFloat(this.state.search_fees);
        }

        return parseFloat(total);
    }

    onNext() {
        if (this.state.is_edit) {
            this.saveData(true);
        } else {
            this.showNotification('Please first enter property detail', 'danger')
        }
    }

    onPrevious() {
        if (this.state.is_edit) {
            this.saveData(false);
        } else {
            this.setState({
                previous: true,
            })
        }
    }

    saveData(isNext = true) {
        let loadingState = (isNext) ? 'loading' : 'loading_previous';
        let goToState = (isNext) ? 'next' : 'previous';

        this.setState({
            [loadingState]: true
        })

        let postData = new FormData();
        postData.append('property_id', this.state.property_id);
        postData.append('invested_cash', (this.state.invested_cash) ? 1 : 0);
        postData.append('deposite_cash', this.state.deposite_cash);
        postData.append('front_end_loan', (this.state.front_end_loan) ? 1 : 0);
        if (this.state.front_end_loan) {
            postData.append('loan_amount', this.state.loan_amount);
            postData.append('lender_name', this.state.lender_name);
            postData.append('loan_type', this.state.loan_type);
            postData.append('interest_rate', this.state.interest_rate);
            postData.append('from_year', this.state.from_year);
            postData.append('to_year', this.state.to_year);
            postData.append('additional_loan_amount', this.state.additional_loan_amount);
        }
        postData.append('back_end_loan', (this.state.back_end_loan) ? 1 : 0);
        if (this.state.back_end_loan) {
            postData.append('loan_amount_back', this.state.loan_amount_back);
            postData.append('lender_name_back', this.state.lender_name_back);
            postData.append('loan_type_back', this.state.loan_type_back);
            postData.append('interest_rate_back', this.state.interest_rate_back);
            postData.append('from_year_back', this.state.from_year_back);
            postData.append('to_year_back', this.state.to_year_back);
            postData.append('additional_loan_amount_back', this.state.additional_loan_amount_back);
        }
        if (this.state.front_end_loan || this.state.back_end_loan) {
            postData.append('establishment_fees', this.state.establishment_fees);
            postData.append('mortgage_insurance', this.state.mortgage_insurance);
            postData.append('morgagees_fees', this.state.morgagees_fees);
            postData.append('valuation_fees', this.state.valuation_fees);
            postData.append('reg_morgage_1', this.state.reg_morgage_1);
            postData.append('reg_of_title', this.state.reg_of_title);
            postData.append('search_fees', this.state.search_fees);
        }

        let cost_name = this.getOtherCosts('cost_name');
        for (let i = 0; i < cost_name.length; i++) {
            postData.append('cost_name[]', cost_name[i]);
        }
        let cost_amount = this.getOtherCosts('cost_amount');
        for (let i = 0; i < cost_amount.length; i++) {
            postData.append('cost_amount[]', cost_amount[i]);
        }

        addEditFundingStructure(postData).then(res => {
            if (res.success) {
                this.showNotification(res.message, 'success')
                this.setState({
                    [goToState]: true,
                    property: res.data.property
                })
            } else {
                this.showNotification(res.message, 'danger')
            }
            this.setState({
                [loadingState]: false
            })
        });
    }

    setStateData(property) {
        let stateData = setFundingTabState(property);
        this.setState(stateData);
        this.setState({
            is_edit: true,
        })
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.match.path === config.site_url.funding_structure + '/:id') {
            //is edit call
            let property_id = this.props.match.params.id;
            if (property_id) {

                if (typeof this.props.location.property !== 'undefined' && this.props.location.property) {
                    this.setStateData(this.props.location.property);
                    this.getFundingStructureData(property_id);
                } else {
                    let postData = {
                        property_id: property_id
                    }

                    getProperty(postData).then(res => {
                        if (res.success) {
                            this.setStateData(res.data.property);
                            this.getFundingStructureData(property_id);
                        } else {
                            if (res.error) {
                                if (typeof res.error !== 'undefined' && typeof res.error.auth_code !== 'undefined' &&
                                    res.error.auth_code === 401
                                ) {
                                    this.setState({
                                        redirect_to_dashboard: true,
                                    })
                                }
                            }
                            this.showNotification(res.message, 'danger')
                        }
                    });
                }

            } else {
                this.setState({
                    redirect_to_dashboard: true,
                })
            }
        } else {
            let profile = getMyProfile();
            if (profile) {
                if (profile.account_type === 2) {
                    let client_id = (getClientId()) || '';
                    if (client_id === '') {
                        this.setState({
                            redirect_to_dashboard: true,
                        })
                    }
                }
            }
        }
    }

    render() {

        //if business account and client value not pass as params then redirect to dashboard
        if (this.state.redirect_to_dashboard) {
            return <Redirect to={config.site_url.dashboard} />;
        }

        //if next success then redirect to next tab
        if (this.state.next) {
            return <Redirect
                to={{
                    pathname: config.site_url.investment_outcome + '/' + this.state.property_id,
                    property: this.state.property
                }}
            />;
        }

        //if next success then redirect to next tab
        if (this.state.previous) {
            return <Redirect
                to={{
                    pathname: config.site_url.acquisition_cost + '/' + this.state.property_id,
                    property: this.state.property
                }}
            />;
        }


        return <div className="">

            <ReactNotification ref={this.notificationDOMRef} />

            <Row>
                <Col md="6">
                    {/*--------------------------------------------------------------------------------*/}
                    {/*ACQUISITION COST DETAIL*/}
                    {/*--------------------------------------------------------------------------------*/}
                    <div className="prpty_flipper blocks">
                        <div className="block">
                            <div className={(this.state.show_dialog) ? "plisting_form rect_bx flip_bx go" : "plisting_form rect_bx flip_bx"}>
                                <div className="head_tron d-flex">
                                    <h2 className="head  fx_w t_green">Funding Structure</h2>

                                    <Progress value={this.state.progress} />

                                    <div className="progress-no t_green">{this.state.progress}%</div>
                                </div>

                                <div className="inner_bx">
                                    <Form id="prp_detail">

                                        <div className="tp_row pr-0 pt-0 pb-0 height60">
                                            <div className="hd_row height60 d-flex pr-40 justify-content-between">
                                                <h4 className="sm_tit fx_w t_black t_upper">cash invested</h4>

                                                <label className="switch" onClick={this.onCheckboxChange.bind(this, 'invested_cash')}>
                                                    <CustomInput
                                                        type="checkbox"
                                                        name="invested_cash"
                                                        checked={this.state.invested_cash}
                                                    />
                                                    <span className="slider round1"/>
                                                </label>
                                            </div>

                                            <div className={`hd_row d-flex inner_row` + ((this.state.invested_cash) ? `` : ` hide`)}>
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Cash Deposit
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <NumberFormat
                                                                thousandSeparator={true}
                                                                prefix={config.price_prefix}
                                                                name="deposite_cash"
                                                                id="deposite_cash"
                                                                className="form-control text-right"
                                                                placeholder="$0"
                                                                value={(formatMoney(this.state.deposite_cash, 2, '.', '') === '0.00') ? "" : this.state.deposite_cash}
                                                                maxLength={config.price_max_length}
                                                                onChange={this.mainAmountChange}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tp_row pr-0 pt-0 pb-0 height60">
                                            <div className="hd_row height60 d-flex pr-40 justify-content-between">
                                                <h4 className="sm_tit fx_w t_black t_upper">Deposit & costs loan</h4>

                                                <label className="switch" onClick={this.onCheckboxChange.bind(this, 'front_end_loan')}>
                                                    <CustomInput
                                                        type="checkbox"
                                                        name="front_end_loan"
                                                        checked={this.state.front_end_loan}
                                                    />
                                                    <span className="slider round1"/>
                                                </label>
                                            </div>


                                            <div className={`hd_row d-flex inner_row` + ((this.state.front_end_loan) ? `` : ` hide`)}>
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Loan Amount
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <NumberFormat
                                                                thousandSeparator={true}
                                                                prefix={config.price_prefix}
                                                                name="loan_amount"
                                                                id="loan_amount"
                                                                className="form-control text-right"
                                                                placeholder="$0"
                                                                value={(formatMoney(this.state.loan_amount, 2, '.', '') === '0.00') ? "" : this.state.loan_amount}
                                                                maxLength={config.price_max_length}
                                                                onChange={this.mainAmountChange}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className={`hd_row d-flex inner_row` + ((this.state.front_end_loan) ? `` : ` hide`)}>
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Name of Lender
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                           <Input
                                                               type="text"
                                                               className="form-control text-right"
                                                               placeholder="Enter Lender Name"
                                                               name="lender_name"
                                                               value={this.state.lender_name}
                                                               onChange={this.onInputChange}
                                                           />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className={`hd_row d-flex inner_row` + ((this.state.front_end_loan) ? `` : ` hide`)}>
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height60 inner_select brd-top justify-content-between">
                                                    <Select
                                                        defaultValue={getLoanType()}
                                                        options={getLoanTypeOptions()}
                                                        components={{ Group }}
                                                        styles={style1}
                                                        value={getLoanType(this.state.loan_type)}
                                                        onChange={this.onFrontEndLoanChange}
                                                    />
                                                </div>
                                            </div>

                                            <div className={`hd_row d-flex inner_row` + ((this.state.front_end_loan) ? `` : ` hide`)}>
                                                <h4 className="sm_tit fx_w t_upper">
                                                    Interest
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Interest Rate (%)
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <PercentageField
                                                                className="form-control text-right"
                                                                name="interest_rate"
                                                                placeholder="0%"
                                                                value={(formatMoney(this.state.interest_rate, 2, '.', '') === '0.00') ? "" : this.state.interest_rate}
                                                                onChange={this.onInputChange}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className={`hd_row d-flex inner_row loan_term` + ((this.state.front_end_loan) ? `` : ` hide`)}>
                                                <h4 className="sm_tit fx_w t_upper">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Loan Term (year)
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <Select
                                                                defaultValue={getLoanTerm()}
                                                                options={getLoanTermOptions()}
                                                                components={{ Group }}
                                                                styles={style1}
                                                                onChange={this.onLoanTermChange.bind(this, 'to_year')}
                                                                value={getLoanTerm(this.state.to_year)}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className={`hd_row d-flex inner_row` + ((this.state.front_end_loan) ? `` : ` hide`)}>
                                                <h4 className="sm_tit fx_w t_upper">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Loan Repayment (P/M)
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <NumberFormat
                                                                thousandSeparator={true}
                                                                prefix={config.price_prefix}
                                                                name="loan_repayment"
                                                                id="loan_repayment"
                                                                className="form-control text-right"
                                                                value={this.state.loan_repayment}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className={`hd_row d-flex inner_row` + ((this.state.front_end_loan) ? `` : ` hide`)}>
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Additional Loan Amount
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <NumberFormat
                                                                thousandSeparator={true}
                                                                prefix={config.price_prefix}
                                                                name="additional_loan_amount"
                                                                id="additional_loan_amount"
                                                                className="form-control text-right"
                                                                placeholder="$0"
                                                                value={(formatMoney(this.state.additional_loan_amount, 2, '.', '') === '0.00') ? "" : this.state.additional_loan_amount}
                                                                maxLength={config.price_max_length}
                                                                onChange={this.additionalAmountChange}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <div className="tp_row pr-0 pt-0 pb-0 height60">
                                            <div className="hd_row height60 d-flex pr-40 justify-content-between">
                                                <h4 className="sm_tit fx_w t_black t_upper">purchase loan</h4>

                                                <label className="switch" onClick={this.onCheckboxChange.bind(this, 'back_end_loan')}>
                                                    <CustomInput
                                                        type="checkbox"
                                                        name="back_end_loan"
                                                        checked={this.state.back_end_loan}
                                                    />
                                                    <span className="slider round1"/>
                                                </label>
                                            </div>


                                            <div className={`hd_row d-flex inner_row` + ((this.state.back_end_loan) ? `` : ` hide`)}>
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Loan Amount
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <NumberFormat
                                                                thousandSeparator={true}
                                                                prefix={config.price_prefix}
                                                                name="loan_amount_back"
                                                                id="loan_amount_back"
                                                                className="form-control text-right"
                                                                placeholder="$0"
                                                                value={(formatMoney(this.state.loan_amount_back, 2, '.', '') === '0.00') ? "" : this.state.loan_amount_back}
                                                                maxLength={config.price_max_length}
                                                                onChange={this.mainAmountChange}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className={`hd_row d-flex inner_row` + ((this.state.back_end_loan) ? `` : ` hide`)}>
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Name of Lender
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                           <Input
                                                               type="text"
                                                               className="form-control text-right"
                                                               placeholder="Enter Lender Name"
                                                               name="lender_name_back"
                                                               value={this.state.lender_name_back}
                                                               onChange={this.onInputChange}
                                                           />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className={`hd_row d-flex inner_row` + ((this.state.back_end_loan) ? `` : ` hide`)}>
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height60 inner_select brd-top justify-content-between">
                                                    <Select
                                                        defaultValue={getLoanType()}
                                                        options={getLoanTypeOptions()}
                                                        components={{ Group }}
                                                        styles={style1}
                                                        value={getLoanType(this.state.loan_type_back)}
                                                        onChange={this.onBackEndLoanChange}
                                                    />
                                                </div>
                                            </div>

                                            <div className={`hd_row d-flex inner_row` + ((this.state.back_end_loan) ? `` : ` hide`)}>
                                                <h4 className="sm_tit fx_w t_upper">
                                                    Interest
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Interest Rate (%)
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <PercentageField
                                                                className="form-control text-right"
                                                                name="interest_rate_back"
                                                                placeholder="0%"
                                                                value={(formatMoney(this.state.interest_rate_back, 2, '.', '') === '0.00') ? "" : this.state.interest_rate_back}
                                                                onChange={this.onInputChange}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className={`hd_row d-flex inner_row loan_term` + ((this.state.back_end_loan) ? `` : ` hide`)}>
                                                <h4 className="sm_tit fx_w t_upper">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Loan Term (year)
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <Select
                                                                defaultValue={getLoanTerm()}
                                                                options={getLoanTermOptions()}
                                                                components={{ Group }}
                                                                styles={style1}
                                                                onChange={this.onLoanTermChange.bind(this, 'to_year_back')}
                                                                value={getLoanTerm(this.state.to_year_back)}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className={`hd_row d-flex inner_row` + ((this.state.back_end_loan) ? `` : ` hide`)}>
                                                <h4 className="sm_tit fx_w t_upper">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Loan Repayment (P/M)
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <NumberFormat
                                                                thousandSeparator={true}
                                                                prefix={config.price_prefix}
                                                                name="loan_repayment_back"
                                                                id="loan_repayment_back"
                                                                className="form-control text-right"
                                                                value={this.state.loan_repayment_back}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className={`hd_row d-flex inner_row` + ((this.state.back_end_loan) ? `` : ` hide`)}>
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Additional Loan Amount
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <NumberFormat
                                                                thousandSeparator={true}
                                                                prefix={config.price_prefix}
                                                                name="additional_loan_amount_back"
                                                                id="additional_loan_amount_back"
                                                                className="form-control text-right"
                                                                placeholder="$0"
                                                                value={(formatMoney(this.state.additional_loan_amount_back, 2, '.', '') === '0.00') ? "" : this.state.additional_loan_amount_back}
                                                                maxLength={config.price_max_length}
                                                                onChange={this.additionalAmountChange}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tp_row pr-0 pt-0 pb-0 height60">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">
                                                    Other Costs
                                                </h4>

                                                <div className="d-flex w100 height38 justify-content-end">
                                                    <span className="txt-gray act_icon">
                                                        <i className="f20 icon-plus" onClick={this.addOtherCost}/>
                                                    </span>
                                                </div>
                                            </div>

                                            <MapOtherCosts
                                                other_costs={this.state.other_costs}
                                                onInputChange={this.onOtherCostsChange}
                                                removeOtherCost={this.removeOtherCost}
                                            />
                                        </div>

                                        <div className="tp_row_btm d-flex justify-content-between">
                                            <LeftButton text="Previous" onClick={this.onPrevious} loading={this.state.loading_previous}/>

                                            <MiddleButton text="expanded loan costs" onClick={this.hideShowDialog}/>

                                            <RightButton text="Next" onClick={this.onNext} loading={this.state.loading} />
                                        </div>
                                    </Form>
                                </div>
                            </div>

                            <LoanCosts
                                show_dialog={this.state.show_dialog}
                                establishment_fees={this.state.establishment_fees}
                                mortgage_insurance={this.state.mortgage_insurance}
                                morgagees_fees={this.state.morgagees_fees}
                                valuation_fees={this.state.valuation_fees}
                                reg_morgage_1={this.state.reg_morgage_1}
                                reg_of_title={this.state.reg_of_title}
                                search_fees={this.state.search_fees}
                                onInputChange={this.mainAmountChange}
                                hideShowDialog={this.hideShowDialog}
                            />
                        </div>
                    </div>


                    <PropertySnapshot
                        property_id={this.state.property_id}
                        lat={this.state.latitude}
                        lng={this.state.longitude}
                        address={this.state.property_address}
                        price={this.state.purchase_price}
                        bed={this.state.bedrooms}
                        bath={this.state.bathrooms}
                        car={this.state.car_space}
                        adv_link={this.state.adv_link}
                        images={this.state.images}
                        showNotification={this.showNotification}
                    />
                </Col>

                <Col md={6}>
                    <Projection
                        property_id={this.state.property_id}
                        progress={this.state.progress}
                        overview={this.state.overview}
                        showNotification={this.showNotification}
                    />

                    <ExtraRepayment
                        property_id={this.state.property_id}
                        loan_amount={this.state.loan_amount}
                        additional_loan_amount={this.state.additional_loan_amount}
                        interest_rate={this.state.interest_rate}
                        to_year={this.state.to_year}
                        loan_type={this.state.loan_type}
                        loan_amount_back={this.state.loan_amount_back}
                        additional_loan_amount_back={this.state.additional_loan_amount_back}
                        interest_rate_back={this.state.interest_rate_back}
                        to_year_back={this.state.to_year_back}
                        loan_type_back={this.state.loan_type_back}
                    />
                </Col>
            </Row>
        </div>

    }
}


const MapOtherCosts = ({other_costs, onInputChange, removeOtherCost}) => {
    if(other_costs.length > 0){
        return other_costs.map( (item, key) => {
            return (
                <div className="hd_row d-flex inner_row">
                    <h4 className="sm_tit fx_w">
                    </h4>

                    <div className="d-flex w100 height38 brd-top justify-content-between">
                        <h4 className="sm_tit fx_w">
                            <Input
                                type="text"
                                className="form-control"
                                placeholder="Name of cost"
                                name="cost_name[]"
                                value={item.cost_name}
                                onChange={onInputChange.bind(this, key, 'cost_name')}
                            />
                        </h4>

                        <span className="d-flex act_icon height60">
                            <span className="value t_black pmedium">
                                <PriceField
                                    placeholder="Cost Amount"
                                    name="cost_amount[]"
                                    value={item.cost_amount}
                                    onChange={onInputChange.bind(this, key, 'cost_amount')}
                                />
                            </span>
                            <i
                                className="f20 txt-gray icon-close"
                                onClick={removeOtherCost.bind(this, key)}
                            />
                        </span>
                    </div>
                </div>
            )
        })
    }
    return null;
}