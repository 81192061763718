import React from 'react';
import {
    Row,
    Col,
    Card,
    CardTitle,
    CardBody,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Form,
    Button
} from 'reactstrap';
import {addSubUser, deleteSubUser, editSubUser, getSubUser} from "../../services/services";
import {getNotificationObject, isAdminUser} from "../../services/helper";
import ReactNotification from "react-notifications-component";
import camera from '../../assets/images/camera.png';
import validator from "./user-validators";
import {Redirect} from 'react-router';
import config from 'config/config';
import RightButton from "../../components/properlytics/right-button";
import LeftLink from "../../components/properlytics/left-link";
import {confirmAlert} from "react-confirm-alert";

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

class SubUserForm extends React.Component {
    constructor(props) {
        super(props);

        this.validators = validator;
        this.onFileChange = this.onFileChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.formValidators = this.formValidators.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.openUpload = this.openUpload.bind(this);
        this.handleSwitch = this.handleSwitch.bind(this);
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.showErrors = this.showErrors.bind(this);
        this.deleteUser = this.deleteUser.bind(this);

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();

        this.state = {
            is_edit: false,
            edit_user_id: '',
            profile_pic: '',
            img_display: false,
            profile_pic_url: '',
            email: '',
            password: '',
            first_name: '',
            last_name: '',
            is_active: true,
            loading: false,
            page_loading: false,

            redirect: false,
            redirect_to_add: false,
            redirect_to_dashboard: false,
        }
    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    onFileChange(event) {

        getBase64(event.target.files[0], imageUrl => this.setState({
            profile_pic_url: imageUrl,
        }));

        this.setState({
            [event.target.name]: event.target.files[0],
            img_display: true,
        });
    }

    onInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        this.formValidators([event.target.name], event.target.value);
    }

    formValidators(fieldName, value) {
        this.validators[fieldName].errors = [];
        this.validators[fieldName].state = value;
        this.validators[fieldName].valid = true;
        this.validators[fieldName].rules.forEach((rule) => {
            if (rule.test instanceof RegExp) {
                if (!rule.test.test(value)) {
                    this.validators[fieldName].errors.push(rule.message);
                    this.validators[fieldName].valid = false;
                }
            } else if (typeof rule.test === 'function') {
                if (!rule.test(value)) {
                    this.validators[fieldName].errors.push(rule.message);
                    this.validators[fieldName].valid = false;
                }
            }
        });
    }

    validForm() {
        let status = true;
        Object.keys(this.validators).forEach((field) => {
            if (this.state.is_edit) {
                //edit
                if (field === 'first_name' || field === 'last_name') {
                    if (!this.validators[field].valid) {
                        status = false;
                    }
                }
            } else {
                //add
                if (field === 'email' || field === 'password' || field === 'first_name' || field === 'last_name') {
                    if (!this.validators[field].valid) {
                        console.log(field);
                        status = false;
                    }
                }
            }
        });
        return status;
    }

    showErrors() {
        let fields = [];
        if (this.state.is_edit) {
            fields = ['first_name', 'last_name'];
        } else {
            fields = ['email', 'password', 'first_name', 'last_name'];
        }

        let errors = '';
        let return_error = false;
        for (let index = 0; index < fields.length ; index++) {
            const validator = this.validators[fields[index]];

            if (validator && !validator.valid) {
                for (let i =0 ;i<validator.errors.length;i++) {
                    errors += validator.errors[i];
                    break;
                }
            }

            if (return_error) {
                break;
            }
        }
        return errors;
    }

    onSubmit() {
        if (this.validForm()) {
            this.setState({
                loading: true
            })
            let postData = new FormData();
            if (this.state.is_edit) {
                postData.append('sub_user_id', this.state.edit_user_id);
            }
            postData.append('email', this.state.email);
            postData.append('profile_pic', this.state.profile_pic);
            postData.append('password', this.state.password);
            postData.append('first_name', this.state.first_name);
            postData.append('last_name', this.state.last_name);
            postData.append('is_active', (this.state.is_active) ? 1 : 0);

            let callService = (this.state.is_edit) ? editSubUser(postData) : addSubUser(postData);

            callService.then(res => {
                if (res.success) {
                    this.showNotification(res.message, 'success')
                    this.setState({
                        redirect: true
                    })
                } else {
                    this.showNotification(res.message, 'danger')
                }
                this.setState({
                    loading: false
                })
            });
        } else {
            let msg = this.showErrors();
            if (msg.toString() === '') {
                this.showNotification('Please enter all required data', 'danger')
            } else {
                this.showNotification(msg, 'danger')
            }
        }
    }

    openUpload = () => {
        document.querySelector('input[type=\'file\']').click();
    }

    deleteConfirm = () => {
        let user_id = this.state.edit_user_id;
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete this user?',
            buttons: [
                {
                    label: 'Cancel',
                },
                {
                    label: 'Delete',
                    onClick: () => this.deleteUser(user_id)
                }
            ]
        }, user_id);
    }

    deleteUser(user_id) {
        let postData = {
            sub_user_id: user_id,
        }

        deleteSubUser(postData).then(res => {
            if (res.success) {
                this.showNotification(res.message, 'success')
                this.setState({
                    redirect: true
                })
            } else {
                this.showNotification(res.message, 'danger')
            }
        });
    }

    componentDidMount() {

        if (!isAdminUser()) {
            this.setState({
                redirect_to_dashboard: true
            })
        }

        if (this.props.match.path === config.site_url.sub_user_edit + '/:id') {
            //is edit call
            let user_id = this.props.match.params.id;
            if (user_id) {

                this.setState({
                    page_loading: true
                })

                let postData = {
                    get_user_id: user_id
                }

                getSubUser(postData).then(res => {
                    if (res.success) {

                        let user = res.data.sub_user;
                        this.setState({
                            is_edit: true,
                            edit_user_id: user.user_id,
                            img_display: (user.profile_pic),
                            profile_pic_url: (user.profile_pic) ? user.profile_pic : '',
                            email: user.email,
                            first_name: user.first_name,
                            last_name: user.last_name,
                            is_active: (user.is_active)
                        })

                        this.setState({
                            page_loading: false
                        })

                        this.formValidators('first_name', user.first_name);
                        this.formValidators('last_name', user.last_name);
                    } else {
                        this.setState({
                            redirect_to_add: true,
                        })
                    }
                });


            } else {
                this.setState({
                    redirect_to_add: true,
                })
            }
        }
    }

    handleSwitch() {

        let is_active = true;
        if (this.state.is_active) {
            is_active = false;
        }

        this.setState({
            is_active: is_active
        })
    }

    render() {

        if (this.state.redirect) {
            return <Redirect to={config.site_url.subscription}/>;
        }

        if (this.state.redirect_to_dashboard) {
            return <Redirect to={config.site_url.dashboard}/>;
        }

        if (this.state.redirect_to_add) {
            return <Redirect to={config.site_url.sub_user_add}/>;
        }

        return <div className="">
            {/*--------------------------------------------------------------------------------*/}
            {/* Start Notification */}
            {/*--------------------------------------------------------------------------------*/}
            <ReactNotification ref={this.notificationDOMRef} />
            {/*--------------------------------------------------------------------------------*/}
            {/* End Notification */}
            {/*--------------------------------------------------------------------------------*/}

            {/*--------------------------------------------------------------------------------*/}
            {/*Login Cards*/}
            {/*--------------------------------------------------------------------------------*/}
            <div className="auth-wrapper no-block justify-content-center align-items-center">

                <div className={this.state.page_loading ? `page-loader show` : `page-loader hide`} />

                <div className={(this.state.page_loading) ? 'auth-box hide' : 'auth-box'}>
                    <Row>
                        <Col md="12">
                            <CardTitle className="f32 text-center mb-0">
                                User Details
                            </CardTitle>
                            <p className="mt-3 txt-gray text-center">
                                {(this.state.is_edit ? 'Update ' : 'Add new ')}user information
                            </p>
                        </Col>
                    </Row>

                    <div id="loginform">
                        <Card>
                            <CardBody className="">
                                <Row>
                                    <Col xs="12">
                                        <Form id="userdetail">

                                            <InputGroup>
                                                <Col md="12">
                                                    <div className={(this.state.img_display ? 'hide' : 'user-img')}>
                                                        <div className="avtar">
                                                            <img src={camera} width="40" alt="user_image" onClick={this.openUpload}/>
                                                        </div>

                                                        <Input
                                                            type="file"
                                                            className="hide"
                                                            name="profile_pic"
                                                            id="profile_pic"
                                                            onChange={this.onFileChange}
                                                        />

                                                        <div className="change-img" onClick={this.openUpload}>
                                                            <i className="fa fa-plus" />
                                                        </div>
                                                    </div>

                                                    <div className={(this.state.img_display ? 'user-img' : 'hide')}>
                                                        <div className="avtar-img">
                                                            <img src={this.state.profile_pic_url} width="40" alt="user_image"/>
                                                        </div>

                                                        <div className="change-img" onClick={this.openUpload}>
                                                            <i className="fa fa-pencil-alt" />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Email*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter your email"
                                                    name="email"
                                                    id="email"
                                                    value={this.state.email}
                                                    onChange={this.onInputChange}
                                                    readOnly={(this.state.is_edit)}
                                                />
                                            </InputGroup>

                                            <InputGroup className={(this.state.is_edit) ? "" : "password-note-group"}>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Password*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="password"
                                                    placeholder="Enter your password"
                                                    name="password"
                                                    id="password"
                                                    value={this.state.password}
                                                    onChange={this.onInputChange}
                                                />
                                                <label className={(this.state.is_edit) ? "hide" : "password-note"}>
                                                    Use 5 or more characters with a mix of letters, numbers & symbols
                                                </label>
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        First Name*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter First Name"
                                                    name="first_name"
                                                    id="first_name"
                                                    value={this.state.first_name}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Surname*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter Last Name"
                                                    name="last_name"
                                                    id="last_name"
                                                    value={this.state.last_name}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Access*
                                                    </InputGroupText>
                                                </InputGroupAddon>

                                                <div className="field check-round slide-inverse">
                                                    <span className="sts">No </span>
                                                    <input
                                                        type="checkbox"
                                                        name="is_active"
                                                        id="check-slide-i"
                                                        checked={(this.state.is_active)}
                                                    />
                                                    <label for="check-slide-i">
                                                        <span onClick={this.handleSwitch}/>
                                                    </label>
                                                    <span className="sts">Yes </span>
                                                </div>
                                            </InputGroup>

                                            <div className="d-flex no-block align-items-cente auth-footer">
                                                <LeftLink text="Cancel" url={config.site_url.subscription} />

                                                <div className={(this.state.is_edit) ? 'center_bx' : 'hide'}>
                                                    <Button className="dlt_btn" size="lg" type="button" onClick={this.deleteConfirm} block >
                                                        <div>
                                                            Delete user
                                                        </div>
                                                    </Button>
                                                </div>

                                                <RightButton text={(this.state.is_edit) ? 'Update' : 'Create'} onClick={this.onSubmit} loading={this.state.loading} />
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default SubUserForm;
