import React from 'react';
import {
    Form,
} from 'reactstrap';
import RightButton from "components/properlytics/right-button";
import LeftButton from "components/properlytics/left-button";
import MiddleButton from "../../../../components/properlytics/middle-button";
import PercentageField from "../../../../components/numbers/PercentageField";
import PriceField from "../../../../components/numbers/PriceField";

export default class TaxBenefits extends React.Component {
    render() {
        const {
            is_joint,
            investor,
            partner,
            i_income,
            p_income,
            income,
            i_rental_income,
            p_rental_income,
            rental_income,
            i_total_income,
            p_total_income,
            total_income,
            i_rental_deduction,
            p_rental_deduction,
            rental_deduction,
            i_new_tax_income,
            p_new_tax_income,
            new_tax_income,
            i_present_tax,
            p_present_tax,
            present_tax,
            i_new_tax,
            p_new_tax,
            new_tax,
            i_tax_saving,
            p_tax_saving,
            tax_saving,
        } = this.props;

        let classnames = 'grd_bx popup-wrap flip_bx1 tax_benefits';
        if (this.props.show_dialog2) {
            if (!this.props.show_dialog3) {
                classnames += ' go';
            }
        }

        return <div className={classnames}>
            <div className="rect_bx flip_bx1">
                <div className="head_tron d-flex">
                    <span className="flipbtn t_black rotate">
                        <i className="icon-arrow-up-circle"/>
                    </span>
                    <h2 className="head pl-2 t_black">Tax Benefits</h2>
                </div>

                <div className="inner_bx">
                    <Form>
                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span/>
                                    <span>Investor</span>
                                    <span className={is_joint ? '' : 'hide'}>Partner</span>
                                    <span className="text-right">Total</span>
                                </div>
                            </div>
                        </div>

                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span>Ownership</span>
                                    <span>
                                        <PercentageField
                                            className="form-control lpad0"
                                            placeholder="100%"
                                            name="tb_investor"
                                            value={investor}
                                            readOnly={true}
                                        />
                                    </span>
                                    <span className={is_joint ? '' : 'hide'}>
                                        <PercentageField
                                            className="form-control lpad0"
                                            placeholder="0%"
                                            name="tb_partner"
                                            value={partner}
                                            readOnly={true}
                                        />
                                    </span>
                                    <span>
                                        <PercentageField
                                            className="form-control text-right rpad0"
                                            placeholder="100%"
                                            name="tb_total"
                                            value={'100'}
                                            readOnly={true}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span>Current Taxable Income</span>
                                    <span>
                                        <PriceField
                                            className="form-control lpad0"
                                            placeholder="$0"
                                            name="i_income"
                                            value={i_income}
                                            readOnly={true}
                                        />
                                    </span>
                                    <span className={is_joint ? '' : 'hide'}>
                                        <PriceField
                                            className="form-control lpad0"
                                            placeholder="$0"
                                            name="p_income"
                                            value={p_income}
                                            readOnly={true}
                                        />
                                    </span>
                                    <span>
                                        <PriceField
                                            className="form-control text-right rpad0"
                                            placeholder="$0"
                                            name="income"
                                            value={income}
                                            readOnly={true}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span>Rental Income</span>
                                    <span>
                                        <PriceField
                                            className="form-control lpad0"
                                            placeholder="$0"
                                            name="i_rental_income"
                                            value={i_rental_income}
                                            readOnly={true}
                                        />
                                    </span>
                                    <span className={is_joint ? '' : 'hide'}>
                                        <PriceField
                                            className="form-control lpad0"
                                            placeholder="$0"
                                            name="p_rental_income"
                                            value={p_rental_income}
                                            readOnly={true}
                                        />
                                    </span>
                                    <span>
                                        <PriceField
                                            className="form-control text-right rpad0"
                                            placeholder="$0"
                                            name="rental_income"
                                            value={rental_income}
                                            readOnly={true}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span>Total Income</span>
                                    <span>
                                        <PriceField
                                            className="form-control lpad0"
                                            placeholder="$0"
                                            name="i_total_income"
                                            value={i_total_income}
                                            readOnly={true}
                                        />
                                    </span>
                                    <span className={is_joint ? '' : 'hide'}>
                                        <PriceField
                                            className="form-control lpad0"
                                            placeholder="$0"
                                            name="p_total_income"
                                            value={p_total_income}
                                            readOnly={true}
                                        />
                                    </span>
                                    <span>
                                        <PriceField
                                            className="form-control text-right rpad0"
                                            placeholder="$0"
                                            name="total_income"
                                            value={total_income}
                                            readOnly={true}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span>Rental Deductions</span>
                                    <span>
                                        <PriceField
                                            className="form-control lpad0"
                                            placeholder="$0"
                                            name="i_rental_deduction"
                                            value={i_rental_deduction}
                                            readOnly={true}
                                        />
                                    </span>
                                    <span className={is_joint ? '' : 'hide'}>
                                        <PriceField
                                            className="form-control lpad0"
                                            placeholder="$0"
                                            name="p_rental_deduction"
                                            value={p_rental_deduction}
                                            readOnly={true}
                                        />
                                    </span>
                                    <span>
                                        <PriceField
                                            className="form-control text-right rpad0"
                                            placeholder="$0"
                                            name="rental_deduction"
                                            value={rental_deduction}
                                            readOnly={true}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span>New Taxable Income</span>
                                    <span>
                                        <PriceField
                                            className="form-control lpad0"
                                            placeholder="$0"
                                            name="i_new_tax_income"
                                            value={i_new_tax_income}
                                            readOnly={true}
                                        />
                                    </span>
                                    <span className={is_joint ? '' : 'hide'}>
                                        <PriceField
                                            className="form-control lpad0"
                                            placeholder="$0"
                                            name="p_new_tax_income"
                                            value={p_new_tax_income}
                                            readOnly={true}
                                        />
                                    </span>
                                    <span>
                                        <PriceField
                                            className="form-control text-right rpad0"
                                            placeholder="$0"
                                            name="new_tax_income"
                                            value={new_tax_income}
                                            readOnly={true}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span>Present Tax</span>
                                    <span>
                                        <PriceField
                                            className="form-control lpad0"
                                            placeholder="$0"
                                            name="i_present_tax"
                                            value={i_present_tax}
                                            readOnly={true}
                                        />
                                    </span>
                                    <span className={is_joint ? '' : 'hide'}>
                                        <PriceField
                                            className="form-control lpad0"
                                            placeholder="$0"
                                            name="p_present_tax"
                                            value={p_present_tax}
                                            readOnly={true}
                                        />
                                    </span>
                                    <span>
                                        <PriceField
                                            className="form-control text-right rpad0"
                                            placeholder="$0"
                                            name="present_tax"
                                            value={present_tax}
                                            readOnly={true}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span>New Tax</span>
                                    <span>
                                        <PriceField
                                            className="form-control lpad0"
                                            placeholder="$0"
                                            name="i_new_tax"
                                            value={i_new_tax}
                                            readOnly={true}
                                        />
                                    </span>
                                    <span className={is_joint ? '' : 'hide'}>
                                        <PriceField
                                            className="form-control lpad0"
                                            placeholder="$0"
                                            name="p_new_tax"
                                            value={p_new_tax}
                                            readOnly={true}
                                        />
                                    </span>
                                    <span>
                                        <PriceField
                                            className="form-control text-right rpad0"
                                            placeholder="$0"
                                            name="new_tax"
                                            value={new_tax}
                                            readOnly={true}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span>Tax Saving</span>
                                    <span>
                                        <PriceField
                                            className="form-control lpad0"
                                            placeholder="$0"
                                            name="i_tax_saving"
                                            value={i_tax_saving}
                                            readOnly={true}
                                        />
                                    </span>
                                    <span className={is_joint ? '' : 'hide'}>
                                        <PriceField
                                            className="form-control lpad0"
                                            placeholder="$0"
                                            name="p_tax_saving"
                                            value={p_tax_saving}
                                            readOnly={true}
                                        />
                                    </span>
                                    <span>
                                        <PriceField
                                            className="form-control text-right rpad0"
                                            placeholder="$0"
                                            name="tax_saving"
                                            value={tax_saving}
                                            readOnly={true}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>


                        <div className="tp_row_btm d-flex justify-content-between">
                            <LeftButton text="Close" onClick={this.props.hideShowDialog2} />

                            <MiddleButton text="Tax Scales" onClick={this.props.hideShowDialog3} />

                            <RightButton text="Save" onClick={this.props.hideShowDialog2} />
                        </div>
                    </Form>
                </div>
            </div>`
        </div>

    }
}