const validator = {
    email: {
        rules: [
            {
                test: /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i,
                message: 'Please Enter Valid Email',
            },
        ],
        errors: [],
        valid: false,
        state: '',
    },
    password: {
        rules: [
            {
                test: (value) => {
                    return value.length >= 5;
                },
                message: 'Password can not be < 5 characters',
            },
        ],
        errors: [],
        valid: false,
        state: ''
    },
    first_name: {
        rules: [
            {
                test: (value) => {
                    return value.length >= 2;
                },
                message: 'First name can not be < 2 characters',
            },
        ],
        errors: [],
        valid: false,
        state: '',
    },
    last_name: {
        rules: [
            {
                test: (value) => {
                    return value.length >= 2;
                },
                message: 'Last name can not be < 2 characters',
            },
        ],
        errors: [],
        valid: false,
        state: '',
    },
    dob: {
        rules: [],
        errors: [],
        valid: false,
        state: '',
    },
    company_name: {
        rules: [],
        errors: [],
        valid: false,
        state: '',
    },
    occupation: {
        rules: [],
        errors: [],
        valid: false,
        state: '',
    },
    phone: {
        rules: [
            {
                test: /^[0-9]+$/i,
                message: 'Alphabets not allowed in contact no',
            },
        ],
        errors: [],
        valid: false,
        state: '',
    },
    expire_at: {
        rules: [],
        errors: [],
        valid: false,
        state: '',
    },
};

export default validator;