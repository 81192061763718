const validator = {
    old_password: {
        rules: [
            {
                test: (value) => {
                    return value.length >= 5;
                },
                message: 'Password can not be < 5 characters',
            },
        ],
        errors: [],
        valid: false,
        state: ''
    },
    new_password: {
        rules: [
            {
                test: (value) => {
                    return value.length >= 5;
                },
                message: 'Password can not be < 5 characters',
            },
        ],
        errors: [],
        valid: false,
        state: ''
    },
    confirm_password: {
        rules: [
            {
                test: (value) => {
                    return value.length >= 5;
                },
                message: 'Password can not be < 5 characters',
            },
        ],
        errors: [],
        valid: false,
        state: ''
    },
};

export default validator;