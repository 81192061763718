import React from 'react';
import {
    Form,
    CustomInput,
} from 'reactstrap';
import RightButton from "components/properlytics/right-button";
import LeftButton from "components/properlytics/left-button";
import {getStateOptions} from "../../../../services/config-helper";
import {addStampDuty, getStampDuty} from "../../../../services/services";
import PriceField from "../../../../components/numbers/PriceField";
import PercentageField from "../../../../components/numbers/PercentageField";

export default class StampDuty extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            state: props.state,
            loading: false,
            stamp_duty: [],
        }
        this.changeStampDuty = this.changeStampDuty.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onCheckBoxClick = this.onCheckBoxClick.bind(this);
        this.onStateChange = this.onStateChange.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    changeStampDuty(key,name,value) {
        let stamp_duty = this.state.stamp_duty;

        if (typeof stamp_duty[key] !== 'undefined') {
            if (name === 'to') {
                value = (value === '') ? '' : parseInt(value);
                if (typeof stamp_duty[key] !== 'undefined') {
                    stamp_duty[key].to = value;
                    if (typeof stamp_duty[key + 1] !== 'undefined') {
                        if (value) {
                            stamp_duty[key + 1].from = value + 1;
                        } else {
                            stamp_duty[key + 1].from = '';
                        }

                        if ((value + 1) > stamp_duty[key + 1].to) {
                            for (let i = (key + 1);i<stamp_duty.length;i++) {

                                if (i === (key+ 1)) {
                                    stamp_duty[i].to = '';
                                } else {
                                    stamp_duty[i].from = '';
                                    stamp_duty[i].to = '';
                                }
                            }
                        }
                    } else {
                        if (stamp_duty[key].to.toString() !== '') {
                            stamp_duty[key + 1] = {
                                from: value + 1,
                                to: '',
                                rate: 0,
                                flat_rate: 0
                            }
                        }
                    }
                }
            }
            if (name === 'rate') {
                value = (value === '') ? 0.00 : value;
                if (typeof stamp_duty[key] !== 'undefined') {
                    stamp_duty[key].rate = value;
                }
            }
            if (name === 'flat_rate') {
                if (typeof stamp_duty[key] !== 'undefined') {
                    stamp_duty[key].flat_rate = (value) ? 1 : 0;
                }
            }
        }

        this.setState({
            stamp_duty: stamp_duty
        })
    }

    getStampDutyFormData(name) {
        let stamp_duty = this.state.stamp_duty;

        let data = [];
        for (let i=0;i<stamp_duty.length;i++) {
            if (name === 'from') {
                data.push(stamp_duty[i].from);
            }
            if (name === 'to') {
                data.push(stamp_duty[i].to);
            }
            if (name === 'rate') {
                data.push(stamp_duty[i].rate);
            }
            if (name === 'flat_rate') {
                data.push(stamp_duty[i].flat_rate);
            }
        }

        return data;
    }

    onInputChange = (key, name, event) => {
        if (!isNaN(event.target.value) || event.target.value === '') {
            this.changeStampDuty(key, name, event.target.value)
        }
    }

    onCheckBoxClick = (key, event) => {
        this.changeStampDuty(key, 'flat_rate', event.target.checked)
    }

    onStateChange (value) {
        this.setState({
            state: value
        })
        this.getStampDuty(value);
    }

    onSave() {
        if (this.props.property_id !== '') {
            this.setState({
                loading: true
            })
            let postData = new FormData();
            postData.append('property_id', this.props.property_id);
            postData.append('state', this.state.state);
            let from = this.getStampDutyFormData('from');
            for (let i = 0; i < from.length; i++) {
                postData.append('from[]', from[i]);
            }
            let to = this.getStampDutyFormData('to');
            for (let i = 0; i < to.length; i++) {
                postData.append('to[]', to[i]);
            }
            let rate = this.getStampDutyFormData('rate');
            for (let i = 0; i < rate.length; i++) {
                postData.append('rate[]', rate[i]);
            }
            let flat_rate = this.getStampDutyFormData('flat_rate');
            for (let i = 0; i < flat_rate.length; i++) {
                postData.append('flat_rate[]', flat_rate[i]);
            }

            addStampDuty(postData).then(res => {
                if (res.success) {
                    this.props.showNotification(res.message, 'success')
                    let state = {
                        value: this.state.state
                    }
                    this.props.onStateChange(state);
                } else {
                    this.props.showNotification(res.message, 'danger')
                }
                this.setState({
                    loading: false
                })
            });
        } else {
            this.props.showNotification('Please first enter property detail', 'danger')
        }
    }

    getStampDuty(state) {
        if (this.props.property_id !== '') {
            let postData = {
                property_id: this.props.property_id,
                state: state
            }

            getStampDuty(postData).then(res => {
                if (res.success) {
                    this.setState({
                        stamp_duty: res.data.stamp_duties,
                    })
                } else {
                    this.props.showNotification(res.message, 'danger')
                    this.props.hideShowDialog();
                }
            });
        }
    }

    componentDidMount() {
        this.getStampDuty(this.state.state);
    }

    componentDidUpdate(props) {
        const { state, show_dialog } = this.props;
        if (props.state !== state) {
            this.setState({
                state: this.props.state
            });
        }

        if (props.show_dialog !== show_dialog) {
            if (this.props.show_dialog) {
                this.getStampDuty(this.props.state);
            }
        }
    }

    render() {
        const {state} = this.state;

        let state_list = getStateOptions().map((item) => {
            return (
                <span
                    onClick={this.onStateChange.bind(this,item.value)}
                    className={(state.toString() === item.value.toString()) ? 'active' : ''}
                >
                {item.label}
                </span>
            );
        }, state);

        return <div className={(this.props.show_dialog) ? "grd_bx popup-wrap flip_bx1 stamp_duty go" : "grd_bx popup-wrap flip_bx1 stamp_duty"}>
            <div className="rect_bx flip_bx1">
                <div className="head_tron d-flex">
                    <span className="flipbtn t_black rotate">
                        <i className="icon-arrow-up-circle"/>
                    </span>
                    <h2 className="head pl-2 t_black">Stamp Duty Calculation</h2>
                </div>

                <div className="inner_bx">
                    <Form>
                        <div className="tp_row d-flex height60">
                            <div className="hd_row w100 d-flex ">
                                <h4 className="sm_tit fx_w">Region</h4>

                                <div className="d-flex w100 loc_tab justify-content-between">
                                    {state_list}
                                </div>
                            </div>
                        </div>

                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span>From</span>
                                    <span>To</span>
                                    <span>Rate</span>
                                    <span>Flat</span>
                                </div>
                            </div>
                        </div>

                        <div>
                            <MapForm
                                onInputChange={this.onInputChange}
                                onCheckBoxClick={this.onCheckBoxClick}
                                stamp_duty={this.state.stamp_duty}
                            />
                        </div>


                        <div className="tp_row_btm d-flex justify-content-between">
                            <LeftButton text="Close" onClick={this.props.hideShowDialog} />

                            <RightButton text="Save" onClick={this.onSave} loading={this.state.loading} />
                        </div>
                    </Form>
                </div>
            </div>
        </div>

    }
}

const MapForm = ({stamp_duty, onInputChange, onCheckBoxClick}) => {
    if(stamp_duty.length > 0){
        return stamp_duty.map( (item, key) => {
            return (
                <div className="tp_row table_row  d-flex  height60">
                    <div className="hd_row w100">
                        <div className="d-flex w100 tab_grid">
                            <span>
                                <PriceField
                                    placeholder="Enter From"
                                    name="from[]"
                                    value={item.from}
                                    readOnly={true}
                                />
                            </span>
                            <span>
                                <PriceField
                                    placeholder="Enter To"
                                    name="to[]"
                                    value={item.to}
                                    onChange={onInputChange.bind(this, key, 'to')}
                                />
                            </span>
                            <span>
                                <PercentageField
                                    placeholder="Enter Rate"
                                    name="to[]"
                                    value={item.rate}
                                    onChange={onInputChange.bind(this, key, 'rate')}
                                />
                            </span>
                            <span>
                                <Form>
                                    <div className="form-check form-check-inline">
                                        <CustomInput
                                            type="checkbox"
                                            name="flat_rate[]"
                                            id={"flat_rate" + key}
                                            checked={(item.flat_rate)}
                                            onClick={onCheckBoxClick.bind(this, key)}
                                        />
                                    </div>
                                </Form>
                            </span>
                        </div>
                    </div>
                </div>
            )
        })
    }
    return null;
}

