import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'reactstrap';

class Sidebar extends React.Component {

	constructor(props) {
		super(props);
		this.activeRoute.bind(this);

		let is_edit= false;
		let property_id = '';

		if (typeof props.match.params.id !== 'undefined') {
			if (props.match.params.id) {
                property_id = props.match.params.id;
                is_edit = true;
			}
		}

		this.state = {
			property_id: property_id,
			is_edit: is_edit,
		};
	}
	/*--------------------------------------------------------------------------------*/
	/*Verifies if routeName is the one active (in browser input)                      */
	/*--------------------------------------------------------------------------------*/
	activeRoute(routeName) { return this.props.location.pathname.indexOf(routeName) > -1 ? 'selected' : ''; }

	render() {
		return (
			<aside className="left-sidebar" id="sidebarbg" data-sidebarbg={this.props.data.settings[0].sidebarbg} >
				<div className="scroll-sidebar">
					<div className="sidebar-nav">
						{/*--------------------------------------------------------------------------------*/}
						{/* Sidebar Menus will go here                                                */}
						{/*--------------------------------------------------------------------------------*/}
						<Nav id="sidebarnav">
							{this.props.routes.map((prop, key) => {
								if (prop.redirect) {
									return null;
								} else {
									if (prop.display) {
										if (prop.property_tab) {
                                            let path = (this.state.is_edit) ? prop.path + '/' + this.state.property_id : prop.path;
                                            return (
                                                <li className={this.activeRoute(prop.path) + (prop.pro ? ' active active-pro' : '') + ' sidebar-item'} key={key}>
                                                    <NavLink to={{
                                                        pathname: path,
                                                        property: this.props.location.property
                                                    }} className="sidebar-link" activeClassName="active">
                                                        <img src={prop.imgUrl} alt="Icon" className="inactive-menu" />
                                                        <img src={prop.imgUrl2} alt="Icon" className="active-menu" />
                                                        <span className="hide-menu">{prop.name}</span>
                                                    </NavLink>
                                                </li>
                                            );
										} else {
                                            return (
                                                <li className={this.activeRoute(prop.path) + (prop.pro ? ' active active-pro' : '') + ' sidebar-item'} key={key}>
                                                    <NavLink to={{
                                                        pathname: prop.path,
                                                    }} className="sidebar-link" activeClassName="active">
                                                        <img src={prop.imgUrl} alt="Icon" className="inactive-menu" />
                                                        <img src={prop.imgUrl2} alt="Icon" className="active-menu" />
                                                        <span className="hide-menu">{prop.name}</span>
                                                    </NavLink>
                                                </li>
                                            );
										}
									} else {
										return null;
									}
								}
							})}
						</Nav>
					</div>
				</div>
			</aside>
		);
	}
}
export default Sidebar;
