import React from 'react';
import {
    Form,
} from 'reactstrap';
import RightButton from "components/properlytics/right-button";
import LeftButton from "components/properlytics/left-button";
import NumberFormat from 'react-number-format';
import config from "config/config";

export default class LoanCosts extends React.Component {

    render() {
        const {
            establishment_fees,
            mortgage_insurance,
            morgagees_fees,
            valuation_fees,
            reg_morgage_1,
            reg_of_title,
            search_fees,
            onInputChange
        } = this.props;

        return <div className={(this.props.show_dialog) ? "grd_bx popup-wrap flip_bx1 loan_cost go" : "grd_bx popup-wrap flip_bx1 stamp_duty"}>
            <div className="rect_bx flip_bx1">
                <div className="head_tron d-flex">
                    <span className="flipbtn t_black rotate">
                        <i className="icon-arrow-up-circle"/>
                    </span>
                    <h2 className="head pl-2 t_black">Loan Costs</h2>
                </div>

                <div className="inner_bx loan_costs">
                    <Form>

                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span>Cost Name</span>
                                    <span>Cost Price</span>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="tp_row table_row  d-flex  height60">
                                <div className="hd_row w100">
                                    <div className="d-flex w100 tab_grid">
                                        <span>
                                            Establishment Fees
                                        </span>
                                        <span>
                                            <NumberFormat
                                                thousandSeparator={true}
                                                prefix={config.price_prefix}
                                                name="establishment_fees"
                                                id="establishment_fees"
                                                className="form-control text-right"
                                                placeholder="$0"
                                                value={(parseInt(establishment_fees) === 0) ? "" : establishment_fees}
                                                maxLength={config.price_max_length}
                                                onChange={onInputChange.bind(this)}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="tp_row table_row  d-flex  height60">
                                <div className="hd_row w100">
                                    <div className="d-flex w100 tab_grid">
                                        <span>
                                            Mortgage Insurance
                                        </span>
                                        <span>
                                            <NumberFormat
                                                thousandSeparator={true}
                                                prefix={config.price_prefix}
                                                name="mortgage_insurance"
                                                id="mortgage_insurance"
                                                className="form-control text-right"
                                                placeholder="$0"
                                                value={(parseInt(mortgage_insurance) === 0) ? "" : mortgage_insurance}
                                                maxLength={config.price_max_length}
                                                onChange={onInputChange.bind(this)}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="tp_row table_row  d-flex  height60">
                                <div className="hd_row w100">
                                    <div className="d-flex w100 tab_grid">
                                        <span>
                                            Mortgagee's Solicitor's Fees
                                        </span>
                                        <span>
                                            <NumberFormat
                                                thousandSeparator={true}
                                                prefix={config.price_prefix}
                                                name="morgagees_fees"
                                                id="morgagees_fees"
                                                className="form-control text-right"
                                                placeholder="Enter Amount"
                                                value={morgagees_fees}
                                                maxLength={config.price_max_length}
                                                onChange={onInputChange.bind(this)}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="tp_row table_row  d-flex  height60">
                                <div className="hd_row w100">
                                    <div className="d-flex w100 tab_grid">
                                        <span>
                                            Valuation Fees
                                        </span>
                                        <span>
                                            <NumberFormat
                                                thousandSeparator={true}
                                                prefix={config.price_prefix}
                                                name="valuation_fees"
                                                id="valuation_fees"
                                                className="form-control text-right"
                                                placeholder="Enter Amount"
                                                value={valuation_fees}
                                                maxLength={config.price_max_length}
                                                onChange={onInputChange.bind(this)}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="tp_row table_row  d-flex  height60">
                                <div className="hd_row w100">
                                    <div className="d-flex w100 tab_grid">
                                        <span>
                                            Registration of Mortgage(s)
                                        </span>
                                        <span>
                                            <NumberFormat
                                                thousandSeparator={true}
                                                prefix={config.price_prefix}
                                                name="reg_morgage_1"
                                                id="reg_morgage_1"
                                                className="form-control text-right"
                                                placeholder="Enter Amount"
                                                value={reg_morgage_1}
                                                maxLength={config.price_max_length}
                                                onChange={onInputChange.bind(this)}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="tp_row table_row  d-flex  height60">
                                <div className="hd_row w100">
                                    <div className="d-flex w100 tab_grid">
                                        <span>
                                            Registration of Title
                                        </span>
                                        <span>
                                            <NumberFormat
                                                thousandSeparator={true}
                                                prefix={config.price_prefix}
                                                name="reg_of_title"
                                                id="reg_of_title"
                                                className="form-control text-right"
                                                placeholder="Enter Amount"
                                                value={reg_of_title}
                                                maxLength={config.price_max_length}
                                                onChange={onInputChange.bind(this)}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="tp_row table_row  d-flex  height60">
                                <div className="hd_row w100">
                                    <div className="d-flex w100 tab_grid">
                                        <span>
                                            Search Fees
                                        </span>
                                        <span>
                                            <NumberFormat
                                                thousandSeparator={true}
                                                prefix={config.price_prefix}
                                                name="search_fees"
                                                id="search_fees"
                                                className="form-control text-right"
                                                placeholder="Enter Amount"
                                                value={search_fees}
                                                maxLength={config.price_max_length}
                                                onChange={onInputChange.bind(this)}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="tp_row_btm d-flex justify-content-between">
                            <LeftButton text="Close" onClick={this.props.hideShowDialog} />

                            <RightButton text="Save" onClick={this.props.hideShowDialog} />
                        </div>
                    </Form>
                </div>
            </div>`
        </div>

    }
}