import React from 'react'
import {Redirect} from 'react-router';
import {isLogin, subscriptionExpired} from "../services/helper";
import config from 'config/config';

export default function (ComposedComponent) {

    class SubscriptionMiddleware extends React.Component {

        state = {
            authenticate: true,
            subscription_expired: false,
        }

        // Push to login route if not authenticated on mount
        UNSAFE_componentWillMount() {
            if (!isLogin()) {
                // Use your router to redirect them to login page
                this.setState({
                    authenticate: false
                })
            } else {
                if (subscriptionExpired()) {
                    this.setState({
                        subscription_expired: true
                    })
                }
            }
        }

        // Push to login route if not authenticated on update
        UNSAFE_componentWillUpdate(nextProps) {
            if (!isLogin()) {
                // Use your router to redirect them to login page
                this.setState({
                    authenticate: false
                })
            } else {
                if (subscriptionExpired()) {
                    this.setState({
                        subscription_expired: true
                    })
                }
            }
        }

        // Otherwise render the original component
        render() {
            if (this.state.authenticate === true) {
                if (this.state.subscription_expired === true) {
                    return <Redirect to={config.site_url.subscription}/>;
                    //return <ComposedComponent {...this.props}/> /*for test*/
                } else {
                    return <ComposedComponent {...this.props}/>
                }
            } else {
                return <Redirect to={config.site_url.login}/>;
            }
        }

    }

    return SubscriptionMiddleware

}