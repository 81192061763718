import React from 'react';
import {
    Button,
} from 'reactstrap';

export default class MiddleButton extends React.Component {

	render() {
        let {text, onClick} = this.props;

        if (text === '') {
            return '';
        }

        return (
            <div className="center_bx">
                <Button className="middle_btn" size="lg" type="button" onClick={onClick} block >
                    <div>
                        {text}
                    </div>
                </Button>
            </div>
        );
	}
}