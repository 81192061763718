import React from 'react';
import {getMyProfile, getNotificationObject} from "../../services/helper";
import {getAdminDashboard} from "../../services/services";
import {Redirect} from 'react-router';
import config from 'config/config';
import DashboardTabs from "../../components/admin/dashboard-tabs";
import UserTable from "../../components/admin/user-table";
import ReactNotification from "react-notifications-component";

export default class AdminDashboard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            is_super_admin: true,
            dashboard: {
                users: 0,
                last_days_users: 0,
                clients: 0,
                last_days_clients: 0,
                properties: 0,
                last_days_properties: 0,
                payments: 0
            },
            users: [],
            paginator: {},
            loading: false,

            filter: {
                search_by: '',
                search_text: '',
            },

            user_id: '',
            redirect_to_user_edit: false
        };

        this.setUsers = this.setUsers.bind(this);
        this.getDashboard = this.getDashboard.bind(this);
        this.changeFilter = this.changeFilter.bind(this);

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();

    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    getDashboard(page, filter) {
        if (page !== '') {
            this.setState({
                loading: true
            });

            let search_by = '';
            let search_text = '';
            if (typeof filter !== 'undefined' && typeof filter.search_by !== 'undefined') {
                if (filter.search_by !== '' && filter.search_text !== '') {
                    search_by = (filter.search_by !== '') ? filter.search_by : '';
                    search_text = (filter.search_text !== '') ? filter.search_text : '';
                }
            } else {
                if (this.state.filter.search_by !== '' && this.state.filter.search_text !== '') {
                    search_by = (this.state.filter.search_by !== '') ? this.state.filter.search_by : '';
                    search_text = (this.state.filter.search_text !== '') ? this.state.filter.search_text : '';
                }
            }

            let postData = {
                page: page,
                search_by: search_by,
                search_text: search_text,
            }

            getAdminDashboard(postData).then(res => {
                if (res.success) {
                    this.setUsers(res);
                } else {
                    if (res.error) {
                        if (typeof res.error !== 'undefined' && typeof res.error.admin_code !== 'undefined' &&
                            res.error.admin_code === 401
                        ) {
                            this.setState({
                                is_super_admin: false,
                            })
                        } else {
                            this.showNotification(res.message, 'danger')
                        }
                    } else {
                        this.showNotification(res.message, 'danger')
                    }
                }
                this.setState({
                    loading: false
                });
            });
        } else {
            this.setState({
                loading: false
            });
        }
    }

    changeFilter(filter) {
        this.getDashboard(1, filter);

        this.setState({
            filter: filter
        })
    }

    componentDidMount() {
        let profile = getMyProfile();
        if (profile) {
            this.setState({
                is_super_admin: (profile.is_super_admin),
            });
        }

        this.getDashboard(1);
    }

    setUsers(res) {
        this.setState({
            dashboard: res.data.dashboard,
            users: res.data.users_detail.users,
            paginator: res.data.users_detail.pagination
        })
    }

    render() {

        if (!this.state.is_super_admin) {
            return <Redirect to={config.site_url.dashboard}/>;
        }

        return (<div className="page-content no-gutters  container-fluid">

                <ReactNotification ref={this.notificationDOMRef} />

                <div className="client_wrap superadmin">

                    <DashboardTabs
                        dashboard={this.state.dashboard}
                        changeFilter={this.changeFilter}
                    />

                    <UserTable
                        users={this.state.users}
                        paginator={this.state.paginator}
                        loading={this.state.loading}
                        setUsers={this.setUsers}
                        getDashboard={this.getDashboard}
                        showNotification={this.showNotification}
                    />
                </div>
            </div>
        );
    }
}