import React from 'react';
import NumberFormat from 'react-number-format';
import config from 'config/config';
import {convertToNumber} from "../../services/helper";

export default class PercentageField extends React.Component {

    constructor(props) {
        super(props);
        this.onValueChange = this.onValueChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    onValueChange(values) {
        let event = {
            target: {
                name: this.props.name,
                value: values.value,
            }
        }
        this.props.onChange(event)
    }

    onBlur(event) {
        let events = {
            target: {
                name: this.props.name,
                value: convertToNumber(event.target.value),
            }
        }
        this.props.onBlur(events)
    }

    render() {
        let name = (this.props.name) || '';
        let placeholder = (this.props.placeholder) || '';
        let value = (this.props.value) || '';
        let className = (this.props.className) || '';
        let readOnly = (this.props.readOnly) || false;

        if (name === '') {
            return '';
        }

        if (typeof this.props.onChange !== 'undefined') {
            if (typeof this.props.onBlur !== 'undefined') {
                return (
                    <NumberFormat
                        thousandSeparator={true}
                        suffix={config.percentage_suffix}
                        name={name}
                        id={name}
                        className={className || "form-control"}
                        placeholder={placeholder}
                        value={value}
                        maxLength={config.percentage_max_length}
                        onValueChange={(values) => this.onValueChange(values)}
                        onBlur={this.onBlur}
                        readOnly={readOnly}
                    />
                );
            } else {
                return (
                    <NumberFormat
                        thousandSeparator={true}
                        suffix={config.percentage_suffix}
                        name={name}
                        id={name}
                        className={className || "form-control"}
                        placeholder={placeholder}
                        value={value}
                        maxLength={config.percentage_max_length}
                        onValueChange={(values) => this.onValueChange(values)}
                        readOnly={readOnly}
                    />
                );
            }
        } else {
            return (
                <NumberFormat
                    thousandSeparator={true}
                    suffix={config.percentage_suffix}
                    name={name}
                    id={name}
                    className={className || "form-control"}
                    placeholder={placeholder}
                    value={value}
                    readOnly={readOnly}
                />

            );
        }
    }
}