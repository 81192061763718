import React from 'react';
import {
    Table
} from 'reactstrap';
import config from "config/config";

export default class SubUserTable extends React.Component {

    render() {
        let {sub_users, user_id, display_users} = this.props;

        if (!display_users) {
            return '';
        }

        let users_data = sub_users.map((user, key) => {
            return (
                <tr className="strip">

                    <td>
                        <span className="d-block"> {user.first_name}</span>

                    </td>

                    <td>
                        <span>{user.last_name}</span>
                    </td>

                    <td>
                        <span>{user.email}</span>
                    </td>

                    <td className={(user_id.toString() === user.user_id.toString()) ? 'hide' : '' }>
                        <div className="act_btn">
                            <span className={user.is_active ? "clickme active" : "clickme"}>
                                <i className="ti-user"/>
                            </span>
                            <a href={config.hash + config.site_url.sub_user_edit + '/' + user.user_id} className="clickme">
                                <i className="ti-settings"/>
                            </a>
                        </div>
                    </td>
                </tr>
            );
        }, user_id);

        return (
            <div>
                <div className="head_tron d-flex border-bottom-0 justify-content-between">
                    <h2 className="head t_green">Users</h2>
                    <h2 className="head ">
                        <a href={config.hash + config.site_url.sub_user_add} className="link t_gray"> Add New User</a>
                    </h2>
                </div>

                <div className="table-wrap">
                    <Table responsive  className="table mb-0 custmize">
                        <thead>
                        <tr>
                            <th>First name</th>
                            <th className="">Surname</th>
                            <th className="mb_w">Email</th>
                            <th>Edit</th>
                        </tr>
                        </thead>
                        <tbody>
                        {users_data}
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    }
}



