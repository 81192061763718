import React from 'react';
import ReactDOM from 'react-dom';
//import { createBrowserHistory } from 'history';
import indexRoutes from './routes/index.jsx';
import {Route, Switch, BrowserRouter, HashRouter} from 'react-router-dom';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';


import './assets/scss/style.css';
import './assets/scss/all/prpty.scss';

//const hist = createBrowserHistory();

ReactDOM.render(
    <div>
        <BrowserRouter>
            <Switch>
                <Route exact path="/linkedin" component={LinkedInPopUp} />
            </Switch>
        </BrowserRouter>

        <HashRouter>
            <Switch>
                {indexRoutes.map((prop, key) => {
                    return <Route path={prop.path} key={key} component={prop.component}/>;
                })}
            </Switch>
        </HashRouter>
    </div>
    , document.getElementById('root')
);
