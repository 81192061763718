import React from 'react';
import {
    Button,
} from 'reactstrap';
import {
    projectionYearOptions,
    getSelectedYearOption,
    getYear,
    setYear
} from 'services/config-helper';
import Select, { components } from "react-select";
import {exportReport} from "../../../services/services";
import {downloadReport, getOverviewMoney, getWhoPayMoney, getYearWiseValue} from "../../../services/helper";

const groupStyles1 = {
    borderRadius: '0px',
    background: '#ffffff',
    padding: "0"
};

const Group1 = (props) => (
    <div style={groupStyles1}>
        <components.Group {...props} />
    </div>
);

const style1 = {
    control: (base, state) => ({
        ...base,
        background: state.isFocused ? 0 : 0,
        padding: "0 !important",
        margin: "0 !important",
        border: "0 !important",
        boxShadow: "0 !important",
        "&:hover": {
            border: "0 !important"
        }
    })
};

export default class Projection extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            year: getYear(),
            loading: false,
        }

        this.onYearChange = this.onYearChange.bind(this);
        this.generateReport = this.generateReport.bind(this);
    }

    onYearChange (selected) {
        this.setState({
            year: selected.value,
        });
        setYear(selected.value);
    }

    generateReport() {

        if (this.props.property_id) {
            this.setState({
                loading: true
            })
            let postData = {
                property_id: this.props.property_id
            };

            exportReport(postData).then(res => {
                if (res.success) {
                    downloadReport(res.data);
                } else {
                    this.props.showNotification(res.message, 'danger');
                }
                this.setState({
                    loading: false
                })
            });
        } else {
            this.props.showNotification('Please add property detail first, then you can generate report.', 'danger')
        }
    }

    render() {

        const year = this.state.year;

        const { overview } = this.props;

        const isCompleted = (parseInt(this.props.progress) === 100) ? true : false;

        if (typeof overview.cost_income_per_week !== 'undefined') {
            return (
                <div className="prpty_projection blocks  ui-sortable">
                    <div className="block">
                        <div className="plisting_form rect_bx flip_bx">
                            <div className="head_tron d-flex">
                                <h2 className="head  fx_w t_green">Projections</h2>

                                <div className="d-flex tab_grid">
                                    <span>Present</span>
                                    <span>YR1</span>
                                    <span>YR2</span>
                                    <div className="crita">
                                        <Select
                                            defaultValue={getSelectedYearOption()}
                                            options={projectionYearOptions}
                                            components={{ Group1 }}
                                            styles={style1}
                                            value={getSelectedYearOption(this.state.year)}
                                            onChange={this.onYearChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="inner_bx">
                                <div className="tp_row">
                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Property Value</h4>

                                        <div className="d-flex tab_grid">
                                            <span>{getOverviewMoney(overview.property_value.yr0, 0)}</span>
                                            <span>{getOverviewMoney(overview.property_value.yr1, 0)}</span>
                                            <span>{getOverviewMoney(overview.property_value.yr2, 0)}</span>
                                            <span>{getOverviewMoney(getYearWiseValue(overview.property_value, year), 0)}</span>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Purchase Costs</h4>

                                        <div className="d-flex tab_grid">
                                            <span>{getOverviewMoney(overview.purchase_costs, 0)}</span>
                                            <span/>
                                            <span/>
                                            <span/>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Investments</h4>

                                        <div className="d-flex tab_grid">
                                            <span>{getOverviewMoney(overview.investments, 0)}</span>
                                            <span/>
                                            <span/>
                                            <span/>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Loan Amount</h4>

                                        <div className="d-flex tab_grid">
                                            <span>{getOverviewMoney(overview.loan_amount.yr0, 0)}</span>
                                            <span>{getOverviewMoney(overview.loan_amount.yr1, 0)}</span>
                                            <span>{getOverviewMoney(overview.loan_amount.yr2, 0)}</span>
                                            <span>{getOverviewMoney(getYearWiseValue(overview.loan_amount, year), 0)}</span>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Equity</h4>

                                        <div className="d-flex tab_grid">
                                            <span>{getOverviewMoney(overview.equity.yr0, 0)}</span>
                                            <span>{getOverviewMoney(overview.equity.yr1, 0)}</span>
                                            <span>{getOverviewMoney(overview.equity.yr2, 0)}</span>
                                            <span>{getOverviewMoney(getYearWiseValue(overview.equity, year), 0)}</span>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Capital Growth Rate</h4>

                                        <div className="d-flex tab_grid">
                                            <span>{overview.capital_growth_rate.yr1}%</span>
                                            <span/>
                                            <span/>
                                            <span/>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Inflation Rate (CPI)</h4>

                                        <div className="d-flex tab_grid">
                                            <span>{overview.inflation_rate.yr1}%</span>
                                            <span/>
                                            <span/>
                                            <span/>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Gross Rent ({overview.rent_type})</h4>

                                        <div className="d-flex tab_grid">
                                            <span>{getOverviewMoney(overview.gross_rent_per_week.yr0, 0)}</span>
                                            <span>{getOverviewMoney(overview.gross_rent_per_week.yr1, 0)}</span>
                                            <span>{getOverviewMoney(overview.gross_rent_per_week.yr2, 0)}</span>
                                            <span>{getOverviewMoney(getYearWiseValue(overview.gross_rent_per_week, year), 0)}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="tp_row">
                                    <div className="hd_row d-flex">
                                        <h4 className="t_black f14 mbtm0 t_upper pregu">Cash-Deductions</h4>
                                    </div>
                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Interest ({overview.loan_type})</h4>

                                        <div className="d-flex tab_grid">
                                            <span>{overview.interest_rate}%</span>
                                            <span>{getOverviewMoney(overview.interest.yr1, 0)}</span>
                                            <span>{getOverviewMoney(overview.interest.yr2, 0)}</span>
                                            <span>{getOverviewMoney(getYearWiseValue(overview.interest, year), 0)}</span>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Rental Expenses</h4>

                                        <div className="d-flex tab_grid">
                                            <span>{overview.rental_expenses.yr0}%</span>
                                            <span>{getOverviewMoney(overview.rental_expenses.yr1, 0)}</span>
                                            <span>{getOverviewMoney(overview.rental_expenses.yr2, 0)}</span>
                                            <span>{getOverviewMoney(getYearWiseValue(overview.rental_expenses, year), 0)}</span>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Pre-tax Cashflow</h4>

                                        <div className="d-flex tab_grid">
                                            <span/>
                                            {/*<span>{getOverviewMoney(overview.pre_tax_cash_flow.yr0, 0)}</span>*/}
                                            <span>{getOverviewMoney(overview.pre_tax_cash_flow.yr1, 0)}</span>
                                            <span>{getOverviewMoney(overview.pre_tax_cash_flow.yr2, 0)}</span>
                                            <span>{getOverviewMoney(getYearWiseValue(overview.pre_tax_cash_flow, year), 0)}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="tp_row">
                                    <div className="hd_row d-flex">
                                        <h4 className="t_black f14 mbtm0 t_upper pregu">Non-cash-deductions</h4>
                                    </div>
                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Depreciation of Building</h4>

                                        <div className="d-flex tab_grid">
                                            <span>{overview.depreciation_of_building.yr0}%</span>
                                            <span>{getOverviewMoney(overview.depreciation_of_building.yr1, 0)}</span>
                                            <span>{getOverviewMoney(overview.depreciation_of_building.yr2, 0)}</span>
                                            <span>{getOverviewMoney(getYearWiseValue(overview.depreciation_of_building, year), 0)}</span>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Depreciation of Fittings</h4>

                                        <div className="d-flex tab_grid">
                                            <span>{getOverviewMoney(overview.depreciation_of_fittings.yr0, 0)}</span>
                                            <span>{getOverviewMoney(overview.depreciation_of_fittings.yr1, 0)}</span>
                                            <span>{getOverviewMoney(overview.depreciation_of_fittings.yr2, 0)}</span>
                                            <span>{getOverviewMoney(getYearWiseValue(overview.depreciation_of_fittings, year), 0)}</span>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Loan Costs</h4>

                                        <div className="d-flex tab_grid">
                                            <span>{getOverviewMoney(overview.loan_cost.yr0, 0)}</span>
                                            <span>{getOverviewMoney(overview.loan_cost.yr1, 0)}</span>
                                            <span>{getOverviewMoney(overview.loan_cost.yr2, 0)}</span>
                                            <span>{getOverviewMoney(getYearWiseValue(overview.loan_cost, year), 0)}</span>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Total Deductions</h4>

                                        <div className="d-flex tab_grid">
                                            <span/>
                                            <span>{getOverviewMoney(overview.total_deduction.yr1, 0)}</span>
                                            <span>{getOverviewMoney(overview.total_deduction.yr2, 0)}</span>
                                            <span>{getOverviewMoney(getYearWiseValue(overview.total_deduction, year), 0)}</span>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Tax Credit ({overview.tax_credit.type})</h4>

                                        <div className="d-flex tab_grid">
                                            <span>{getOverviewMoney(overview.tax_credit.yr0, 0)}</span>
                                            <span>{getOverviewMoney(overview.tax_credit.yr1, 0)}</span>
                                            <span>{getOverviewMoney(overview.tax_credit.yr2, 0)}</span>
                                            <span>{getOverviewMoney(getYearWiseValue(overview.tax_credit, year), 0)}</span>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">After-tax Cashflow</h4>

                                        <div className="d-flex tab_grid">
                                            <span />
                                            {/*<span>{getOverviewMoney(overview.after_tax_cash_flow.yr0, 0)}</span>*/}
                                            <span>{getOverviewMoney(overview.after_tax_cash_flow.yr1, 0)}</span>
                                            <span>{getOverviewMoney(overview.after_tax_cash_flow.yr2, 0)}</span>
                                            <span>{getOverviewMoney(getYearWiseValue(overview.after_tax_cash_flow, year), 0)}</span>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Cost/Income (PW)</h4>

                                        <div className="d-flex tab_grid">
                                            <span/>
                                            <span className={(overview.cost_income_per_week.yr1 < 0) ? 't_pink' : 't_green'}>
                                                {getWhoPayMoney(overview.cost_income_per_week.yr1, 0, '.', ',', false)}
                                            </span>
                                            <span className={(overview.cost_income_per_week.yr2 < 0) ? 't_pink' : 't_green'}>
                                                {getWhoPayMoney(overview.cost_income_per_week.yr2, 0, '.', ',', false)}
                                            </span>
                                            <span className={(getYearWiseValue(overview.cost_income_per_week, year) < 0) ? 't_pink' : 't_green'}>
                                                {getWhoPayMoney(getYearWiseValue(overview.cost_income_per_week, year), 0)}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="tp_row_btm d-flex">
                                    <div className="center_bx">
                                        <Button className={isCompleted ? "middle_btn active" : "middle_btn"} size="lg" type="button" onClick={this.generateReport} disabled={this.state.loading} block >
                                            <div>
                                                Generate Report
                                            </div>
                                            <div className={(this.state.loading) ? 'btn-loading left right' : 'hide'}>
                                                <div className="btn-loader" />
                                            </div>
                                        </Button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            //at the time of the add property
            return (
                <div className="prpty_projection blocks  ui-sortable">
                    <div className="block">
                        <div className="plisting_form rect_bx flip_bx">
                            <div className="head_tron d-flex">
                                <h2 className="head  fx_w t_green">Projections</h2>

                                <div className="d-flex tab_grid">
                                    <span>Present</span>
                                    <span>YR1</span>
                                    <span>YR2</span>
                                    <div className="crita">
                                        <Select
                                            defaultValue={getSelectedYearOption()}
                                            options={projectionYearOptions}
                                            components={{ Group1 }}
                                            styles={style1}
                                            value={getSelectedYearOption(this.state.year)}
                                            onChange={this.onYearChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="inner_bx">
                                <div className="tp_row">
                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Property Value</h4>

                                        <div className="d-flex tab_grid">
                                            <span>$0</span>
                                            <span>$0</span>
                                            <span>$0</span>
                                            <span>$0</span>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Purchase Costs</h4>

                                        <div className="d-flex tab_grid">
                                            <span>$0</span>
                                            <span/>
                                            <span/>
                                            <span/>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Investments</h4>

                                        <div className="d-flex tab_grid">
                                            <span>$0</span>
                                            <span/>
                                            <span/>
                                            <span/>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Loan Amount</h4>

                                        <div className="d-flex tab_grid">
                                            <span>$0</span>
                                            <span>$0</span>
                                            <span>$0</span>
                                            <span>$0</span>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Equity</h4>

                                        <div className="d-flex tab_grid">
                                            <span>$0</span>
                                            <span>$0</span>
                                            <span>$0</span>
                                            <span>$0</span>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Capital Growth Rate</h4>

                                        <div className="d-flex tab_grid">
                                            <span>0%</span>
                                            <span/>
                                            <span/>
                                            <span/>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Inflation Rate (CPI)</h4>

                                        <div className="d-flex tab_grid">
                                            <span>0%</span>
                                            <span/>
                                            <span/>
                                            <span/>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Gross Rent (PW)</h4>

                                        <div className="d-flex tab_grid">
                                            <span>$0</span>
                                            <span>$0</span>
                                            <span>$0</span>
                                            <span>$0</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="tp_row">
                                    <div className="hd_row d-flex">
                                        <h4 className="t_black f14 mbtm0 t_upper pregu">Cash-Deductions</h4>
                                    </div>
                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Interest (split)</h4>

                                        <div className="d-flex tab_grid">
                                            <span>0%</span>
                                            <span>$0</span>
                                            <span>$0</span>
                                            <span>$0</span>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Rental Expenses</h4>

                                        <div className="d-flex tab_grid">
                                            <span>0%</span>
                                            <span>$0</span>
                                            <span>$0</span>
                                            <span>$0</span>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Pre-tax Cashflow</h4>

                                        <div className="d-flex tab_grid">
                                            <span />
                                            <span>$0</span>
                                            <span>$0</span>
                                            <span>$0</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="tp_row">
                                    <div className="hd_row d-flex">
                                        <h4 className="t_black f14 mbtm0 t_upper pregu">Non-cash-deductions</h4>
                                    </div>
                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Depreciation of Building</h4>

                                        <div className="d-flex tab_grid">
                                            <span>0%</span>
                                            <span>$0</span>
                                            <span>$0</span>
                                            <span>$0</span>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Depreciation of Fittings</h4>

                                        <div className="d-flex tab_grid">
                                            <span>$0</span>
                                            <span>$0</span>
                                            <span>$0</span>
                                            <span>$0</span>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Loan Costs</h4>

                                        <div className="d-flex tab_grid">
                                            <span>$0</span>
                                            <span>$0</span>
                                            <span>$0</span>
                                            <span>$0</span>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Total Deductions</h4>

                                        <div className="d-flex tab_grid">
                                            <span/>
                                            <span>$0</span>
                                            <span>$0</span>
                                            <span>$0</span>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Tax Credit (Joint)</h4>

                                        <div className="d-flex tab_grid">
                                            <span>$0</span>
                                            <span>$0</span>
                                            <span>$0</span>
                                            <span>$0</span>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">After-tax Cashflow</h4>

                                        <div className="d-flex tab_grid">
                                            <span />
                                            <span>$0</span>
                                            <span>$0</span>
                                            <span>$0</span>
                                        </div>
                                    </div>

                                    <div className="hd_row d-flex">
                                        <h4 className="sm_tit fx_w">Cost/Income (PW)</h4>

                                        <div className="d-flex tab_grid">
                                            <span/>
                                            <span className="t_green">$0</span>
                                            <span className="t_green">$0</span>
                                            <span className="t_green">$0</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="tp_row_btm d-flex">
                                    <div className="center_bx">
                                        <Button className={isCompleted ? "middle_btn active" : "middle_btn"} size="lg" type="button" onClick={this.generateReport} disabled={this.state.loading} block >
                                            <div>
                                                Generate Report
                                            </div>
                                            <div className={(this.state.loading) ? 'btn-loading left right' : 'hide'}>
                                                <div className="btn-loader" />
                                            </div>
                                        </Button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}
