import Loginlayout from '../layouts/loginlayout.jsx';
import AuthMiddleware from 'middleware/auth';
import GuestMiddleware from 'middleware/guest';
import SuperAdminMiddleware from 'middleware/super-admin';
import SubscriptionMiddleware from 'middleware/subscription';
import config from 'config/config';
import Innerlayout from "../layouts/innerlayout";
import Innerlayout1 from "../layouts/innerlayout1";
import Fulllayout from "../layouts/fulllayout";

var indexRoutes = [
    { path: config.site_url.login, name: "Login", component: GuestMiddleware(Loginlayout) },
    { path: config.site_url.register, name: "Register", component: GuestMiddleware(Loginlayout) },
    { path: config.site_url.businessTrial, name: "BusinessTrial", component: GuestMiddleware(Loginlayout) },
    { path: config.site_url.personalTrial, name: "PersonalTrial", component: GuestMiddleware(Loginlayout) },
    { path: config.site_url.setup_profile, name: "SetupProfile", component: GuestMiddleware(Loginlayout) },
    { path: config.site_url.forgot_password, name: "ForgotPassword", component: GuestMiddleware(Loginlayout) },
    { path: config.site_url.reset_password + '/:token', name: "ResetPassword", component: GuestMiddleware(Loginlayout) },
    { path: config.site_url.thank_you, name: "ThankYou", component: GuestMiddleware(Loginlayout) },


    { path: config.site_url.account, name: "Account", component: AuthMiddleware(Innerlayout1) },
    { path: config.site_url.change_password, name: "ChangePassword", component: AuthMiddleware(Innerlayout1) },

    /*Subscription Start*/
    { path: config.site_url.subscription_custom, name: "SubscriptionCustom", component: AuthMiddleware(Innerlayout1) },
    { path: config.site_url.subscription_purchase, name: "SubscriptionPurchase", component: AuthMiddleware(Innerlayout1) },
    { path: config.site_url.subscription_manage, name: "SubscriptionManage", component: AuthMiddleware(Innerlayout1) },
    { path: config.site_url.subscription, name: "Subscription", component: AuthMiddleware(Innerlayout1) },
    { path: config.site_url.sub_user_add, name: "SubUserAdd", component: AuthMiddleware(Innerlayout1) },
    { path: config.site_url.sub_user_edit, name: "SubUserEdit", component: AuthMiddleware(Innerlayout1) },
    /*Subscription End*/

    /*Admin Start*/
    { path: config.site_url.admin_dashboard, name: "AdminDashboard", component: SuperAdminMiddleware(Innerlayout) },
    { path: config.site_url.admin_add_user, name: "AdminAddUser", component: SuperAdminMiddleware(Innerlayout) },
    { path: config.site_url.admin_edit_user, name: "AdminEditUser", component: SuperAdminMiddleware(Innerlayout) },
    { path: config.site_url.admin_users, name: 'AdminUsers', component: SuperAdminMiddleware(Innerlayout) },
    /*Admin End*/

    /*Dashboard Start*/
    { path: config.site_url.dashboard, name: "BDashboard", component: SubscriptionMiddleware(Innerlayout) },
    { path: config.site_url.add_client, name: "AddClient", component: SubscriptionMiddleware(Innerlayout) },
    { path: config.site_url.edit_client, name: "EditClient", component: SubscriptionMiddleware(Innerlayout) },
    { path: config.site_url.properties, name: "PropertyList", component: SubscriptionMiddleware(Innerlayout) },
    /*Dashboard End*/

    /*Tabs Start*/
    { path: config.site_url.property_detail + '/:id', name: 'Tab1', component: SubscriptionMiddleware(Fulllayout) },
    { path: config.site_url.acquisition_cost + '/:id', name: 'Tab2', component: SubscriptionMiddleware(Fulllayout) },
    { path: config.site_url.funding_structure + '/:id', name: 'Tab3', component: SubscriptionMiddleware(Fulllayout) },
    { path: config.site_url.investment_outcome + '/:id', name: 'Tab4', component: SubscriptionMiddleware(Fulllayout) },
    { path: config.site_url.investment_expenses + '/:id', name: 'Tab5', component: SubscriptionMiddleware(Fulllayout) },
    { path: config.site_url.economic_assumption + '/:id', name: 'Tab6', component: SubscriptionMiddleware(Fulllayout) },
    { path: config.site_url.income + '/:id', name: 'Tab7', component: SubscriptionMiddleware(Fulllayout) },
    { path: config.site_url.deduction + '/:id', name: 'Tab8', component: SubscriptionMiddleware(Fulllayout) },
    { path: config.site_url.cashflow + '/:id', name: 'Tab9', component: SubscriptionMiddleware(Fulllayout) },

    { path: config.site_url.property_detail, name: 'Tab1', component: SubscriptionMiddleware(Fulllayout) },
    { path: config.site_url.acquisition_cost, name: 'Tab2', component: SubscriptionMiddleware(Fulllayout) },
    { path: config.site_url.funding_structure, name: 'Tab3', component: SubscriptionMiddleware(Fulllayout) },
    { path: config.site_url.investment_outcome, name: 'Tab4', component: SubscriptionMiddleware(Fulllayout) },
    { path: config.site_url.investment_expenses, name: 'Tab5', component: SubscriptionMiddleware(Fulllayout) },
    { path: config.site_url.economic_assumption, name: 'Tab6', component: SubscriptionMiddleware(Fulllayout) },
    { path: config.site_url.income, name: 'Tab7', component: SubscriptionMiddleware(Fulllayout) },
    { path: config.site_url.deduction, name: 'Tab8', component: SubscriptionMiddleware(Fulllayout) },
    { path: config.site_url.cashflow, name: 'Tab9', component: SubscriptionMiddleware(Fulllayout) },
    /*Tabs End*/

    { path: '/', name: 'Dashboard', component: AuthMiddleware(Innerlayout) }

];

export default indexRoutes;
