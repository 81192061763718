import React from 'react';
import config from 'config/config';

export default class RecentProperty extends React.Component {

	render() {
        let {property} = this.props;

        if (typeof property.property_id === 'undefined') {
            return (
                <div className="top_part">
                    <h4 className="t_green fnormal pmedium f16">Recent Property</h4>

                    <div className="d-flex top_dtl">
                        <h6 className="pmedium t_black pmedium mbtm0" style={{height: '110px'}}>Not Available</h6>
                    </div>
                </div>
            );
        }

		return (
            <div className="top_part">
                <h4 className="t_green fnormal pmedium f16">Recent Property</h4>

                <div className="d-flex top_dtl">
                    <img src={property.image} className="prpt_img" alt="Property_Image" />

                    <div className="top_mdl">
                        <div className="d-flex">
                            <h6 className="pmedium t_black pmedium mbtm0">{property.client.name || ''}</h6>
                            <p className="prop_add mbtm0">{property.address}</p>
                        </div>

                        <ul className="list-inline mbtm0 pro_faci">
                            <li>
                                Bed  <span className="no">{property.bedrooms}</span>
                            </li>
                            <li>
                                Bath  <span className="no">{property.bathrooms}</span>
                            </li>
                            <li>
                                Car  <span className="no">{property.car_space}</span>
                            </li>
                        </ul>
                    </div>

                    <a href={config.hash + config.site_url.property_detail + '/' + property.property_id}>
                        <i className="icon-arrow-right" />
                    </a>
                </div>
            </div>
		);
	}
}