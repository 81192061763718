import React from 'react';
import {
    Row,
    Col,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    Card,
    CardTitle,
    CardBody,
    InputGroupText
} from 'reactstrap';
import config from 'config/config';
import { Redirect } from 'react-router';
import {getNotificationObject} from "../../services/helper";
import ReactNotification from "react-notifications-component";
import RightButton from "../../components/properlytics/right-button";
import validator from "./reset-validators";
import {checkResetPassword, resetPassword} from "../../services/services";
import LeftLink from "../../components/properlytics/left-link";

export default class ResetPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            confirm_password: '',
            token: '',

            title: '',
            message: '',
            redirect_to_thank_you: false,
            loading: false,
            page_loading: false,
        };

        this.validators = validator;
        this.onInputChange = this.onInputChange.bind(this);
        this.formValidators = this.formValidators.bind(this);
        this.showErrors = this.showErrors.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();
    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    onInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        this.formValidators([event.target.name], event.target.value);
    }

    formValidators(fieldName, value) {
        this.validators[fieldName].errors = [];
        this.validators[fieldName].state = value;
        this.validators[fieldName].valid = true;
        this.validators[fieldName].rules.forEach((rule) => {
            if (rule.test instanceof RegExp) {
                if (!rule.test.test(value)) {
                    this.validators[fieldName].errors.push(rule.message);
                    this.validators[fieldName].valid = false;
                }
            } else if (typeof rule.test === 'function') {
                if (!rule.test(value)) {
                    this.validators[fieldName].errors.push(rule.message);
                    this.validators[fieldName].valid = false;
                }
            }
        });
    }

    validForm() {
        let status = true;
        Object.keys(this.validators).forEach((field) => {
            if (field === 'email' || field === 'password' || field === 'confirm_password') {
                if (!this.validators[field].valid) {
                    status = false;
                }
            }
        });
        return status;
    }

    showErrors() {
        const fields = ['email', 'password', 'confirm_password'];

        let errors = '';
        let return_error = false;
        for (let index = 0; index < fields.length ; index++) {
            const validator = this.validators[fields[index]];

            if (validator && !validator.valid) {
                for (let i =0 ;i<validator.errors.length;i++) {
                    errors += validator.errors[i];
                    break;
                }
            }

            if (return_error) {
                break;
            }
        }
        return errors;
    }

    onSubmit() {
        if (this.validForm()) {
            this.setState({
                loading: true
            })
            let postData = new FormData();
            postData.append('token', this.state.token);
            postData.append('email', this.state.email);
            postData.append('password', this.state.password);
            postData.append('confirm_password', this.state.confirm_password);

            resetPassword(postData).then(res => {
                if (res.success) {
                    this.setState({
                        redirect_to_thank_you: true,
                        title: 'Thank You',
                        message: 'Your password is successfully reset, please try to login now.',
                    })
                } else {
                    this.setState({
                        loading: false
                    })
                    this.showNotification(res.message, 'danger')
                }
            });
        } else {
            let msg = this.showErrors();
            if (msg.toString() === '') {
                this.showNotification('Please enter all required data', 'danger')
            } else {
                this.showNotification(msg, 'danger')
            }
        }
    }

    componentDidMount() {

        this.setState({
            page_loading: true
        })
        let token = '';

        if (typeof this.props.match.params.token !== 'undefined' && this.props.match.params.token) {
            token = this.props.match.params.token;
        }

        let postData = new FormData();
        postData.append('token', token);
        checkResetPassword(postData).then(res => {
            if (res.success) {
                this.setState({
                    token: token,
                })
            } else {
                this.setState({
                    redirect_to_thank_you: true,
                    title: 'Error',
                    message: res.message,
                })
            }
            this.setState({
                page_loading: false
            })
        });
    }

    render() {

        if (this.state.redirect_to_thank_you) {
            return <Redirect
                to={{
                    pathname: config.site_url.thank_you,
                    title: this.state.title,
                    message: this.state.message
                }}
            />;
        }


        return <div className="">
            {/*--------------------------------------------------------------------------------*/}
            {/* Start Notification */}
            {/*--------------------------------------------------------------------------------*/}
            <ReactNotification ref={this.notificationDOMRef} />
            {/*--------------------------------------------------------------------------------*/}
            {/* End Notification */}
            {/*--------------------------------------------------------------------------------*/}

            {/*--------------------------------------------------------------------------------*/}
            {/*Login Cards*/}
            {/*--------------------------------------------------------------------------------*/}
            <div className="auth-wrapper no-block justify-content-center align-items-center">

                <div className={this.state.page_loading ? `page-loader show` : `page-loader hide`} />

                <div className={(this.state.page_loading) ? 'auth-box hide' : 'auth-box'}>
                    <Row>
                        <Col md="12">
                            <CardTitle className="f32 text-center mb-0">
                                Reset Password
                            </CardTitle>
                            <p className="mt-3 txt-gray text-center">Reset password for Properlytics</p>
                        </Col>
                    </Row>

                    <div id="loginform">
                        <Card>
                            <CardBody className="">
                                <Row>
                                    <Col xs="12">
                                        <Form id="signupform">

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Email*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter your email"
                                                    name="email"
                                                    id="email"
                                                    value={this.state.email}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        New Password*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="password"
                                                    placeholder="Enter new password"
                                                    name="password"
                                                    id="password"
                                                    value={this.state.password}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Confirm Password*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="password"
                                                    placeholder="Enter confirm password"
                                                    name="confirm_password"
                                                    id="confirm_password"
                                                    value={this.state.confirm_password}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <div className="d-flex no-block align-items-center auth-footer reset-bx">
                                                <LeftLink text="Go to Login" url={config.site_url.login}/>

                                                <RightButton text="Reset Password" onClick={this.onSubmit} loading={this.state.loading} />
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </div>;
    }
}

