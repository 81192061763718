import React from 'react';
import {
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import config from 'config/config';

export default class TopBarLinks extends React.Component {
	render() {
        return (
            <Nav className="float-left" navbar>
                <NavItem>
                    <NavLink href={config.hash + config.site_url.dashboard} className="">
                        Dashboard
                    </NavLink>
                </NavItem>
            </Nav>
        );
	}
}