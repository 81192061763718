import React from 'react';
import {
    Row,
    Col,
    Card,
    CardTitle,
    CardBody,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Form,
} from 'reactstrap';
import {addUserFromAdmin, editUserFromAdmin, getUserFromAdmin} from "../../services/services";
import {getMyProfile, getNotificationObject} from "../../services/helper";
import ReactNotification from "react-notifications-component";
import camera from '../../assets/images/camera.png';
import validator from "./user-validators";
import {Redirect} from 'react-router';
import config from 'config/config';
import moment from 'moment';
import RightButton from "../../components/properlytics/right-button";
import LeftLink from "../../components/properlytics/left-link";
import DOBField from "../../components/numbers/DOBField";

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

class AdminUserForm extends React.Component {
    constructor(props) {
        super(props);

        this.validators = validator;
        this.onFileChange = this.onFileChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.formValidators = this.formValidators.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.openUpload = this.openUpload.bind(this);
        this.showErrors = this.showErrors.bind(this);
        this.handleSwitch = this.handleSwitch.bind(this);

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();

        this.state = {
            is_edit: false,
            edit_user_id: '',
            profile_pic: '',
            img_display: false,
            profile_pic_url: '',
            account_type: 1,
            email: '',
            password: '',
            company_name: '',
            first_name: '',
            last_name: '',
            dob: '',
            occupation: '',
            phone: '',
            expire_at: '',
            display_expire_at: true,
            loading: false,
            page_loading: false,

            redirect: false,
            is_super_admin: true,
            redirect_to_add: false,
        }
    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    onFileChange(event) {

        getBase64(event.target.files[0], imageUrl => this.setState({
            profile_pic_url: imageUrl,
        }));

        this.setState({
            [event.target.name]: event.target.files[0],
            img_display: true,
        });
    }

    onInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        this.formValidators([event.target.name], event.target.value);
    }

    formValidators(fieldName, value) {
        this.validators[fieldName].errors = [];
        this.validators[fieldName].state = value;
        this.validators[fieldName].valid = true;
        this.validators[fieldName].rules.forEach((rule) => {
            if (rule.test instanceof RegExp) {
                if (!rule.test.test(value)) {
                    this.validators[fieldName].errors.push(rule.message);
                    this.validators[fieldName].valid = false;
                }
            } else if (typeof rule.test === 'function') {
                if (!rule.test(value)) {
                    this.validators[fieldName].errors.push(rule.message);
                    this.validators[fieldName].valid = false;
                }
            }
        });
    }

    validForm() {
        let status = true;
        Object.keys(this.validators).forEach((field) => {
            if (this.state.is_edit) {
                //edit
                if (field === 'first_name' || field === 'last_name' || field === 'phone') {
                    if (!this.validators[field].valid) {
                        status = false;
                    }
                }
            } else {
                //add
                if (field === 'email' || field === 'password' || field === 'account_type' || field === 'first_name' ||
                    field === 'last_name' || field === 'phone') {
                    if (!this.validators[field].valid) {
                        status = false;
                    }
                }
            }
        });
        return status;
    }

    showErrors() {
        let fields = [];
        if (this.state.is_edit) {
            fields = ['first_name', 'last_name', 'phone'];
        } else {
            fields = ['email', 'password', 'account_type', 'first_name', 'last_name', 'phone'];
        }

        let errors = '';
        let return_error = false;
        for (let index = 0; index < fields.length ; index++) {
            const validator = this.validators[fields[index]];

            if (validator && !validator.valid) {
                for (let i =0 ;i<validator.errors.length;i++) {
                    errors += validator.errors[i];
                    break;
                }
            }

            if (return_error) {
                break;
            }
        }
        return errors;
    }

    onSubmit() {
        if (this.validForm()) {
            this.setState({
                loading: true
            })
            let postData = new FormData();
            if (this.state.is_edit) {
                postData.append('edit_user_id', this.state.edit_user_id);
            } else {
                postData.append('account_type', this.state.account_type);
            }
            postData.append('email', this.state.email);
            postData.append('profile_pic', this.state.profile_pic);
            postData.append('password', this.state.password);
            postData.append('first_name', this.state.first_name);
            postData.append('last_name', this.state.last_name);
            postData.append('dob', this.state.dob);
            postData.append('company_name', this.state.company_name);
            postData.append('occupation', this.state.occupation);
            postData.append('phone', this.state.phone);

            if (this.state.display_expire_at) {
                postData.append('expire_at', this.state.expire_at);
            }

            let callService = (this.state.is_edit) ? editUserFromAdmin(postData) : addUserFromAdmin(postData);

            callService.then(res => {
                if (res.success) {
                    this.showNotification(res.message, 'success')
                    this.setState({
                        redirect: true
                    })
                } else {
                    this.showNotification(res.message, 'danger')
                }
                this.setState({
                    loading: false
                })
            });
        } else {
            let msg = this.showErrors();
            if (msg.toString() === '') {
                this.showNotification('Please enter all required data', 'danger')
            } else {
                this.showNotification(msg, 'danger')
            }
        }
    }

    openUpload = () => {
        document.querySelector('input[type=\'file\']').click();
    }

    componentDidMount() {
        let profile = getMyProfile();
        if (profile) {
            this.setState({
                is_super_admin: (profile.is_super_admin),
            });
        }

        if (this.props.match.path === config.site_url.admin_edit_user + '/:id') {
            //is edit call
            let user_id = this.props.match.params.id;
            if (user_id) {

                this.setState({
                    page_loading: true
                })

                let postData = {
                    get_user_id: user_id
                }

                getUserFromAdmin(postData).then(res => {
                    if (res.success) {

                        let user = res.data.user;
                        this.setState({
                            is_edit: true,
                            edit_user_id: user.user_id,
                            img_display: (user.profile_pic),
                            profile_pic_url: (user.profile_pic) ? user.profile_pic : '',
                            account_type: user.account_type,
                            email: user.email,
                            company_name: user.company_name,
                            first_name: user.first_name,
                            last_name: user.last_name,
                            dob: (user.dob) ? moment(user.dob, 'YYYY-MM-DD').format('DD/MM/YYYY') : "",
                            occupation: user.occupation,
                            phone: user.phone,
                            expire_at: (user.expire_at && user.created_by_super_admin) ? moment(user.expire_at, 'YYYY-MM-DD').format('DD/MM/YYYY') : "",
                            display_expire_at: (user.created_by_super_admin),
                        })

                        this.setState({
                            page_loading: false
                        })

                        this.formValidators('first_name', user.first_name);
                        this.formValidators('last_name', user.last_name);
                        this.formValidators('dob', user.dob);
                        this.formValidators('phone', user.phone);
                    } else {
                        this.setState({
                            redirect_to_add: true,
                        })
                    }
                });


            } else {
                this.setState({
                    redirect_to_add: true,
                })
            }
        }
    }

    handleSwitch() {
        let account_type = 1;
        if (parseInt(this.state.account_type) === 2) {
            account_type = 1;
        } else {
            account_type = 2;
        }

        this.setState({
            account_type: account_type
        })
    }

    render() {

        if (!this.state.is_super_admin) {
            return <Redirect to={config.site_url.dashboard}/>;
        }

        if (this.state.redirect) {
            return <Redirect to={config.site_url.admin_dashboard}/>;
        }

        if (this.state.redirect_to_add) {
            return <Redirect to={config.site_url.admin_add_user}/>;
        }

        return <div className="">
            {/*--------------------------------------------------------------------------------*/}
            {/* Start Notification */}
            {/*--------------------------------------------------------------------------------*/}
            <ReactNotification ref={this.notificationDOMRef} />
            {/*--------------------------------------------------------------------------------*/}
            {/* End Notification */}
            {/*--------------------------------------------------------------------------------*/}

            {/*--------------------------------------------------------------------------------*/}
            {/*Login Cards*/}
            {/*--------------------------------------------------------------------------------*/}
            <div className="auth-wrapper no-block justify-content-center align-items-center">

                <div className={this.state.page_loading ? `page-loader show` : `page-loader hide`} />

                <div className={(this.state.page_loading) ? 'auth-box hide' : 'auth-box'}>
                    <Row>
                        <Col md="12">
                            <CardTitle className="f32 text-center mb-0">
                                User Details
                            </CardTitle>
                            <p className="mt-3 txt-gray text-center">
                                {(this.state.is_edit ? 'Update ' : 'Add new ')}user information
                            </p>
                        </Col>
                    </Row>

                    <div id="loginform">
                        <Card>
                            <CardBody className="">
                                <Row>
                                    <Col xs="12">
                                        <Form id="userdetail">

                                            <InputGroup>
                                                <Col md="12">
                                                    <div className={(this.state.img_display ? 'hide' : 'user-img')}>
                                                        <div className="avtar">
                                                            <img src={camera} width="40" alt="user_image" onClick={this.openUpload}/>
                                                        </div>

                                                        <Input
                                                            type="file"
                                                            className="hide"
                                                            name="profile_pic"
                                                            id="profile_pic"
                                                            onChange={this.onFileChange}
                                                        />

                                                        <div className="change-img" onClick={this.openUpload}>
                                                            <i className="fa fa-plus" />
                                                        </div>
                                                    </div>

                                                    <div className={(this.state.img_display ? 'user-img' : 'hide')}>
                                                        <div className="avtar-img">
                                                            <img src={this.state.profile_pic_url} width="40" alt="user_image"/>
                                                        </div>

                                                        <div className="change-img" onClick={this.openUpload}>
                                                            <i className="fa fa-pencil-alt" />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </InputGroup>

                                            <InputGroup className={(this.state.is_edit ? 'hide' : '')}>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Account Type*
                                                    </InputGroupText>
                                                </InputGroupAddon>

                                                <div className="field check-round slide-inverse">
                                                    <span className="sts">Personal </span>
                                                    <input
                                                        type="checkbox"
                                                        id="check-slide-i"
                                                        checked={(parseInt(this.state.account_type) === 2)}
                                                    />
                                                    <label for="check-slide-i">
                                                        <span onClick={this.handleSwitch}/>
                                                    </label>
                                                    <span className="sts">Business </span>
                                                </div>
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Email*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter your email"
                                                    name="email"
                                                    id="email"
                                                    value={this.state.email}
                                                    onChange={this.onInputChange}
                                                    readonly={(this.state.is_edit)}
                                                />
                                            </InputGroup>

                                            <InputGroup className={(this.state.is_edit) ? "" : "password-note-group"}>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Password*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="password"
                                                    placeholder="Enter your password"
                                                    name="password"
                                                    id="password"
                                                    value={this.state.password}
                                                    onChange={this.onInputChange}
                                                />
                                                <label className={(this.state.is_edit) ? "hide" : "password-note"}>
                                                    Use 5 or more characters with a mix of letters, numbers & symbols
                                                </label>
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        First Name*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter First Name"
                                                    name="first_name"
                                                    id="first_name"
                                                    value={this.state.first_name}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Surname*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter Last Name"
                                                    name="last_name"
                                                    id="last_name"
                                                    value={this.state.last_name}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        D.O.B
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <DOBField
                                                    name="dob"
                                                    value={this.state.dob}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <InputGroup className={(this.state.account_type === 2) ? '' : 'hide'}>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Company Name
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter Company Name"
                                                    name="company_name"
                                                    id="company_name"
                                                    value={this.state.company_name}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Job Title
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter Your Job Title"
                                                    name="occupation"
                                                    id="occupation"
                                                    value={this.state.occupation}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Contact no.*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter Your Contact Number"
                                                    name="phone"
                                                    id="phone"
                                                    value={this.state.phone}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <InputGroup className={(this.state.display_expire_at) ? '' : 'hide'}>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Expire At
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <DOBField
                                                    name="expire_at"
                                                    value={this.state.expire_at}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <div className="d-flex no-block align-items-cente auth-footer">
                                                <LeftLink text="Cancel" url={config.site_url.admin_dashboard} />

                                                <RightButton text={(this.state.is_edit) ? 'Update' : 'Create'} onClick={this.onSubmit} loading={this.state.loading} />
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default AdminUserForm;
