import React from 'react';
import {getMyProfile, getNotificationObject} from "../../services/helper";
import {getAdminUserList} from "../../services/services";
import {Redirect} from 'react-router';
import config from 'config/config';
import UserListTable from "../../components/admin/user-list-table";
import ReactNotification from "react-notifications-component";

export default class AdminUserList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            is_super_admin: true,
            users: [],
            paginator: {},
            loading: false,
        };

        this.setUsers = this.setUsers.bind(this);
        this.getUserList = this.getUserList.bind(this);

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();

    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    getUserList(page) {
        if (page !== '') {
            this.setState({
                loading: true
            });

            let postData = {
                page: page,
            }

            getAdminUserList(postData).then(res => {
                if (res.success) {
                    this.setUsers(res);
                } else {
                    if (res.error) {
                        if (typeof res.error !== 'undefined' && typeof res.error.admin_code !== 'undefined' &&
                            res.error.admin_code === 401
                        ) {
                            this.setState({
                                is_super_admin: false,
                            })
                        } else {
                            this.showNotification(res.message, 'danger')
                        }
                    } else {
                        this.showNotification(res.message, 'danger')
                    }
                }
                this.setState({
                    loading: false
                });
            });
        } else {
            this.setState({
                loading: false
            });
        }
    }

    componentDidMount() {
        let profile = getMyProfile();
        if (profile) {
            this.setState({
                is_super_admin: (profile.is_super_admin),
            });
        }

        this.getUserList(1);
    }

    setUsers(res) {
        this.setState({
            users: res.data.users,
            paginator: res.data.pagination
        })
    }

    render() {

        if (!this.state.is_super_admin) {
            return <Redirect to={config.site_url.dashboard}/>;
        }

        return (<div className="page-content no-gutters  container-fluid">

                <ReactNotification ref={this.notificationDOMRef} />

                <div className="client_wrap superadmin">

                    <UserListTable
                        users={this.state.users}
                        paginator={this.state.paginator}
                        loading={this.state.loading}
                        setUsers={this.setUsers}
                        getUserList={this.getUserList}
                        showNotification={this.showNotification}
                    />
                </div>
            </div>
        );
    }
}