import React from 'react';
import {
    Button,
    Row,
    Col,
    Table
} from 'reactstrap';
import {exportReport, deleteProperty} from "../../services/services";
import {formatMoney, calculatePropertyStatus, downloadReport} from "../../services/helper";
import Paginator from "../properlytics/paginator";
import {confirmAlert} from 'react-confirm-alert';
import config from "config/config";
import {Redirect} from 'react-router';

export default class PropertyTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect_to_property_detail: false,
        }

        this.addProperty = this.addProperty.bind(this);
        this.generateReport = this.generateReport.bind(this);
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.deleteProperty = this.deleteProperty.bind(this);
    }

    addProperty = () => {
        if (this.props.can_add) {
            this.setState({
                redirect_to_property_detail: true
            })
        } else {
            let msg = 'You reached max property limit, so you can\'t add new property.';
            this.props.showNotification(msg, 'danger');
        }
    }

    async generateReport(property_id) {

        document.getElementById('gen_btn_' + property_id).innerHTML = 'Generating...';

        let postData = {
            property_id: property_id
        };

        await exportReport(postData).then(res => {
            if (res.success) {
                downloadReport(res.data);
            } else {
                this.props.showNotification(res.message, 'danger');
            }

        });

        document.getElementById('gen_btn_' + property_id).innerHTML = 'Generate Report';
    }

    deleteConfirm = (property_id) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete this property?',
            buttons: [
                {
                    label: 'Cancel',
                },
                {
                    label: 'Delete',
                    onClick: () => this.deleteProperty(property_id)
                }
            ]
        }, property_id);
    }

    deleteProperty(property_id) {
        let postData = {
            property_id: property_id,
            client_id: this.props.client_id,
            page: this.props.paginator.current_page
        }

        deleteProperty(postData).then(res => {
            if (res.success) {
                this.props.showNotification(res.message, 'success')
                this.props.setProperties(res);
            } else {
                this.props.showNotification(res.message, 'danger')
            }
        });
    }

    render() {
        let {properties, paginator, loading, fetchProperties} = this.props;

        if (this.state.redirect_to_property_detail) {
            return <Redirect to={config.site_url.property_detail} />;
        }

        if (properties.length < 1) {
            return (
                <div id="prpty_list">
                    <Row>
                        <Col md="12">
                            <div className="table-wrap">
                                <Table responsive className="table custmize">
                                    <thead>
                                    <tr>
                                        <th/>
                                        <th className="mb_w">Address</th>
                                        <th className="w_130">Property Value</th>
                                        <th>Bed</th>
                                        <th>Bath</th>
                                        <th>Car</th>
                                        <th/>
                                        <th colSpan={2} className="text-right">
                                            <Button
                                                onClick={this.addProperty.bind(this)}
                                                className="tab_btn active"
                                            >
                                                Add Property
                                            </Button>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr className={(loading) ? 'strip' : 'hide'}>
                                        <td colSpan={9} className="text-center">
                                            <div className="table-loader"/>
                                        </td>
                                    </tr>
                                    <tr className={(loading) ? 'hide' : 'strip'}>
                                        <td colSpan={9} className="text-center">
                                            Property not available
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </div>
            );
        }

        let properties_data = properties.map((property, key) => {
            return (
                <tr className="strip">
                    <td>
                        <div className="img_bx">
                            <div className="table-image">
                                <div data-name="SVG Skill">
                                    <svg viewBox="-10 -10 220 220">
                                        <g fill="none" stroke-width="3" transform="translate(100,100)">
                                            <path d="M 0,-100 A 100,100 0 0,1 86.6,-50" stroke="url(#cl1)"></path>
                                            <path d="M 86.6,-50 A 100,100 0 0,1 86.6,50" stroke="url(#cl2)"></path>
                                            <path d="M 86.6,50 A 100,100 0 0,1 0,100" stroke="url(#cl3)"></path>
                                            <path d="M 0,100 A 100,100 0 0,1 -86.6,50" stroke="url(#cl4)"></path>
                                            <path d="M -86.6,50 A 100,100 0 0,1 -86.6,-50" stroke="url(#cl5)"></path>
                                            <path d="M -86.6,-50 A 100,100 0 0,1 0,-100" stroke="url(#cl6)"></path>
                                        </g>
                                    </svg>
                                    <svg viewBox="-10 -10 220 220">
                                        <path
                                            d="M200,100 C200,44.771525 155.228475,0 100,0 C44.771525,0 0,44.771525 0,100 C0,155.228475 44.771525,200 100,200 C155.228475,200 200,155.228475 200,100 Z"
                                            stroke-dashoffset={calculatePropertyStatus(property.complete_status)}
                                        >
                                        </path>
                                    </svg>

                                    <div className="over incomp">
                                        <div className="data-percent">
                                            <img src={property.image} alt="Property"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>

                    <td>
                        <span className="d-block">{property.address}</span>
                    </td>

                    <td>
                        ${formatMoney(property.price)}
                    </td>

                    <td>
                        {property.bedrooms}
                    </td>

                    <td>
                        {property.bathrooms}
                    </td>

                    <td>
                        {property.car_space}
                    </td>

                    <td>
                        <Button
                            id={'gen_btn_' + property.property_id}
                            onClick={this.generateReport.bind(this, property.property_id)}
                            className="tab_btn"
                        >
                            Generate Report
                        </Button>
                    </td>

                    <td>
                        <Button
                            className="tab_btn link-button"
                            type="button"
                        >
                            <a
                                href={config.hash + config.site_url.property_detail + '/' + property.property_id}
                            >
                                Open Property
                            </a>
                        </Button>
                    </td>

                    <td>
                        <div className="read-more">
                            <button type="button" onClick={this.deleteConfirm.bind(this, property.property_id)} className="trash-icon">
                                <i className="ti-trash"/>
                            </button>
                        </div>
                    </td>
                </tr>
            );
        });

        return (
            <div id="prpty_list">
                <Row>
                    <Col md="12">
                        <div className="table-wrap">
                            <Table responsive className="table custmize">
                                <thead>
                                <tr>
                                    <th/>
                                    <th className="mb_w">Address</th>
                                    <th className="w_130">Property Value</th>
                                    <th>Bed</th>
                                    <th>Bath</th>
                                    <th>Car</th>
                                    <th/>
                                    <th colSpan={2} className="text-right">
                                        <Button
                                            onClick={this.addProperty.bind(this)}
                                            className="tab_btn active"
                                        >
                                            Add Property
                                        </Button>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {properties_data}
                                </tbody>
                            </Table>

                            <svg width="0" height="0">
                                <defs>
                                    <linearGradient id="cl1" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="1"
                                                    y2="1">
                                        <stop stop-color="#00c880"/>
                                        <stop offset="100%" stop-color="#00c880"/>
                                    </linearGradient>

                                    <linearGradient id="cl2" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="0"
                                                    y2="1">
                                        <stop stop-color="#00c880"/>
                                        <stop offset="100%" stop-color="#00c880"/>
                                    </linearGradient>
                                    <linearGradient id="cl3" gradientUnits="objectBoundingBox" x1="1" y1="0" x2="0"
                                                    y2="1">
                                        <stop stop-color="#00c880"/>
                                        <stop offset="100%" stop-color="#00c880"/>
                                    </linearGradient>
                                    <linearGradient id="cl4" gradientUnits="objectBoundingBox" x1="1" y1="1" x2="0"
                                                    y2="0">
                                        <stop stop-color="#00c880"/>
                                        <stop offset="100%" stop-color="#00c880"/>
                                    </linearGradient>
                                    <linearGradient id="cl5" gradientUnits="objectBoundingBox" x1="0" y1="1" x2="0"
                                                    y2="0">
                                        <stop stop-color="#00c880"/>
                                        <stop offset="100%" stop-color="#00c880"/>
                                    </linearGradient>
                                    <linearGradient id="cl6" gradientUnits="objectBoundingBox" x1="0" y1="1" x2="1"
                                                    y2="0">
                                        <stop stop-color="#00c880"/>
                                        <stop offset="100%" stop-color="#00c880"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                    </Col>

                    <Col lg="12">
                        <Paginator
                            paginator={paginator}
                            onClick={fetchProperties}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}



