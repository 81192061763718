import React from 'react';
import {
    Button,
    Row,
    Col,
    Table,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
} from 'reactstrap';
import {deleteUserFromAdmin} from "../../services/services";
import Paginator from "../properlytics/paginator";
import {confirmAlert} from 'react-confirm-alert';
import {Redirect} from 'react-router';
import config from 'config/config';


export default class UserTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect_to_user_form: false,
        }

        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.openAddUser = this.openAddUser.bind(this);
        this.openCloseUser = this.openCloseUser.bind(this);
        this.openCloseClient = this.openCloseClient.bind(this);
    }

    openAddUser = () => {
        this.setState({
            redirect_to_user_form: true,
        })
    }

    deleteConfirm = (user_id) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete this user?',
            buttons: [
                {
                    label: 'Cancel',
                },
                {
                    label: 'Delete',
                    onClick: () => this.deleteUser(user_id)
                }
            ]
        }, user_id);
    }

    deleteUser(user_id) {
        let postData = {
            delete_user_id: user_id,
            page: this.props.paginator.current_page
        }

        deleteUserFromAdmin(postData).then(res => {
            if (res.success) {
                this.props.showNotification(res.message, 'success')
                this.props.setUsers(res);
            } else {
                this.props.showNotification(res.message, 'danger')
            }
        });
    }

    openCloseUser = (user_id) => {
        let id = 'user_' + user_id;
        document.querySelector('#'+id+' .main_row').classList.toggle("active");
        let clients = document.querySelectorAll('#'+id+' .collapse_row');
        for (let i=0;i<clients.length;i++) {
            clients[i].classList.toggle("open");
        }
    }

    openCloseClient = (client_id) => {
        let id = 'client_' + client_id;
        document.querySelector('#'+id+ ' .icon-arrow-down').classList.toggle('hide');
        document.querySelector('#'+id+ ' .icon-arrow-up').classList.toggle('hide');
        document.querySelector('#'+id+ ' .list-unstyled').classList.toggle('hide');
    }

    render() {
        let {users, paginator, loading, getDashboard} = this.props;

        if (this.state.redirect_to_user_form) {
            return <Redirect to={config.site_url.admin_add_user}/>;
        }

        return (
            <div id="prpty_list">
                <Row>
                    <Col md="12">
                        <div className="table-wrap">
                            <Table responsive className="custmize">
                                <thead>
                                <tr className="stripe">
                                    <th>First Name</th>
                                    <th>Surname</th>
                                    <th className="w_130">Last Login</th>
                                    <th>Clients</th>
                                    <th>Properties</th>
                                    <th>Account Type</th>
                                    <th/>
                                    <th/>
                                    <th colSpan={2} className="text-right">
                                        <Button
                                            onClick={this.openAddUser.bind(this)}
                                            className="tab_btn active"
                                        >
                                            Add User
                                        </Button>
                                    </th>
                                </tr>
                                </thead>

                                <UserMap
                                    loading={loading}
                                    users={users}
                                    deleteConfirm={this.deleteConfirm}
                                    openCloseUser={this.openCloseUser}
                                    openCloseClient={this.openCloseClient}
                                />

                            </Table>
                        </div>
                    </Col>

                    <Col lg="12">
                        <Paginator
                            paginator={paginator}
                            onClick={getDashboard}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const UserMap = ({loading, users, deleteConfirm, openCloseUser, openCloseClient}) => {

    if (!users || users.length === 0) {
        return (
            <tbody>
            <tr className="strip">
                <td colSpan={10} className="text-center">
                    <div className={(loading) ? 'table-loader' : 'hide'}/>
                    <div className={(loading) ? 'hide' : ''}>Users not available</div>
                </td>
            </tr>
            </tbody>
        )
    }

    return users.map(user => {
        let id = 'user_' + user.id;
        return (
            <tbody id={id}>
            <tr className="main_row">
                <td>
                    {user.first_name}
                </td>

                <td>
                    {user.last_name}
                </td>

                <td>
                    {user.last_login}
                </td>

                <td>
                    {user.client_count}
                </td>

                <td>
                    {user.property_count}
                </td>

                <td>
                    {user.account_type}
                </td>

                <td>
                    <a className="tab_btn" href={"mailto:" + user.email}>Email</a>
                </td>

                <td>
                    <a className="tab_btn" href={"tel:+61" + user.phone}>Phone</a>
                </td>

                <td className="text-right">
                    <div className="read-more" onClick={openCloseUser.bind(this, user.id)}>
                        <span className="clickme admin-arrow">
                            <i className="icon-arrow-right"/>
                        </span>
                    </div>
                </td>

                <td className="text-right">
                    <UncontrolledDropdown  inNavbar className="read-more">
                        <DropdownToggle nav className="rpad0">
                            <span className="more-icon"/>
                            <span className="more-icon"/>
                            <span className="more-icon"/>
                        </DropdownToggle>

                        <DropdownMenu right className="pad0 user-dd">
                            <Nav>
                                <NavItem>
                                    <NavLink href={config.hash + config.site_url.admin_edit_user + '/' + user.id}>
                                        Edit
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink onClick={deleteConfirm.bind(this, user.id)}>
                                        Delete
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </td>
            </tr>

            <ClientMap
                clients={user.clients}
                openCloseClient={openCloseClient}
            />
            </tbody>
        );
    })
}

const ClientMap = ({clients, openCloseClient}) => {

    if (!clients || clients.length === 0) {
        return ('');
    }

    return clients.map(client => {
        let id = 'client_' + client.id;
        return (
            <tr id={id} className="collapse_row">
                <td>
                    <span>{client.first_name}</span>
                </td>

                <td>
                    <span>{client.last_name}</span>
                </td>

                <td>
                    <span
                        onClick={openCloseClient.bind(this, client.id)}
                        className="link t_gray"
                    >
                        View Properties&nbsp;
                        <i className="icon-arrow-down f10"/><i className="icon-arrow-up f10 hide"/>
                    </span>
                    <ul className="list-unstyled hide">
                        <PropertyMap
                            properties={client.properties}
                        />
                    </ul>
                </td>

                <td/>

                <td>{client.property_count}</td>

                <td/>

                <td/>

                <td/>

                <td/>

                <td/>
            </tr>
        );
    })
}

const PropertyMap = ({properties}) => {

    if (!properties || properties.length === 0) {
        return ('');
    }

    return properties.map(property => {
        return (
            <li>
                <span>
                    <a href={config.hash + config.site_url.property_detail + '/' + property.id}>{property.address}</a>
                </span>
            </li>
        );
    })
}

