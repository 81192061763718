import React from 'react';
import {
    Button,
    Nav,
    NavItem,
    NavLink,
    Row,
    Col,
    Table,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
} from 'reactstrap';
import {deleteClient} from "../../services/services";
import Paginator from "../properlytics/paginator";
import {confirmAlert} from 'react-confirm-alert';
import config from 'config/config';


export default class ClientTable extends React.Component {

    constructor(props) {
        super(props);

        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.deleteClient = this.deleteClient.bind(this);
    }

    deleteConfirm = (client_id) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete this client?',
            buttons: [
                {
                    label: 'Cancel',
                },
                {
                    label: 'Delete',
                    onClick: () => this.deleteClient(client_id)
                }
            ]
        }, client_id);
    }

    deleteClient(client_id) {
        let postData = {
            client_id: client_id,
            page: this.props.paginator.current_page
        }

        deleteClient(postData).then(res => {
            if (res.success) {
                this.props.showNotification(res.message, 'success')
                this.props.setClients(res);
            } else {
                this.props.showNotification(res.message, 'danger')
            }
        });
    }

    render() {
        let {clients, paginator, loading, fetchClients} = this.props;

        if (clients.length < 1) {
            return (
                <div id="prpty_list">
                    <Row>
                        <Col md="12">
                            <div className="table-wrap">
                                <Table responsive className="table custmize">
                                    <thead>
                                    <tr>
                                        <th>First Name</th>
                                        <th>Surname</th>
                                        <th className="w_130">Email</th>
                                        <th>Phone</th>
                                        <th>Properties</th>
                                        <th colSpan={2} className="text-right">
                                            <a href={config.hash + config.site_url.add_client} className="tab_btn active">
                                                Add Client
                                            </a>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr className={(loading) ? 'strip' : 'hide'}>
                                        <td colSpan={7} className="text-center">
                                            <div className="table-loader"/>
                                        </td>
                                    </tr>
                                    <tr className={(loading) ? 'hide' : 'strip'}>
                                        <td colSpan={7} className="text-center">
                                            Clients not available
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </div>
            );
        }

        let clients_data = clients.map((client, key) => {
            return (
                <tr className="strip">
                    <td>
                        {client.first_name}
                    </td>

                    <td>
                        {client.last_name}
                    </td>

                    <td>
                        <span className="d-block">{client.email}</span>
                    </td>

                    <td>
                        {client.phone}
                    </td>

                    <td>
                        {client.property_count}
                    </td>

                    <td>
                        <Button
                            onClick={this.props.getClientProperties.bind(this, client.client_id)}
                            className="tab_btn"
                        >
                            Open
                        </Button>
                    </td>

                    <td className="text-right">
                        <UncontrolledDropdown  inNavbar className="read-more">
                            <DropdownToggle nav className="rpad0">
                                <span className="more-icon"/>
                                <span className="more-icon"/>
                                <span className="more-icon"/>
                            </DropdownToggle>

                            <DropdownMenu right className="pad0 user-dd">
                                <Nav>
                                    <NavItem>
                                        <NavLink href={config.hash + config.site_url.edit_client + '/' + client.client_id}>
                                            Edit
                                        </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink onClick={this.deleteConfirm.bind(this, client.client_id)}>
                                            Delete
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </td>
                </tr>
            );
        });

        return (
            <div id="prpty_list">
                <Row>
                    <Col md="12">
                        <div className="table-wrap">
                            <Table responsive className="table custmize">
                                <thead>
                                <tr>
                                    <th>First Name</th>
                                    <th>Surname</th>
                                    <th className="w_130">Email</th>
                                    <th>Phone</th>
                                    <th>Properties</th>
                                    <th colSpan={2} className="text-right">
                                        <a href={config.hash + config.site_url.add_client} className="tab_btn active">
                                            Add Client
                                        </a>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {clients_data}
                                </tbody>
                            </Table>
                        </div>
                    </Col>

                    <Col lg="12">
                        <Paginator
                            paginator={paginator}
                            onClick={fetchClients}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}



