import React from 'react';
import {
    Row,
    Col,
    Card,
    CardTitle,
    CardBody,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Form,
} from 'reactstrap';
import {dataURLtoFile, getNotificationObject, storeInLocalStorage} from "../../services/helper";
import {performSingup} from "../../services/services";
import ReactNotification from "react-notifications-component";
import validator from "./signup-validators";
import {Redirect} from 'react-router';
import Autocomplete from 'react-google-autocomplete';
import config from 'config/config';
import RightButton from "../../components/properlytics/right-button";
import DOBField from "../../components/numbers/DOBField";
import UploadAvtar from "../../components/properlytics/upload-avtar";
import ReactPixel from 'react-facebook-pixel';


class SetupProfile extends React.Component {
    constructor(props) {
        super(props);

        this.validators = validator;
        this.updateState = this.updateState.bind(this);
        this.onAddressChange = this.onAddressChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.showErrors = this.showErrors.bind(this);
        this.formValidators = this.formValidators.bind(this);
        this.onSignup = this.onSignup.bind(this);
        this.handleAccountType = this.handleAccountType.bind(this);

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();

        let redirect_to_login = true;
        let email = '';
        let first_name = '';
        let last_name = '';
        let signup_with = 3;
        if (typeof props.location.state !== 'undefined') {
            if (typeof props.location.state.email !== 'undefined') {
                redirect_to_login = false;
                email = props.location.state.email;
                first_name = props.location.state.first_name;
                last_name = props.location.state.last_name;
                signup_with = props.location.state.signup_with;
                this.formValidators('email', email);
                this.formValidators('first_name', first_name);
                this.formValidators('last_name', last_name);
            }
        }

        this.state = {
            signup_with: signup_with,
            account_type: 1,
            profile_pic: '',
            img_display: false,
            profile_pic_url: '',
            first_name: first_name,
            last_name: last_name,
            dob: '',
            company_name: '',
            occupation: '',
            address: '',
            phone: '',
            email: email,
            password: '',

            redirect: false,
            redirect_to_login: redirect_to_login,
            loading: false,
        }
    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    updateState(name, value) {
        this.setState({
            [name]: value,
        })
    }

    onAddressChange(place) {
        this.setState({
            address: place.formatted_address
        });
        this.formValidators('address', place.formatted_address);
    }

    onInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        this.formValidators([event.target.name], event.target.value);
    }

    formValidators(fieldName, value) {
        this.validators[fieldName].errors = [];
        this.validators[fieldName].state = value;
        this.validators[fieldName].valid = true;
        this.validators[fieldName].rules.forEach((rule) => {
            if (rule.test instanceof RegExp) {
                if (!rule.test.test(value)) {
                    this.validators[fieldName].errors.push(rule.message);
                    this.validators[fieldName].valid = false;
                }
            } else if (typeof rule.test === 'function') {
                if (!rule.test(value)) {
                    this.validators[fieldName].errors.push(rule.message);
                    this.validators[fieldName].valid = false;
                }
            }
        });
    }

    validForm() {
        let status = true;
        Object.keys(this.validators).forEach((field) => {
            if (field === 'email' || field === 'first_name' || field === 'last_name' ||
                field === 'phone') {
                if (!this.validators[field].valid) {
                    status = false;
                }
            }
        });
        return status;
    }

    showErrors() {
        const fields = ['email', 'first_name', 'last_name', 'phone'];

        let errors = '';
        let return_error = false;
        for (let index = 0; index < fields.length ; index++) {
            const validator = this.validators[fields[index]];

            if (validator && !validator.valid) {
                for (let i =0 ;i<validator.errors.length;i++) {
                    errors += validator.errors[i];
                    break;
                }
            }

            if (return_error) {
                break;
            }
        }
        return errors;
    }

    onSignup() {
        if (this.validForm()) {
            this.setState({
                loading: true
            })
            let postData = new FormData();
            postData.append('signup_with', this.state.signup_with);
            postData.append('account_type', this.state.account_type);
            if (this.state.profile_pic) {
                postData.append('profile_pic', dataURLtoFile(this.state.profile_pic));
            }
            postData.append('first_name', this.state.first_name);
            postData.append('last_name', this.state.last_name);
            postData.append('dob', this.state.dob);
            postData.append('company_name', this.state.company_name);
            postData.append('occupation', this.state.occupation);
            postData.append('address', this.state.address);
            postData.append('phone', this.state.phone);
            postData.append('email', this.state.email);

            performSingup(postData).then(res => {
                if (res.success) {
                    storeInLocalStorage(res.data.profile_details);
                    this.setState({
                        redirect: true,
                    })
                } else {
                    this.setState({
                        loading: false
                    })
                    this.showNotification(res.message, 'danger')
                }
            });
        } else {
            let msg = this.showErrors();
            if (msg.toString() === '') {
                this.showNotification('Please enter all required data', 'danger')
            } else {
                this.showNotification(msg, 'danger')
            }
        }
    }

    handleAccountType() {
        let account_type = 1;
        if (parseInt(this.state.account_type) === 2) {
            account_type = 1;
        } else {
            account_type = 2;
        }

        this.setState({
            account_type: account_type
        })
    }

    componentDidMount() {
        ReactPixel.init(config.fb_pixel_id);
        ReactPixel.pageView();
    }

    render() {

        if (this.state.redirect) {
            return <Redirect to={config.site_url.dashboard} />;
        }

        if (this.state.redirect_to_login) {
            return <Redirect to={config.site_url.login} />;
        }

        return <div className="">
            {/*--------------------------------------------------------------------------------*/}
            {/* Start Notification */}
            {/*--------------------------------------------------------------------------------*/}
            <ReactNotification ref={this.notificationDOMRef} />
            {/*--------------------------------------------------------------------------------*/}
            {/* End Notification */}
            {/*--------------------------------------------------------------------------------*/}

            {/*--------------------------------------------------------------------------------*/}
            {/*Login Cards*/}
            {/*--------------------------------------------------------------------------------*/}
            <div className="auth-wrapper no-block justify-content-center align-items-center">

                <div className="auth-box">
                    <Row>
                        <Col md="12">
                            <CardTitle className="f32 text-center mb-0">
                                Setup Profile
                            </CardTitle>
                            <p className="mt-3 txt-gray text-center">Welcome to properlytics</p>
                        </Col>
                    </Row>

                    <div id="loginform">
                        <Card>
                            <CardBody className="">
                                <Row>
                                    <Col xs="12">
                                        <Form id="signupform">

                                            <UploadAvtar
                                                from="signup-profile"
                                                img_display={this.state.img_display}
                                                profile_pic_url={this.state.profile_pic_url}
                                                updateState={this.updateState}
                                            />

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Account Type*
                                                    </InputGroupText>
                                                </InputGroupAddon>

                                                <div className="field check-round slide-inverse">
                                                    <span className="sts">Personal </span>
                                                    <input
                                                        type="checkbox"
                                                        id="check-slide-i"
                                                        checked={(parseInt(this.state.account_type) === 2)}
                                                    />
                                                    <label for="check-slide-i">
                                                        <span onClick={this.handleAccountType}/>
                                                    </label>
                                                    <span className="sts">Business </span>
                                                </div>
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        First Name*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter First Name"
                                                    name="first_name"
                                                    id="first_name"
                                                    value={this.state.first_name}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Surname*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter Last Name"
                                                    name="last_name"
                                                    id="last_name"
                                                    value={this.state.last_name}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        D.O.B
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <DOBField
                                                    name="dob"
                                                    value={this.state.dob}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <InputGroup className={(this.state.account_type === 2) ? '' : 'hide'}>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Company Name
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter Company Name"
                                                    name="company_name"
                                                    id="company_name"
                                                    value={this.state.company_name}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Job Title
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter Your Job Title"
                                                    name="occupation"
                                                    id="occupation"
                                                    value={this.state.occupation}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Address
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Autocomplete
                                                    className="form-control"
                                                    onPlaceSelected={this.onAddressChange}
                                                    types={[]}
                                                    componentRestrictions={{country: "au"}}
                                                />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Contact no.*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter Your Contact Number"
                                                    name="phone"
                                                    id="phone"
                                                    value={this.state.phone}
                                                    onChange={this.onInputChange}
                                                />
                                            </InputGroup>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        Email*
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter your email"
                                                    name="email"
                                                    id="email"
                                                    value={this.state.email}
                                                />
                                            </InputGroup>

                                            <div className="d-flex no-block align-items-cente auth-footer">
                                                <div className="right_bx d-flex">
                                                </div>

                                                <RightButton text="Setup Account" onClick={this.onSignup} loading={this.state.loading} />
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default SetupProfile;
