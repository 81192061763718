import React from 'react'
import {Redirect} from 'react-router';
import {isLogin} from "../services/helper";
import config from 'config/config';

export default function (ComposedComponent) {

    class GuestMiddleware extends React.Component {

        state = {
            authenticate: false
        }

        // Push to login route if not authenticated on mount
        UNSAFE_componentWillMount() {
            if (isLogin()) {
                // Use your router to redirect them to login page
                this.setState({
                    authenticate: true
                })
            }
        }

        // Push to login route if not authenticated on update
        UNSAFE_componentWillUpdate(nextProps) {
            if (isLogin()) {
                // Use your router to redirect them to login page
                this.setState({
                    authenticate: true
                })
            }
        }

        // Otherwise render the original component
        render() {
            if (this.state.authenticate === false) {
                return <ComposedComponent {...this.props}/>
            } else {
                return <Redirect to={config.site_url.dashboard}/>;
            }
        }

    }

    return GuestMiddleware

}