import React from 'react';
import Header from '../components/header/header';
import {Route, Switch, Redirect} from 'react-router-dom';
import innerRoutes from "routes/innerroutes";


class Innerlayout extends React.Component {

    /*--------------------------------------------------------------------------------*/
    /*Change the layout settings [HEADER,SIDEBAR && DARK LAYOUT] from here            */
    /*--------------------------------------------------------------------------------*/
    constructor(props) {
        super(props);

        this.updateDimensions = this.updateDimensions.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.state = {
            isOpen: false,
            width: window.innerWidth,
            settings: [{
                theme: 'light',
                dir: 'ltr',
                sidebartype: 'full',
                sidebarpos: 'fixed',
                headerpos: 'fixed',
                boxed: 'full'
            }]
        };

        this.props.history.listen((location, action) => {
            if (window.innerWidth < 767 &&
                document.getElementById('main-wrapper').className.indexOf("show-sidebar") !== -1) {
                document.getElementById('main-wrapper').classList.toggle("show-sidebar");
            }
        });
    }

    /*--------------------------------------------------------------------------------*/
    /*Life Cycle Hook, Applies when loading or resizing App                           */
    /*--------------------------------------------------------------------------------*/
    componentDidMount() {
        window.addEventListener("load", this.updateDimensions);
        window.addEventListener("resize", this.updateDimensions);
        window.addEventListener("scroll", this.handleScroll);
    }
    /*--------------------------------------------------------------------------------*/
    /*Function that handles sidebar, changes when resizing App                        */
    /*--------------------------------------------------------------------------------*/
    updateDimensions() {
        let element = document.getElementById('main-wrapper');
        this.setState({
            width: window.innerWidth
        });
        switch (this.state.settings[0].sidebartype) {
            case 'full':
            case 'iconbar':
                if (this.state.width < 1170) {
                    element.setAttribute("data-sidebartype", "mini-sidebar");
                    element.classList.add("mini-sidebar");
                } else {
                    element.setAttribute("data-sidebartype", this.state.settings[0].sidebartype);
                    element.classList.remove("mini-sidebar");
                }
                break;

            case 'overlay':
                if (this.state.width < 767) {
                    element.setAttribute("data-sidebartype", "mini-sidebar");
                } else {
                    element.setAttribute("data-sidebartype", this.state.settings[0].sidebartype);
                }
                break;

            default:
        }
        if (this.state.settings[0].theme === "dark") {
            document.getElementById("theme-view").setAttribute("checked", "");
        }
        if (this.state.settings[0].boxed === "boxed") {
            document.getElementById("boxed-layout").setAttribute("checked", "");
        }
        if (this.state.settings[0].dir === "rtl") {
            document.getElementById("rtl").setAttribute("checked", "");
        }
    }
    /*--------------------------------------------------------------------------------*/
    /*Life Cycle Hook                                                                 */
    /*--------------------------------------------------------------------------------*/
    componentWillUnmount() {
        window.removeEventListener("load", this.updateDimensions);
        window.removeEventListener("resize", this.updateDimensions);
        window.removeEventListener("scroll", this.handleScroll);
    }
    /*--------------------------------------------------------------------------------*/
    /*Theme Setting && Changes Default(ltr) DIRECTION to rtl DIRECTION                   */
    /*--------------------------------------------------------------------------------*/
    rtl = (h) => {
        if (h.target.checked) {
            let rtl = JSON.parse(JSON.stringify(this.state.settings));
            rtl[0].dir = 'rtl';
            this.setState({ settings: rtl });
        } else {
            let ltr = JSON.parse(JSON.stringify(this.state.settings));
            ltr[0].dir = 'ltr';
            this.setState({ settings: ltr });
        }
    }

    handleScroll(event) {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            document.getElementById("top-header").classList.add('fixed-header');
        } else {
            document.getElementById("top-header").classList.remove('fixed-header');
        }
    }

    render() {
        return <div
            id="main-wrapper"
            dir={this.state.settings[0].dir}
            data-theme={this.state.settings[0].theme}
            data-layout={this.state.settings[0].layout}
            data-sidebartype={this.state.settings[0].sidebartype}
            data-sidebar-position={this.state.settings[0].sidebarpos}
            data-header-position={this.state.settings[0].headerpos}
            data-boxed-layout={this.state.settings[0].boxed}
        >
            {/*--------------------------------------------------------------------------------*/}
            {/* Header                                                                         */}
            {/*--------------------------------------------------------------------------------*/}
            <Header data={this.state} />
            {/*--------------------------------------------------------------------------------*/}
            {/*CLIENT lIST*/}
            {/*--------------------------------------------------------------------------------*/}
            <Switch>
                {innerRoutes.map((prop, key) => {
                    if (prop.redirect)
                        return (
                            <Redirect from={prop.path} to={prop.pathTo} key={key}/>
                        );
                    return (
                        <Route path={prop.path} component={prop.component} key={key}/>
                    );
                })}
            </Switch>
        </div>
    }
}

export default Innerlayout;