import React from 'react';
import {
    Row,
    Col,
    CardTitle,
    Form,
    Input,
    Button,
} from 'reactstrap';
import {
    getMyProfile, getNotificationObject, setPurchaseSubscriptionState,
    storeInLocalStorage
} from "../../services/helper";
import ReactNotification from "react-notifications-component";
import {Redirect} from 'react-router';
import config from 'config/config';
import moment from 'moment';
import Select, { components } from "react-select";
import StripeCheckout from 'react-stripe-checkout';
import LeftLink from "../../components/properlytics/left-link";
import {
    getLicence,
    getLicenceOptions,
} from "../../services/config-helper";
import brandLogo from '../../assets/images/logo-for-stripe.png';
import {addSubscription, applyCoupon} from "../../services/services";

const groupStyles = {
    borderRadius: '0px',
    background: '#ffffff',
    padding: "0"
};

const Group = (props) => (
    <div style={groupStyles}>
        <components.Group {...props} />
    </div>
);

const style1 = {
    control: (base, state) => ({
        ...base,
        // This line disable the blue border
        background: state.isFocused ? 0 : 0,
        padding: "0 !important",
        margin: "0 !important",
        border: "0 !important",
        boxShadow: "0 !important",
        "&:hover": {
            border: "0 !important"
        }
    })
};

class SubscriptionPurchase extends React.Component {
    constructor(props) {
        super(props);

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();

        this.onInputChange = this.onInputChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onChangeQty = this.onChangeQty.bind(this);
        this.setSubscriptionState = this.setSubscriptionState.bind(this);
        this.onToken = this.onToken.bind(this);

        let trial_days = config.trial_days;

        this.state = {
            user_id: '',
            email: '',
            subscription_type: 1, //1 =personal, 2=business
            subscription_interval: 1, // 1=monthly, 2=yearly
            has_trial: true,
            coupon_code: '',
            message: '',

            //plan prices
            start_date: moment().add(trial_days,'days').format('DD/MM/YYYY'),
            end_date: moment().add(trial_days,'days').add(1, 'month').format('DD/MM/YYYY'),
            qty: 1,
            sub_price: 0,
            tax: 0,
            discount: 0,
            discount_per: 0,
            total: 0,
            redirect_choose_subscription: false,
            redirect_to_manage_subscription: false,
            loading: false,
            coupon_loading: false,
        }
    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    onInputChange(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    onBlur(event) {
        let value = event.target.value;
        if (value !== '') {
            this.setState({
                coupon_loading: true
            })
            let postData = {
                coupon_code: value,
            }

            applyCoupon(postData).then(res => {
                let discount = 0;
                let discount_per = 0;
                if (res.success) {
                    this.showNotification(res.message, 'success')
                    discount = res.data.discount;
                    discount_per = res.data.discount_per;
                } else {
                    this.showNotification(res.message, 'danger')
                }
                this.setState({
                    coupon_loading: false,
                    discount: discount,
                    discount_per:discount_per
                })
                this.setSubscriptionState(this.state.subscription_type, this.state.subscription_interval, this.state.qty, this.state.has_trial, discount, discount_per);
            });
        } else {
            this.setState({
                discount: 0,
                discount_per:0
            })
            this.setSubscriptionState(this.state.subscription_type, this.state.subscription_interval, this.state.qty, this.state.has_trial, 0, 0);
        }
    }

    onChangeQty(selected) {
        this.setState({
            qty: selected.value,
        });
        this.setSubscriptionState(this.state.subscription_type, this.state.subscription_interval, selected.value, this.state.has_trial, this.state.discount, this.state.discount_per);
    }

    setSubscriptionState(subscription_type, subscription_interval, qty, has_trial, discount, discount_per) {
        let state = setPurchaseSubscriptionState(subscription_type, subscription_interval, qty, has_trial, discount, discount_per);
        this.setState(state);
    }

    onToken = (token, addresses) => {
        // TODO: Send the token information and any other
        // relevant information to your payment process
        // server, wait for the response, and update the UI
        // accordingly. How this is done is up to you. Using
        // XHR, fetch, or a GraphQL mutation is typical.
        if (typeof token.id !== 'undefined') {

            this.setState({
                loading: true
            })
            let postData = {
                stripe_token: token.id,
                subscription_type: this.state.subscription_type,
                subscription_interval: this.state.subscription_interval,
                qty: this.state.qty,
                account_type: this.state.subscription_type,
                coupon: this.state.coupon_code,
                has_trial: (this.state.has_trial) ? 1 : 0
            }

            addSubscription(postData).then(res => {
                if (res.success) {
                    storeInLocalStorage(res.data.profile_details);
                    //this.showNotification(res.message, 'success')
                    this.setState({
                        message: res.message,
                        redirect_to_manage_subscription: true
                    })
                } else {
                    this.showNotification(res.message, 'danger')
                }
                this.setState({
                    loading: false
                })
            });
        } else {
            this.showNotification('Please try again', 'danger')
        }
    };

    componentDidMount() {
        let profile = getMyProfile();
        if (profile) {
            this.setState({
                user_id: profile.user_id,
                email: profile.email,
            });

            let subscription_type = this.props.location.subscription_type;
            let subscription_interval = this.props.location.subscription_interval;
            let has_trial = (typeof this.props.location.has_trial !== 'undefined') ? this.props.location.has_trial : true;
            if (subscription_type && subscription_interval) {
                this.setSubscriptionState(subscription_type, subscription_interval, this.state.qty, has_trial, this.state.discount, this.state.discount_per);
            } else {
                this.setState({
                    redirect_choose_subscription: true,
                })
            }
        } else {
            this.setState({
                redirect_choose_subscription: true,
            })
        }
    }

    render() {

        if (this.state.redirect_choose_subscription) {
            return <Redirect to={config.site_url.subscription} />;
        }

        if (this.state.redirect_to_manage_subscription) {
            return <Redirect
                to={{
                    pathname: config.site_url.subscription_manage,
                    message: this.state.message,
                }}
            />;
        }

        return <div className="">
            {/*--------------------------------------------------------------------------------*/}
            {/* Start Notification */}
            {/*--------------------------------------------------------------------------------*/}
            <ReactNotification ref={this.notificationDOMRef} />
            {/*--------------------------------------------------------------------------------*/}
            {/* End Notification */}
            {/*--------------------------------------------------------------------------------*/}

            {/*--------------------------------------------------------------------------------*/}
            {/*Login Cards*/}
            {/*--------------------------------------------------------------------------------*/}
            <div className="auth-wrapper no-block justify-content-center align-items-center">

                <div className="auth-box">
                    <Row>
                        <Col md="12">
                            <CardTitle className="f32 text-center mb-0">
                                Subscription
                            </CardTitle>
                            <p className="mt-3 txt-gray text-center">Purchase plan.</p>
                        </Col>
                    </Row>

                    <div id="subscription">
                        <div className="rect_bx mt-5">
                            <div className="head_tron d-flex justify-content-between">
                                <h2 className="head t_green">Your subscription</h2>
                                <h2 className="head t_black">
                                    {(this.state.subscription_type === 1) ? 'Personal' : 'Business'}
                                    {(this.state.subscription_interval === 1) ? '(Monthly)' : '(Yearly)'}
                                </h2>
                                <h2 className="head t_gray">{this.state.start_date} to {this.state.end_date}</h2>
                            </div>

                            <div className="inner_bx">
                                <Form id="prp_detail">
                                    <div className="tp_row">
                                        <div className="hd_row d-flex">
                                            <h4 className="sm_tit fx_w">Licences</h4>
                                            <div className={(this.state.subscription_type === 2) ? 'slct' : 'hide'}>
                                                <Select
                                                    defaultValue={getLicence()}
                                                    options={getLicenceOptions}
                                                    components={{ Group }}
                                                    styles={style1}
                                                    value={getLicence(this.state.qty)}
                                                    onChange={this.onChangeQty}
                                                />
                                            </div>

                                            <div className={(this.state.subscription_type === 1) ? 'slct' : 'hide'}>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.qty}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tp_row">
                                        <div className="hd_row d-flex">
                                            <h4 className="sm_tit fx_w">Coupon Code</h4>
                                            <div className="slct">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter coupon code to get discount"
                                                    name="coupon_code"
                                                    value={this.state.coupon_code}
                                                    onChange={this.onInputChange}
                                                    onBlur={this.onBlur}
                                                />
                                                <div className={this.state.coupon_loading ? "input-loader" : "hide"} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tp_row pr-0 pt-0 pb-0 height60">
                                        <div className="hd_row d-flex inner_row">
                                            <h4 className="sm_tit fx_w">
                                                Subscription Cost
                                            </h4>

                                            <div className="d-flex w100 height38  justify-content-between">
                                                <h4 className="sm_tit fx_w">
                                                    Sub total
                                                </h4>

                                                <span className="d-flex  height60">
                                                    <span className="value  pmedium">${this.state.sub_price}</span>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="hd_row d-flex inner_row">
                                            <h4 className="sm_tit fx_w">
                                            </h4>

                                            <div className="d-flex w100 height38 brd-top justify-content-between">
                                                <h4 className="sm_tit fx_w">
                                                    GST {config.gst_percentage}%
                                                </h4>

                                                <span className="d-flex  height60">
                                                    <span className="value  pmedium">${this.state.tax}</span>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="hd_row d-flex inner_row">
                                            <h4 className="sm_tit fx_w">
                                            </h4>

                                            <div className="d-flex w100 height38 brd-top justify-content-between">
                                                <h4 className="sm_tit fx_w">
                                                    Discount
                                                </h4>

                                                <span className="d-flex  height60">
                                                    <span className="value  pmedium">${this.state.discount}</span>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="hd_row d-flex inner_row">
                                            <h4 className="sm_tit fx_w">
                                            </h4>

                                            <div className="d-flex w100 height38 brd-top justify-content-between">
                                                <h4 className="f14 t_black pbold fx_w">
                                                    Total
                                                </h4>

                                                <span className="d-flex  height60">
                                                    <span className="value t_black pbold">${this.state.total}</span>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="hd_row d-flex inner_row">
                                            <h4 className="sm_tit fx_w">
                                            </h4>

                                            <div className="d-flex  height60 brd-top w100  justify-content-end">

                                                <StripeCheckout
                                                    stripeKey={config.stripe_key}
                                                    token={this.onToken}
                                                    panelLabel="Activate"
                                                    label="Subscribe With Stripe"
                                                    currency="AUD"
                                                    amount={(this.state.total * 100)}
                                                    email={this.state.email}
                                                    name="Properlytics Subscription"
                                                    description="Activate the Free Trial"
                                                    image={brandLogo}
                                                    locale="auto"
                                                >
                                                    <Button
                                                        size="lg"
                                                        type="button"
                                                        className="t_green value wauto"
                                                        block
                                                        disabled={this.state.loading}
                                                    >
                                                        <div>
                                                            Subscribe <i aria-hidden="true" className="icon-arrow-right f10" />
                                                        </div>

                                                        <div className={(this.state.loading) ? 'btn-loading btn-s-loading right' : 'hide'}>
                                                            <div className="btn-loader" />
                                                        </div>
                                                    </Button>
                                                </StripeCheckout>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tp_row_btm d-flex justify-content-between">
                                        <LeftLink url={config.site_url.subscription} text="Exit" />
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default SubscriptionPurchase;
