import React from 'react'
import {Redirect} from 'react-router';
import {getMyProfile, isLogin} from "../services/helper";
import {isSuperAdmin} from "../services/services";
import config from 'config/config';

export default function (ComposedComponent) {

    class SuperAdminMiddleware extends React.Component {

        state = {
            authenticate: true,
            is_super_admin: true
        }

        // Push to login route if not authenticated on mount
        async UNSAFE_componentWillMount() {
            if (!isLogin()) {
                // Use your router to redirect them to login page
                this.setState({
                    authenticate: false
                })
            } else {
                let is_super_admin = await isSuperAdmin();
                if (is_super_admin !== this.state.is_super_admin) {
                    this.setState({
                        is_super_admin: is_super_admin,
                    });
                }
            }
        }

        // Push to login route if not authenticated on update
        UNSAFE_componentWillUpdate(nextProps) {
            if (!isLogin()) {
                // Use your router to redirect them to login page
                this.setState({
                    authenticate: false
                })
            } else {
                let profile = getMyProfile();
                if (profile.is_super_admin !== this.state.is_super_admin) {
                    this.setState({
                        is_super_admin: (profile.is_super_admin),
                    });
                }
            }
        }

        // Otherwise render the original component
        render() {
            if (this.state.authenticate === true) {
                if (this.state.is_super_admin === true) {
                    return <ComposedComponent {...this.props}/>
                } else {
                    return <Redirect to={config.site_url.dashboard}/>;
                }
            } else {
                return <Redirect to={config.site_url.login}/>;
            }
        }

    }

    return SuperAdminMiddleware

}