const env = 'live'; //local = local environment, live = live environment

//local
const local_data = {
    base_url: 'http://localhost:8000/api/site/',
    linkedin_redirect_uri: 'http://localhost:3000/linkedin',
    stripe_key: 'pk_test_Hr5So0FZv7jDHs3bzkNcDLvQ',
}

//live
const live_data = {
    base_url: 'https://app.properlytics.com.au/production/api/site/',
    linkedin_redirect_uri: 'https://app.properlytics.com.au/linkedin',
    stripe_key: 'pk_live_Cna6BNr4XBEGjoIFaNl8fDYV',
}

const config = {
    base_url : (env === 'local') ? local_data.base_url : live_data.base_url,

    //Urls
    login: 'user/login',
    register: 'user/register',
    linkedin_login: 'linkedin/login',
    facebook_login: 'facebook/login',

    forgot_password: 'user/forgotPassword',
    check_reset_password: 'check/reset-password',
    reset_password: 'reset-password',

    update_account: 'user/update',
    change_password: 'user/change-password',
    get_my_dashboard: 'user/dashboard',
    get_profile: 'user/profile',

    get_subscription: 'subscription',
    request_custom_account: 'request/custom-account',
    add_subscription: 'subscription/add',
    update_subscription: 'subscription/update',
    resume_subscription: 'subscription/resume',
    cancel_subscription: 'subscription/cancel',
    apply_coupon: 'subscription/coupon-apply',

    add_sub_user: 'sub-user/add',
    get_sub_user: 'sub-user/view',
    edit_sub_user: 'sub-user/edit',
    delete_sub_user: 'sub-user/delete',


    get_clients: 'clients',
    add_clients: 'client/add',
    get_client: 'client/view',
    edit_clients: 'client/edit',
    delete_clients: 'client/delete',

    get_properties: 'properties',
    delete_property: 'property/delete',
    get_property: 'property',

    add_property_detail: 'property/add',
    get_stamp_duty: 'stamp-duty',
    add_stamp_duty: 'stamp-duty/add',
    cal_stamp_duty: 'calculate/stamp-duty',
    add_acquisition_costs: 'acquisition-cost/add',
    get_funding_structure: 'funding-structure',
    cal_loan_repayment: 'calculate/loan-repayment',
    cal_extra_repayment: 'calculate/extra-repayment',
    add_funding_structure: 'funding-structure/add',
    add_investment_outcomes: 'investment-outcome/add',
    cal_agent_commission: 'calculate/agent-commission',
    add_investment_expenses: 'investment-expense/add',
    add_economic_assumption: 'economic-assumption/add',
    cal_tax_benefits: 'calculate/tax-benefits',
    get_income_scales: 'income-tax',
    add_income_scales: 'income-tax/add',
    get_tax_offset: 'tax-offset',
    add_tax_offset: 'tax-offset/add',
    add_income: 'income/add',
    get_deduction: 'calculate/deduction',
    cal_depreciation_of_fittings: 'calculate/depreciation-of-fitting',
    add_deduction: 'deduction/add',
    get_who_pay: 'calculate/who-pay',
    add_cashflow: 'cashflow/add',

    upload_image: 'image/upload',
    delete_image: 'image/delete',
    get_base64_image: 'image/get',

    export_report: 'export/report',

    admin_dashboard: 'admin/dashboard',
    admin_add_user: 'admin/user/add',
    admin_get_user: 'admin/user/view',
    admin_edit_user: 'admin/user/edit',
    admin_delete_user: 'admin/user/delete',
    admin_user_list: 'admin/users-list',



    back_to_website: 'https://properlytics.com.au',
    support_url: 'https://support.properlytics.com.au',

    google_analytics_url: '',

    //api key
    session_time_out: 60, //in minutes

    map_key: 'AIzaSyDzJdgZ8tzEoxcZiBFgPJW8cMGAlTNrvIo',

    /* FB Data */
    fb_client_id: '243333569714785',
    fb_pixel_id: '460403397826774',

    /* Linkedin Data */
    linkedin_client_id: '86dchjfzc2rfvi',
    linkedin_redirect_uri: (env === 'local') ? local_data.linkedin_redirect_uri : live_data.linkedin_redirect_uri,

    /* Stripe Key */
    stripe_key: (env === 'local') ? local_data.stripe_key : live_data.stripe_key,

    //price control max length
    price_max_length: 15,
    percentage_max_length: 5,
    number_max_length: 3,

    who_pay_color: {
        you: '#00C880',
        tax_credit: '#C5DBCF',
        tenant: '#000000',
    },

    trial_days: 7,
    gst_percentage: 10,

    contact_no: '1300 30 80 23',

    subscription: {
        no: 'NO_SUBSCRIPTION',
        web: 'WEB_SUBSCRIPTION',
        ios: 'IOS_SUBSCRIPTION',
        android: 'ANDROID_SUBSCRIPTION',

        no_type: 'FREE_SUBSCRIPTION',
        personal_type: 'PRO_SUBSCRIPTION',
        business_type: 'BUSINESS_SUBSCRIPTION',

        month: 'MONTHLY_INTERVAL',
        year: 'YEARLY_INTERVAL',
    },

    //site urls
    hash: '#',

    site_url: {
        login: '/login',
        register: '/register',
        businessTrial: '/businesstrial',
        personalTrial: '/personaltrial',
        setup_profile: '/setup-profile',
        forgot_password: '/forgot-password',
        reset_password: '/reset-password',
        thank_you: '/thank-you',

        dashboard: '/dashboard', //welcome screen after login
        properties: '/properties',
        account: '/account',
        change_password: '/change-password',

        subscription: '/subscription',
        subscription_purchase: '/subscription/purchase',
        subscription_manage: '/subscription/manage',
        subscription_custom: '/subscription/custom',
        sub_user_add: '/sub-user/add',
        sub_user_edit: '/sub-user/edit',

        add_client: '/client/add',
        edit_client: '/client/edit',

        property_detail: '/property-detail',
        acquisition_cost: '/acquistion-detail',
        funding_structure: '/funding-strucuture',
        investment_outcome: '/investment-outcome',
        investment_expenses: '/investment-expenses',
        economic_assumption: '/economic-assumptions',
        income: '/income',
        deduction: '/deduction',
        cashflow: '/cashflow',


        admin_dashboard: '/admin/dashboard',
        admin_add_user: '/admin/user/add',
        admin_edit_user: '/admin/user/edit',
        admin_users: '/admin/users',
    },

    //numbers

    price_prefix: '$',
    percentage_suffix: '%',

    //app link

    ios_app: '#',
    android_app: '#',
};

export default config;