import config from '../config/config';
import {
    displayPropertySubType, getBuyerType, getDefaultCapitalGrowth, getDefaultInflation, getHouseType,
    getPropertyUsed, getRent, getSelectedYear, getState, getWhoPayYear, getDefaultLoanCosts, getLoanType,
    getDefaultAgentComissionRate, getDefaultDepreciationOfBuilding, getDefaultDiminishingValue, getDefaultDPLife,
    getDefaultDepreciationMethod
} from "./config-helper";
import moment from "moment/moment";

export function getHeaders() {
    return {
        headers: {
            Authorization: window.localStorage.getItem('app.token'),
        }
    };
}

export function storeInLocalStorage(profile) {
    window.localStorage.setItem('app.email', profile.email);
    window.localStorage.setItem('app.name', profile.name);
    window.localStorage.setItem('app.initials', profile.initials);
    window.localStorage.setItem('app.profile_pic', profile.profile_pic);
    window.localStorage.setItem('app.token', profile.token);
    window.localStorage.setItem('app.user_id', profile.user_id);
    window.localStorage.setItem('app.user_data', JSON.stringify(profile));
    window.localStorage.setItem('app.time_out', calculateTimeOut());
}

export function calculateTimeOut() {
    let timeout = (new Date()).getTime();
    timeout = timeout + (parseInt(config.session_time_out) * 60 * 1000);

    return timeout;
}

export function updateTimeOut() {
    let timeout = (new Date()).getTime();
    timeout = timeout + (parseInt(config.session_time_out) * 60 * 1000);
    window.localStorage.setItem('app.time_out', timeout);
}

export function checkTimeOut() {
    let timeOut = (new Date()).getTime() > parseInt(window.localStorage.getItem('app.time_out'));
    if (timeOut) {
        clearLocalStorage();
    }
    return timeOut;
}

export function getMyProfile() {
    if (window.localStorage.getItem('app.user_data')) {
        return JSON.parse(window.localStorage.getItem('app.user_data'));
    }
    return false;
}

export function canAddProperty(currentCountOfProperty, account_type) {
    if (parseInt(account_type) === 1) {
        if (window.localStorage.getItem('app.user_data')) {
            let data = JSON.parse(window.localStorage.getItem('app.user_data'));
            let max_allow = data.config.max_personal_property_limit;
            if (parseInt(max_allow) <= parseInt(currentCountOfProperty)) {
                return false;
            }
        }
    }
    return true;
}

export function getSubscriptionPageData() {
    if (window.localStorage.getItem('app.user_data')) {
        let data = JSON.parse(window.localStorage.getItem('app.user_data'));
        let redirect_to_dashboard = (!data.is_admin);
        let redirect_to_manage = (data.subscription_detail.subscription_type.toString() !== 'NO_SUBSCRIPTION');
        let redirect_to_purchase = (data.subscription_detail.subscription_type.toString() === 'WEB_SUBSCRIPTION' && data.subscription_detail.subscription_expire);
        let subscription_type = (data.subscription_detail.subscription_plan.toString() === 'BUSINESS_SUBSCRIPTION') ? 2 : 1;
        let subscription_interval = (data.subscription_detail.payment_interval.toString() === 'MONTHLY_INTERVAL') ? 1 : 2;
        let has_trial = !(redirect_to_purchase);
        return {
            has_trial: has_trial,
            redirect_to_dashboard: redirect_to_dashboard,
            redirect_to_manage: redirect_to_manage,
            redirect_to_purchase: redirect_to_purchase,
            subscription_type: subscription_type,
            subscription_interval: subscription_interval,
            subscription_interval_switch: (subscription_interval === 1),

            //plan prices
            personal_monthly: getPlanPrice(1, 1, 1),
            personal_yearly: getPlanPrice(1, 2, 1),
            business_monthly: getPlanPrice(2, 1, 1),
            business_yearly: getPlanPrice(2, 2, 1),
        };
    }
    return {};
}

export function isAdminUser() {
    if (window.localStorage.getItem('app.user_data')) {
        let data = JSON.parse(window.localStorage.getItem('app.user_data'));
        return (data.is_admin);
    }
    return false;
}

export function getPlanPrice(name, interval, qty = 1) {
    name = parseInt(name);
    interval = parseInt(interval);
    qty = parseInt(qty);
    if (window.localStorage.getItem('app.user_data')) {
        let data = JSON.parse(window.localStorage.getItem('app.user_data'));
        let plan = data.config.plan_pricing;
        for (let [key, item] of Object.entries(plan)) {
            if (key.toString() === 'personal' && name === 1) {
                if (interval === 2) {
                    return item.licence_1.yearly;
                } else {
                    return item.licence_1.monthly;
                }
            }

            if (key.toString() === 'business' && name === 2) {
                let items = {};
                if (qty === 1) {
                    items = item.licence_1;
                } else if (qty === 2) {
                    items = item.licence_2;
                } else if (qty === 3) {
                    items = item.licence_3;
                } else if (qty === 4) {
                    items = item.licence_4;
                } else if (qty === 5) {
                    items = item.licence_5;
                }

                for (let [key1, item1] of Object.entries(items)) {
                    if (key1.toString() === 'monthly' && interval === 1) {
                        return item1;
                    }

                    if (key1.toString() === 'yearly' && interval === 2) {
                        return item1;
                    }
                }
            }
        }
    }
    return 0;
}

export function getConfig() {
    if (window.localStorage.getItem('app.user_data')) {
        let data = JSON.parse(window.localStorage.getItem('app.user_data'));

        if (data.config) {
            return data.config;
        }
    }
    return false;
}

export function isLogin() {
    if ((!window.localStorage.getItem('app.token')) || checkTimeOut()) {
        return false;
    } else {
        return true;
    }
}

export function subscriptionExpired() {
    if (window.localStorage.getItem('app.user_data')) {
        let data = JSON.parse(window.localStorage.getItem('app.user_data'));

        if (typeof data.subscription_detail !== 'undefined') {
            if (typeof data.subscription_detail.subscription_expire !== 'undefined') {
                return (data.subscription_detail.subscription_expire);
            }
        }
    }
    return true;
}

export function clearLocalStorage() {
    window.localStorage.removeItem('app.email');
    window.localStorage.removeItem('app.name');
    window.localStorage.removeItem('app.initials');
    window.localStorage.removeItem('app.profile_pic');
    window.localStorage.removeItem('app.token');
    window.localStorage.removeItem('app.user_id');
    window.localStorage.removeItem('app.user_data');
    window.localStorage.removeItem('app.time_out');
    window.localStorage.removeItem('app.settings');
}

//return notification object
export const getNotificationObject = (type, message) => {
    let title = type;
    if (title === 'danger') {
        title = 'error';
    }

    return {
        title: title.charAt(0).toUpperCase() + title.slice(1),
        message: message,
        type: type,
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {duration: 4000},
        dismissable: {click: true}
    }
}

export const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",", displayNagativeSign = true) => {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        let negativeSign = "";
        if (displayNagativeSign && (amount < 0)) {
            negativeSign = "-";
        }

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
        return amount;
    }
};


export function convertToNumber(val) {
    val = val.replace("%", "").replace("$", "").replace(/,/g, '');
    return (val === '') ? 0 : parseFloat(val);
}

export const calculatePropertyStatus = (status_per) => {
    try {
        let status = (630 * status_per) / 100;
        return status.toFixed(0);
    } catch (e) {
        console.log(e)
        return status_per;
    }
};

export const getSettings = () => {
    let return_val = {
        client_id: '',
        year: '',
    }
    try {
        let settings = JSON.parse(window.localStorage.getItem('app.settings'));
        if (settings) {
            return settings;
        } else {
            return return_val;
        }
    } catch (e) {
        console.log(e)
        return return_val;
    }
};

export const getYearWiseValue = (values, year) => {
    try {
        year = parseInt(year);
        if (year === 5) {
            return values.yr5;
        } else if (year === 10) {
            return values.yr10;
        } else if (year === 15) {
            return values.yr15;
        } else {
            return 0;
        }
    } catch (e) {
        console.log(e)
        return 0;
    }
};

export const getOverviewMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",", displayNagativeSign = false) => {
    if (amount < 0) {
        //return '($' + formatMoney(amount, decimalCount, decimal, thousands, displayNagativeSign) + ')';
        return '-$' + formatMoney(amount, decimalCount, decimal, thousands, displayNagativeSign);
    } else {
        return '$' + formatMoney(amount, decimalCount, decimal, thousands, displayNagativeSign);
    }
};

export const getWhoPayMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",", displayNagativeSign = false) => {
    if (amount < 0) {
        return '-$' + formatMoney(amount, decimalCount, decimal, thousands, displayNagativeSign);
    } else {
        return '+$' + formatMoney(amount, decimalCount, decimal, thousands, displayNagativeSign);
    }
};

export const dataURLtoFile = (dataUrl) => {
    return base64ToBlob(dataUrl);
}

export const base64ToBlob = (dataurl) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1]
    const sliceSize = 1024;
    const byteChars = window.atob(arr[1]);
    const byteArrays = [];

    for (let offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
        let slice = byteChars.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new File(byteArrays, getFilename(dataurl),{type: mime});
}

export const getFilename = (dataUrl) => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];

    return Math.round(+new Date()/1000) + '.' + mime.split('/').pop();
}

export const setPropertyTabState = (property) => {

    let state = {};
    if (property.property_detail) {
        let prop = property.property_detail;
        let fun = property.funding_structure;
        state = {
            property_id: prop.property_id,
            client_id: prop.client_id,
            property_address: prop.address,
            latitude: parseFloat(prop.latitude),
            longitude: parseFloat(prop.longitude),
            property_type: prop.property_type,
            bedrooms: prop.bedrooms,
            bathrooms: prop.bathrooms,
            car_space: prop.car_space,
            year_build: prop.year_build,
            builder_name: prop.builder_name,
            purchase_price: prop.purchase_price,
            display_sub_type: displayPropertySubType(prop.property_type),
            property_sub_type: prop.property_sub_type,
            land_price: prop.land_price,
            build_price: prop.build_price,
            adv_link: prop.adv_link,


            loan_amount: (typeof fun.loan_amount !== 'undefined') ? fun.loan_amount : 0,
            additional_loan_amount: (typeof fun.additional_loan_amount !== 'undefined') ? fun.additional_loan_amount : 0,
            loan_type: (typeof fun.loan_type !== 'undefined') ? fun.loan_type : getLoanType().value,
            interest_rate: (typeof fun.interest_rate !== 'undefined') ? fun.interest_rate : 0,
            to_year: (typeof fun.to_year !== 'undefined') ? fun.to_year : 40,
            loan_amount_back: (typeof fun.loan_amount_back !== 'undefined') ? fun.loan_amount_back : 0,
            additional_loan_amount_back: (typeof fun.additional_loan_amount_back !== 'undefined') ? fun.additional_loan_amount_back : 0,
            loan_type_back: (typeof fun.loan_type_back !== 'undefined') ? fun.loan_type_back : getLoanType().value,
            interest_rate_back: (typeof fun.interest_rate_back !== 'undefined') ? fun.interest_rate_back : 0,
            to_year_back: (typeof fun.to_year_back !== 'undefined') ? fun.to_year_back : 40,

            progress: property.complete_status,

            images: property.images,
            overview: property.overview,

            property: property,
        }
    }

    return state;
};

export const setAcquisitionTabState = (property) => {
    let state = {};
    if (property.property_detail) {
        let prop = property.property_detail;
        let fun = property.funding_structure;
        state = {
            property_id: prop.property_id,
            property_address: prop.address,
            latitude: parseFloat(prop.latitude),
            longitude: parseFloat(prop.longitude),
            bedrooms: prop.bedrooms,
            bathrooms: prop.bathrooms,
            car_space: prop.car_space,
            purchase_price: prop.purchase_price,
            adv_link: prop.adv_link,

            loan_amount: (typeof fun.loan_amount !== 'undefined') ? fun.loan_amount : 0,
            additional_loan_amount: (typeof fun.additional_loan_amount !== 'undefined') ? fun.additional_loan_amount : 0,
            loan_type: (typeof fun.loan_type !== 'undefined') ? fun.loan_type : getLoanType().value,
            interest_rate: (typeof fun.interest_rate !== 'undefined') ? fun.interest_rate : 0,
            to_year: (typeof fun.to_year !== 'undefined') ? fun.to_year : 40,
            loan_amount_back: (typeof fun.loan_amount_back !== 'undefined') ? fun.loan_amount_back : 0,
            additional_loan_amount_back: (typeof fun.additional_loan_amount_back !== 'undefined') ? fun.additional_loan_amount_back : 0,
            loan_type_back: (typeof fun.loan_type_back !== 'undefined') ? fun.loan_type_back : getLoanType().value,
            interest_rate_back: (typeof fun.interest_rate_back !== 'undefined') ? fun.interest_rate_back : 0,
            to_year_back: (typeof fun.to_year_back !== 'undefined') ? fun.to_year_back : 40,

            conveyancing_cost: (typeof property.acquisition_cost.conveyancing_cost !== 'undefined') ? property.acquisition_cost.conveyancing_cost : '',
            house_type: (typeof property.acquisition_cost.house_type !== 'undefined') ? property.acquisition_cost.house_type : getHouseType().value,
            property_used: (typeof property.acquisition_cost.property_used !== 'undefined') ? property.acquisition_cost.property_used : getPropertyUsed().value,
            buyer_type: (typeof property.acquisition_cost.buyer_type !== 'undefined') ? property.acquisition_cost.buyer_type : getBuyerType().value,
            stamp_duty: (typeof property.acquisition_cost.stamp_duty !== 'undefined') ? property.acquisition_cost.stamp_duty : 0,
            state: (typeof property.acquisition_cost.state_id !== 'undefined' && property.acquisition_cost.state_id !== '') ? property.acquisition_cost.state_id : getState().value,
            transfer_of_title: (typeof property.acquisition_cost.transfer_of_title !== 'undefined') ? property.acquisition_cost.transfer_of_title : '',
            other_costs: (typeof property.acquisition_cost.other_costs !== 'undefined') ? property.acquisition_cost.other_costs : [],
            furnitures_value: (typeof property.acquisition_cost.furniture_value !== 'undefined') ? property.acquisition_cost.furniture_value : '',

            progress: property.complete_status,

            images: property.images,
            overview: property.overview,

            property: property,
        }
    }

    return state;
};

export const setFundingTabState = (property) => {
    let state = {};
    if (property.property_detail) {
        const defaultLoanCosts = getDefaultLoanCosts();
        let prop = property.property_detail;
        let fun = property.funding_structure;
        state = {
            property_id: prop.property_id,
            property_address: prop.address,
            latitude: parseFloat(prop.latitude),
            longitude: parseFloat(prop.longitude),
            bedrooms: prop.bedrooms,
            bathrooms: prop.bathrooms,
            car_space: prop.car_space,
            purchase_price: prop.purchase_price,
            adv_link: prop.adv_link,

            invested_cash: (typeof fun.invested_cash !== 'undefined') ? fun.invested_cash : false,
            deposite_cash: (typeof fun.deposite_cash !== 'undefined') ? fun.deposite_cash : 0,
            front_end_loan: (typeof fun.front_end_loan !== 'undefined') ? fun.front_end_loan : false,
            loan_amount: (typeof fun.loan_amount !== 'undefined') ? fun.loan_amount : 0,
            lender_name: (typeof fun.lender_name !== 'undefined') ? fun.lender_name : '',
            loan_type: (typeof fun.loan_type !== 'undefined') ? fun.loan_type : getLoanType().value,
            interest_rate: (typeof fun.interest_rate !== 'undefined') ? fun.interest_rate : 0,
            from_year: (typeof fun.from_year !== 'undefined') ? fun.from_year : 1,
            to_year: (typeof fun.to_year !== 'undefined') ? fun.to_year : '',
            establishment_fees: (typeof fun.establishment_fees !== 'undefined') ? fun.establishment_fees : 0,
            mortgage_insurance: (typeof fun.mortgage_insurance !== 'undefined') ? fun.mortgage_insurance : 0,
            loan_repayment: (typeof fun.loan_repayment !== 'undefined') ? fun.loan_repayment : 0,
            additional_loan_amount: (typeof fun.additional_loan_amount !== 'undefined') ? fun.additional_loan_amount : 0,
            back_end_loan: (typeof fun.back_end_loan !== 'undefined') ? fun.back_end_loan : false,
            loan_amount_back: (typeof fun.loan_amount_back !== 'undefined') ? fun.loan_amount_back : 0,
            lender_name_back: (typeof fun.lender_name_back !== 'undefined') ? fun.lender_name_back : '',
            loan_type_back: (typeof fun.loan_type_back !== 'undefined') ? fun.loan_type_back : getLoanType().value,
            interest_rate_back: (typeof fun.interest_rate_back !== 'undefined') ? fun.interest_rate_back : 0,
            from_year_back: (typeof fun.from_year_back !== 'undefined') ? fun.from_year_back : 1,
            to_year_back: (typeof fun.to_year_back !== 'undefined') ? fun.to_year_back : '',
            establishment_fees_back: (typeof fun.establishment_fees_back !== 'undefined') ? fun.establishment_fees_back : 0,
            mortgage_insurance_back: (typeof fun.mortgage_insurance_back !== 'undefined') ? fun.mortgage_insurance_back : 0,
            loan_repayment_back: (typeof fun.loan_repayment_back !== 'undefined') ? fun.loan_repayment_back : 0,
            additional_loan_amount_back: (typeof fun.additional_loan_amount_back !== 'undefined') ? fun.additional_loan_amount_back : 0,
            other_costs: (typeof fun.other_costs !== 'undefined') ? fun.other_costs : [],
            morgagees_fees: (typeof fun.loan_costs !== 'undefined') ? fun.loan_costs.morgagees_fees : defaultLoanCosts.morgagees_fees,
            valuation_fees: (typeof fun.loan_costs !== 'undefined') ? fun.loan_costs.valuation_fees : defaultLoanCosts.valuation_fees,
            reg_morgage_1: (typeof fun.loan_costs !== 'undefined') ? fun.loan_costs.reg_morgage_1 : defaultLoanCosts.reg_morgage_1,
            /*reg_morgage_2: (typeof fun.loan_costs !== 'undefined') ? fun.loan_costs.reg_morgage_2 : defaultLoanCosts.reg_morgage_2,*/
            reg_of_title: (typeof fun.loan_costs !== 'undefined') ? fun.loan_costs.reg_of_title : defaultLoanCosts.reg_of_title,
            search_fees: (typeof fun.loan_costs !== 'undefined') ? fun.loan_costs.search_fees : defaultLoanCosts.search_fees,


            progress: property.complete_status,

            images: property.images,
            overview: property.overview,

            property: property,
        }
    }

    return state;
};

export const setOutcomesTabState = (property) => {
    let state = {};
    if (property.property_detail) {
        let prop = property.property_detail;
        let out = property.investment_outcome;
        let fun = property.funding_structure;
        state = {
            property_id: prop.property_id,
            property_address: prop.address,
            latitude: parseFloat(prop.latitude),
            longitude: parseFloat(prop.longitude),
            bedrooms: prop.bedrooms,
            bathrooms: prop.bathrooms,
            car_space: prop.car_space,
            purchase_price: prop.purchase_price,
            adv_link: prop.adv_link,

            loan_amount: (typeof fun.loan_amount !== 'undefined') ? fun.loan_amount : 0,
            additional_loan_amount: (typeof fun.additional_loan_amount !== 'undefined') ? fun.additional_loan_amount : 0,
            loan_type: (typeof fun.loan_type !== 'undefined') ? fun.loan_type : getLoanType().value,
            interest_rate: (typeof fun.interest_rate !== 'undefined') ? fun.interest_rate : 0,
            to_year: (typeof fun.to_year !== 'undefined') ? fun.to_year : 40,
            loan_amount_back: (typeof fun.loan_amount_back !== 'undefined') ? fun.loan_amount_back : 0,
            additional_loan_amount_back: (typeof fun.additional_loan_amount_back !== 'undefined') ? fun.additional_loan_amount_back : 0,
            loan_type_back: (typeof fun.loan_type_back !== 'undefined') ? fun.loan_type_back : getLoanType().value,
            interest_rate_back: (typeof fun.interest_rate_back !== 'undefined') ? fun.interest_rate_back : 0,
            to_year_back: (typeof fun.to_year_back !== 'undefined') ? fun.to_year_back : 40,

            rent_amount: (typeof out.rent_amount !== 'undefined') ? out.rent_amount : '',
            rent_duration: (typeof out.rent_duration !== 'undefined') ? out.rent_duration : getRent(52).value,
            potential_yearly_return: (typeof out.potential_yearly_return !== 'undefined') ? out.potential_yearly_return : '',
            vacancy_rate: (typeof out.vacancy_rate !== 'undefined') ? out.vacancy_rate : '',
            actual_yearly_rent: (typeof out.actual_yearly_rent !== 'undefined') ? out.actual_yearly_rent : '',
            gross_percent: (typeof out.gross_percent !== 'undefined') ? out.gross_percent : '',


            progress: property.complete_status,

            images: property.images,
            overview: property.overview,

            property: property,
        }
    }

    return state;
};

export const setExpensesTabState = (property) => {
    let state = {};
    if (property.property_detail) {
        let prop = property.property_detail;
        let exp = property.investment_expense;
        let out = property.investment_outcome;
        let fun = property.funding_structure;

        let potential_yearly_return = (typeof out.potential_yearly_return !== 'undefined') ? out.potential_yearly_return : 0;
        let letting_fee = 0;
        if (potential_yearly_return !== 0) {
            letting_fee = potential_yearly_return / 52;
        }
        state = {
            property_id: prop.property_id,
            property_address: prop.address,
            latitude: parseFloat(prop.latitude),
            longitude: parseFloat(prop.longitude),
            bedrooms: prop.bedrooms,
            bathrooms: prop.bathrooms,
            car_space: prop.car_space,
            purchase_price: prop.purchase_price,
            adv_link: prop.adv_link,

            loan_amount: (typeof fun.loan_amount !== 'undefined') ? fun.loan_amount : 0,
            additional_loan_amount: (typeof fun.additional_loan_amount !== 'undefined') ? fun.additional_loan_amount : 0,
            loan_type: (typeof fun.loan_type !== 'undefined') ? fun.loan_type : getLoanType().value,
            interest_rate: (typeof fun.interest_rate !== 'undefined') ? fun.interest_rate : 0,
            to_year: (typeof fun.to_year !== 'undefined') ? fun.to_year : 40,
            loan_amount_back: (typeof fun.loan_amount_back !== 'undefined') ? fun.loan_amount_back : 0,
            additional_loan_amount_back: (typeof fun.additional_loan_amount_back !== 'undefined') ? fun.additional_loan_amount_back : 0,
            loan_type_back: (typeof fun.loan_type_back !== 'undefined') ? fun.loan_type_back : getLoanType().value,
            interest_rate_back: (typeof fun.interest_rate_back !== 'undefined') ? fun.interest_rate_back : 0,
            to_year_back: (typeof fun.to_year_back !== 'undefined') ? fun.to_year_back : 40,

            agent_commission: (typeof exp.agent_commission !== 'undefined') ? exp.agent_commission : 0,
            agent_commision_per: (typeof exp.agent_commision_per !== 'undefined') ? exp.agent_commision_per : getDefaultAgentComissionRate(),
            letting_fee: letting_fee,
            rates: (typeof exp.rates !== 'undefined') ? exp.rates : 0,
            has_building_ins: (typeof exp.has_building_ins !== 'undefined') ? exp.has_building_ins : false,
            building_ins_name: (typeof exp.building_ins_name !== 'undefined') ? exp.building_ins_name : '',
            building_ins_amount: (typeof exp.building_ins_amount !== 'undefined') ? exp.building_ins_amount : 0,
            has_landlord_ins: (typeof exp.has_landlord_ins !== 'undefined') ? exp.has_landlord_ins : false,
            landlord_ins_name: (typeof exp.landlord_ins_name !== 'undefined') ? exp.landlord_ins_name : '',
            landlord_ins_amount: (typeof exp.landlord_ins_amount !== 'undefined') ? exp.landlord_ins_amount : 0,
            maintenance: (typeof exp.maintenance !== 'undefined') ? exp.maintenance : 0,
            body_corporate_amount: (typeof exp.body_corporate_amount !== 'undefined') ? exp.body_corporate_amount : 0,
            has_other_expenses: (typeof exp.has_other_expenses !== 'undefined') ? exp.has_other_expenses : false,
            cleaning: (typeof exp.cleaning !== 'undefined') ? exp.cleaning : 0,
            post_control: (typeof exp.post_control !== 'undefined') ? exp.post_control : 0,
            mowing: (typeof exp.mowing !== 'undefined') ? exp.mowing : 0,
            other_expenses: (typeof exp.other_expenses !== 'undefined') ? exp.other_expenses : 0,
            total_expenses: (typeof exp.total_expenses !== 'undefined') ? exp.total_expenses : 0,


            progress: property.complete_status,

            images: property.images,
            overview: property.overview,

            property: property,
        }
    }

    return state;
};

export const setEconomicTabState = (property) => {
    let state = {};
    if (property.property_detail) {
        let prop = property.property_detail;
        let eco = property.economic_assumption;
        let fun = property.funding_structure;
        //rental expense
        let has_rental_income = true;
        if (typeof eco.has_rental_income !== 'undefined') {
            has_rental_income = eco.has_rental_income
        } else if (typeof eco.rental_income !== 'undefined') {
            has_rental_income = eco.rental_income
        }

        state = {
            property_id: prop.property_id,
            property_address: prop.address,
            latitude: parseFloat(prop.latitude),
            longitude: parseFloat(prop.longitude),
            bedrooms: prop.bedrooms,
            bathrooms: prop.bathrooms,
            car_space: prop.car_space,
            purchase_price: prop.purchase_price,
            adv_link: prop.adv_link,

            loan_amount: (typeof fun.loan_amount !== 'undefined') ? fun.loan_amount : 0,
            additional_loan_amount: (typeof fun.additional_loan_amount !== 'undefined') ? fun.additional_loan_amount : 0,
            loan_type: (typeof fun.loan_type !== 'undefined') ? fun.loan_type : getLoanType().value,
            interest_rate: (typeof fun.interest_rate !== 'undefined') ? fun.interest_rate : 0,
            to_year: (typeof fun.to_year !== 'undefined') ? fun.to_year : 40,
            loan_amount_back: (typeof fun.loan_amount_back !== 'undefined') ? fun.loan_amount_back : 0,
            additional_loan_amount_back: (typeof fun.additional_loan_amount_back !== 'undefined') ? fun.additional_loan_amount_back : 0,
            loan_type_back: (typeof fun.loan_type_back !== 'undefined') ? fun.loan_type_back : getLoanType().value,
            interest_rate_back: (typeof fun.interest_rate_back !== 'undefined') ? fun.interest_rate_back : 0,
            to_year_back: (typeof fun.to_year_back !== 'undefined') ? fun.to_year_back : 40,

            capital_growth_rate: (typeof eco.capital_growth_rate !== 'undefined') ? eco.capital_growth_rate : getDefaultCapitalGrowth(),
            inflation: (typeof eco.inflation !== 'undefined') ? eco.inflation : getDefaultInflation(),
            year1: (typeof eco.year1 !== 'undefined') ? eco.year1 : '',
            year2: (typeof eco.year2 !== 'undefined') ? eco.year2 : '',
            year3: (typeof eco.year3 !== 'undefined') ? eco.year3 : '',
            year4: (typeof eco.year4 !== 'undefined') ? eco.year4 : '',
            year5: (typeof eco.year5 !== 'undefined') ? eco.year5 : '',
            selected_year: (typeof eco.selected_year !== 'undefined') ? eco.selected_year : getSelectedYear().value,
            has_rental_income: has_rental_income,
            texable_income: (typeof eco.texable_income !== 'undefined') ? eco.texable_income : true,
            living_expenses: (typeof eco.living_expenses !== 'undefined') ? eco.living_expenses : true,

            progress: property.complete_status,

            images: property.images,
            overview: property.overview,

            property: property,
        }
    }

    return state;
};

export const setDeductionTabState = (property) => {
    let state = {};
    if (property.property_detail) {
        let prop = property.property_detail;
        let ded = property.deduction;
        let fun = property.funding_structure;
        let dp = (typeof ded.depreciation_fitting_details !== 'undefined') ? ded.depreciation_fitting_details : {};
        let lifes = getDefaultDPLife();
        state = {
            property_id: prop.property_id,
            property_address: prop.address,
            latitude: parseFloat(prop.latitude),
            longitude: parseFloat(prop.longitude),
            bedrooms: prop.bedrooms,
            bathrooms: prop.bathrooms,
            car_space: prop.car_space,
            purchase_price: prop.purchase_price,
            adv_link: prop.adv_link,

            loan_amount: (typeof fun.loan_amount !== 'undefined') ? fun.loan_amount : 0,
            additional_loan_amount: (typeof fun.additional_loan_amount !== 'undefined') ? fun.additional_loan_amount : 0,
            loan_type: (typeof fun.loan_type !== 'undefined') ? fun.loan_type : getLoanType().value,
            interest_rate: (typeof fun.interest_rate !== 'undefined') ? fun.interest_rate : 0,
            to_year: (typeof fun.to_year !== 'undefined') ? fun.to_year : 40,
            loan_amount_back: (typeof fun.loan_amount_back !== 'undefined') ? fun.loan_amount_back : 0,
            additional_loan_amount_back: (typeof fun.additional_loan_amount_back !== 'undefined') ? fun.additional_loan_amount_back : 0,
            loan_type_back: (typeof fun.loan_type_back !== 'undefined') ? fun.loan_type_back : getLoanType().value,
            interest_rate_back: (typeof fun.interest_rate_back !== 'undefined') ? fun.interest_rate_back : 0,
            to_year_back: (typeof fun.to_year_back !== 'undefined') ? fun.to_year_back : 40,

            cash_deduction: (typeof ded.cash_deduction !== 'undefined') ? ded.cash_deduction : 0,
            depreciation_of_building: (typeof ded.depreciation_of_building !== 'undefined') ? ded.depreciation_of_building : getDefaultDepreciationOfBuilding(),
            building_cost: (typeof ded.building_cost !== 'undefined') ? ded.building_cost : 0,
            depreciation_amount: (typeof ded.depreciation_amount !== 'undefined') ? ded.depreciation_amount : 0,
            depreciation_fitting: (typeof ded.depreciation_fitting !== 'undefined') ? ded.depreciation_fitting : 0,
            loan_cost: (typeof ded.loan_cost !== 'undefined') ? ded.loan_cost : 0,
            //dp
            furniture_value: (typeof dp.furniture_value !== 'undefined') ? dp.furniture_value : 0,
            furniture_life: (typeof dp.furniture_life !== 'undefined') ? dp.furniture_life : lifes.furniture_life,
            general_value: (typeof dp.general_value !== 'undefined') ? dp.general_value : 0,
            general_life: (typeof dp.general_life !== 'undefined') ? dp.general_life : lifes.general_life,
            curtains_value: (typeof dp.curtains_value !== 'undefined') ? dp.curtains_value : 0,
            curtains_life: (typeof dp.curtains_life !== 'undefined') ? dp.curtains_life : lifes.curtains_life,
            carpets_value: (typeof dp.carpets_value !== 'undefined') ? dp.carpets_value : 0,
            carpets_life: (typeof dp.carpets_life !== 'undefined') ? dp.carpets_life : lifes.carpets_life,
            hot_water_sys_value: (typeof dp.hot_water_sys_value !== 'undefined') ? dp.hot_water_sys_value : 0,
            hot_water_sys_life: (typeof dp.hot_water_sys_life !== 'undefined') ? dp.hot_water_sys_life : lifes.hot_water_sys_life,
            lawn_mowor_value: (typeof dp.lawn_mowor_value !== 'undefined') ? dp.lawn_mowor_value : 0,
            lawn_mowor_life: (typeof dp.lawn_mowor_life !== 'undefined') ? dp.lawn_mowor_life : lifes.lawn_mowor_life,
            refrigerator_value: (typeof dp.refrigerator_value !== 'undefined') ? dp.refrigerator_value : 0,
            refrigerator_life: (typeof dp.refrigerator_life !== 'undefined') ? dp.refrigerator_life : lifes.refrigerator_life,
            stove_value: (typeof dp.stove_value !== 'undefined') ? dp.stove_value : 0,
            stove_life: (typeof dp.stove_life !== 'undefined') ? dp.stove_life : lifes.stove_life,
            law_value_pool_value: (typeof dp.law_value_pool_value !== 'undefined') ? dp.law_value_pool_value : 0,
            law_value_pool_life: (typeof dp.law_value_pool_life !== 'undefined') ? dp.law_value_pool_life : lifes.law_value_pool_life,
            total: (typeof dp.total !== 'undefined') ? dp.total : 0,
            year_one_depreciation: (typeof dp.year_one_depreciation !== 'undefined') ? dp.year_one_depreciation : 0,
            depreciation_method: (typeof dp.depreciation_method !== 'undefined') ? dp.depreciation_method : getDefaultDepreciationMethod(),
            diminishing_value: (typeof dp.diminishing_value !== 'undefined') ? dp.diminishing_value : getDefaultDiminishingValue(),


            progress: property.complete_status,

            images: property.images,
            overview: property.overview,

            property: property,
        }
    }

    return state;
};

export const setIncomeTabState = (property) => {
    let state = {};
    if (property.property_detail) {
        let prop = property.property_detail;
        let fun = property.funding_structure;
        let inc = property.income;
        let tb = (typeof property.income.tax_benefits !== 'undefined') ? property.income.tax_benefits : {};
        state = {
            property_id: prop.property_id,
            property_address: prop.address,
            latitude: parseFloat(prop.latitude),
            longitude: parseFloat(prop.longitude),
            bedrooms: prop.bedrooms,
            bathrooms: prop.bathrooms,
            car_space: prop.car_space,
            purchase_price: prop.purchase_price,
            adv_link: prop.adv_link,

            loan_amount: (typeof fun.loan_amount !== 'undefined') ? fun.loan_amount : 0,
            additional_loan_amount: (typeof fun.additional_loan_amount !== 'undefined') ? fun.additional_loan_amount : 0,
            loan_type: (typeof fun.loan_type !== 'undefined') ? fun.loan_type : getLoanType().value,
            interest_rate: (typeof fun.interest_rate !== 'undefined') ? fun.interest_rate : 0,
            to_year: (typeof fun.to_year !== 'undefined') ? fun.to_year : 40,
            loan_amount_back: (typeof fun.loan_amount_back !== 'undefined') ? fun.loan_amount_back : 0,
            additional_loan_amount_back: (typeof fun.additional_loan_amount_back !== 'undefined') ? fun.additional_loan_amount_back : 0,
            loan_type_back: (typeof fun.loan_type_back !== 'undefined') ? fun.loan_type_back : getLoanType().value,
            interest_rate_back: (typeof fun.interest_rate_back !== 'undefined') ? fun.interest_rate_back : 0,
            to_year_back: (typeof fun.to_year_back !== 'undefined') ? fun.to_year_back : 40,


            is_joint: (typeof inc.is_joint !== 'undefined') ? inc.is_joint : false,
            investor: (typeof inc.investor !== 'undefined') ? inc.investor : 100,
            partner: (typeof inc.partner !== 'undefined') ? inc.partner : 0,
            i_salary: (typeof inc.i_salary !== 'undefined') ? inc.i_salary : 0,
            p_salary: (typeof inc.p_salary !== 'undefined') ? inc.p_salary : 0,
            salary: (typeof inc.salary !== 'undefined') ? inc.salary : 0,
            i_other_income: (typeof inc.i_other_income !== 'undefined') ? inc.i_other_income : 0,
            p_other_income: (typeof inc.p_other_income !== 'undefined') ? inc.p_other_income : 0,
            other_income: (typeof inc.other_income !== 'undefined') ? inc.other_income : 0,
            i_work_expenses: (typeof inc.i_work_expenses !== 'undefined') ? inc.i_work_expenses : 0,
            p_work_expenses: (typeof inc.p_work_expenses !== 'undefined') ? inc.p_work_expenses : 0,
            work_expenses: (typeof inc.work_expenses !== 'undefined') ? inc.work_expenses : 0,
            i_other_expenses: (typeof inc.i_other_expenses !== 'undefined') ? inc.i_other_expenses : 0,
            p_other_expenses: (typeof inc.p_other_expenses !== 'undefined') ? inc.p_other_expenses : 0,
            other_expenses: (typeof inc.other_expenses !== 'undefined') ? inc.other_expenses : 0,
            i_tax_offset: (typeof inc.i_tax_offset !== 'undefined') ? inc.i_tax_offset : 0,
            p_tax_offset: (typeof inc.p_tax_offset !== 'undefined') ? inc.p_tax_offset : 0,
            tax_offset: (typeof inc.tax_offset !== 'undefined') ? inc.tax_offset : 0,
            i_nt_income: (typeof inc.i_nt_income !== 'undefined') ? inc.i_nt_income : 0,
            p_nt_income: (typeof inc.p_nt_income !== 'undefined') ? inc.p_nt_income : 0,
            nt_income: (typeof inc.nt_income !== 'undefined') ? inc.nt_income : 0,
            i_non_taxable: (typeof inc.i_non_taxable !== 'undefined') ? inc.i_non_taxable : 0,
            p_non_taxable: (typeof inc.p_non_taxable !== 'undefined') ? inc.p_non_taxable : 0,
            non_taxable: (typeof inc.non_taxable !== 'undefined') ? inc.non_taxable : 0,
            total_net_income: (typeof inc.total_net_income !== 'undefined') ? inc.total_net_income : 0,

            //tb
            i_income: (typeof tb.i_income !== 'undefined') ? tb.i_income : 0,
            p_income: (typeof tb.p_income !== 'undefined') ? tb.p_income : 0,
            income: (typeof tb.income !== 'undefined') ? tb.income : 0,
            i_rental_income: (typeof tb.i_rental_income !== 'undefined') ? tb.i_rental_income : 0,
            p_rental_income: (typeof tb.p_rental_income !== 'undefined') ? tb.p_rental_income : 0,
            rental_income: (typeof tb.rental_income !== 'undefined') ? tb.rental_income : 0,
            i_total_income: (typeof tb.i_total_income !== 'undefined') ? tb.i_total_income : 0,
            p_total_income: (typeof tb.p_total_income !== 'undefined') ? tb.p_total_income : 0,
            total_income: (typeof tb.total_income !== 'undefined') ? tb.total_income : 0,
            i_rental_deduction: (typeof tb.i_rental_deduction !== 'undefined') ? tb.i_rental_deduction : 0,
            p_rental_deduction: (typeof tb.p_rental_deduction !== 'undefined') ? tb.p_rental_deduction : 0,
            rental_deduction: (typeof tb.rental_deduction !== 'undefined') ? tb.rental_deduction : 0,
            i_new_tax_income: (typeof tb.i_new_tax_income !== 'undefined') ? tb.i_new_tax_income : 0,
            p_new_tax_income: (typeof tb.p_new_tax_income !== 'undefined') ? tb.p_new_tax_income : 0,
            new_tax_income: (typeof tb.new_tax_income !== 'undefined') ? tb.new_tax_income : 0,
            i_present_tax: (typeof tb.i_present_tax !== 'undefined') ? tb.i_present_tax : 0,
            p_present_tax: (typeof tb.p_present_tax !== 'undefined') ? tb.p_present_tax : 0,
            present_tax: (typeof tb.present_tax !== 'undefined') ? tb.present_tax : 0,
            i_new_tax: (typeof tb.i_new_tax !== 'undefined') ? tb.i_new_tax : 0,
            p_new_tax: (typeof tb.p_new_tax !== 'undefined') ? tb.p_new_tax : 0,
            new_tax: (typeof tb.new_tax !== 'undefined') ? tb.new_tax : 0,
            i_tax_saving: (typeof inc.i_tax_offset !== 'undefined') ? inc.i_tax_offset : 0,
            p_tax_saving: (typeof inc.p_tax_offset !== 'undefined') ? inc.p_tax_offset : 0,
            tax_saving: (typeof tb.tax_saving !== 'undefined') ? tb.tax_saving : 0,
            it_type: (typeof tb.it_type !== 'undefined') ? tb.it_type : 1,


            progress: property.complete_status,

            images: property.images,
            overview: property.overview,

            property: property,
        }
    }

    return state;
};

export const setTaxBenefitState = (tb) => {
    let state = {};
    if (tb) {
        state = {
            i_tax_offset: (typeof tb.i_tax_offset !== 'undefined') ? tb.i_tax_offset : 0,
            p_tax_offset: (typeof tb.p_tax_offset !== 'undefined') ? tb.p_tax_offset : 0,
            tax_offset: (typeof tb.tax_offset !== 'undefined') ? tb.tax_offset : 0,

            //tb
            i_income: (typeof tb.i_income !== 'undefined') ? tb.i_income : 0,
            p_income: (typeof tb.p_income !== 'undefined') ? tb.p_income : 0,
            income: (typeof tb.income !== 'undefined') ? tb.income : 0,
            i_rental_income: (typeof tb.i_rental_income !== 'undefined') ? tb.i_rental_income : 0,
            p_rental_income: (typeof tb.p_rental_income !== 'undefined') ? tb.p_rental_income : 0,
            rental_income: (typeof tb.rental_income !== 'undefined') ? tb.rental_income : 0,
            i_total_income: (typeof tb.i_total_income !== 'undefined') ? tb.i_total_income : 0,
            p_total_income: (typeof tb.p_total_income !== 'undefined') ? tb.p_total_income : 0,
            total_income: (typeof tb.total_income !== 'undefined') ? tb.total_income : 0,
            i_rental_deduction: (typeof tb.i_rental_deduction !== 'undefined') ? tb.i_rental_deduction : 0,
            p_rental_deduction: (typeof tb.p_rental_deduction !== 'undefined') ? tb.p_rental_deduction : 0,
            rental_deduction: (typeof tb.rental_deduction !== 'undefined') ? tb.rental_deduction : 0,
            i_new_tax_income: (typeof tb.i_new_tax_income !== 'undefined') ? tb.i_new_tax_income : 0,
            p_new_tax_income: (typeof tb.p_new_tax_income !== 'undefined') ? tb.p_new_tax_income : 0,
            new_tax_income: (typeof tb.new_tax_income !== 'undefined') ? tb.new_tax_income : 0,
            i_present_tax: (typeof tb.i_present_tax !== 'undefined') ? tb.i_present_tax : 0,
            p_present_tax: (typeof tb.p_present_tax !== 'undefined') ? tb.p_present_tax : 0,
            present_tax: (typeof tb.present_tax !== 'undefined') ? tb.present_tax : 0,
            i_new_tax: (typeof tb.i_new_tax !== 'undefined') ? tb.i_new_tax : 0,
            p_new_tax: (typeof tb.p_new_tax !== 'undefined') ? tb.p_new_tax : 0,
            new_tax: (typeof tb.new_tax !== 'undefined') ? tb.new_tax : 0,
            i_tax_saving: (typeof tb.i_tax_offset !== 'undefined') ? tb.i_tax_offset : 0,
            p_tax_saving: (typeof tb.p_tax_offset !== 'undefined') ? tb.p_tax_offset : 0,
            tax_saving: (typeof tb.tax_saving !== 'undefined') ? tb.tax_saving : 0,
        }
    }

    return state;
};

export const setCashflowTabState = (property) => {
    let state = {};
    if (property.property_detail) {
        let prop = property.property_detail;
        let fun = property.funding_structure;
        state = {
            property_id: prop.property_id,
            property_address: prop.address,
            latitude: parseFloat(prop.latitude),
            longitude: parseFloat(prop.longitude),
            bedrooms: prop.bedrooms,
            bathrooms: prop.bathrooms,
            car_space: prop.car_space,
            purchase_price: prop.purchase_price,
            adv_link: prop.adv_link,

            loan_amount: (typeof fun.loan_amount !== 'undefined') ? fun.loan_amount : 0,
            additional_loan_amount: (typeof fun.additional_loan_amount !== 'undefined') ? fun.additional_loan_amount : 0,
            loan_type: (typeof fun.loan_type !== 'undefined') ? fun.loan_type : getLoanType().value,
            interest_rate: (typeof fun.interest_rate !== 'undefined') ? fun.interest_rate : 0,
            to_year: (typeof fun.to_year !== 'undefined') ? fun.to_year : 40,
            loan_amount_back: (typeof fun.loan_amount_back !== 'undefined') ? fun.loan_amount_back : 0,
            additional_loan_amount_back: (typeof fun.additional_loan_amount_back !== 'undefined') ? fun.additional_loan_amount_back : 0,
            loan_type_back: (typeof fun.loan_type_back !== 'undefined') ? fun.loan_type_back : getLoanType().value,
            interest_rate_back: (typeof fun.interest_rate_back !== 'undefined') ? fun.interest_rate_back : 0,
            to_year_back: (typeof fun.to_year_back !== 'undefined') ? fun.to_year_back : 40,

            progress: property.complete_status,

            images: property.images,
            overview: property.overview,

            property: property,
        }
    }

    return state;
};

export const setWhoPayState = (data) => {
    let state = {};
    if (data) {
        let whopay = data.who_pay_detail;
        let cash = data.cashflow_details;
        let cash_percentage = (typeof whopay.cash_percentage !== 'undefined') ? whopay.cash_percentage : 0;
        let tax_benefit_percentage = (typeof whopay.tax_benefit_percentage !== 'undefined') ? whopay.tax_benefit_percentage : 0;
        let rent_percentage = (typeof whopay.rent_percentage !== 'undefined') ? whopay.rent_percentage : 0;
        state = {
            after_cashflow: (typeof cash.after_cashflow !== 'undefined') ? cash.after_cashflow : 0,
            your_cost: (typeof cash.your_cost !== 'undefined') ? cash.your_cost : 0,
            year: (typeof whopay.year !== 'undefined') ? whopay.year : getWhoPayYear().value,
            principal_amount: (typeof whopay.principal_amount !== 'undefined') ? whopay.principal_amount : 0,
            interest_cost: (typeof whopay.interest_cost !== 'undefined') ? whopay.interest_cost : 0,
            rental_expenses: (typeof whopay.rental_expenses !== 'undefined') ? whopay.rental_expenses : 0,
            total_cost: (typeof whopay.total_cost !== 'undefined') ? whopay.total_cost : 0,
            rent: (typeof whopay.rent !== 'undefined') ? whopay.rent : 0,
            tax_benefit: (typeof whopay.tax_benefit !== 'undefined') ? whopay.tax_benefit : 0,
            cash: (typeof whopay.cash !== 'undefined') ? whopay.cash : 0,
            cash_weekly: (typeof whopay.cash_weekly !== 'undefined') ? whopay.cash_weekly : 0,
            total: (typeof whopay.total !== 'undefined') ? whopay.total : 0,
            rent_percentage: rent_percentage,
            tax_benefit_percentage: tax_benefit_percentage,
            cash_percentage: cash_percentage,
            chart_data: {
                labels: [
                    'You',
                    'Tax Credit',
                    'Tenant',
                ],

                datasets: [{
                    data: [cash_percentage, tax_benefit_percentage, rent_percentage],
                    backgroundColor: [
                        config.who_pay_color.you,
                        config.who_pay_color.tax_credit,
                        config.who_pay_color.tenant,
                    ],
                    hoverBackgroundColor: [
                        config.who_pay_color.you,
                        config.who_pay_color.tax_credit,
                        config.who_pay_color.tenant,
                    ]
                }]
            },
        }
    }

    return state;
};

export const setPurchaseSubscriptionState = (subscription_type, subscription_interval, qty, has_trial = false, discount = 0, discount_per = 0) => {

    let trial_days = config.trial_days;
    let interval = 'month';
    if (parseInt(subscription_interval) === 2) {
        interval = 'year';
    }
    let start_date = '';
    let end_date = '';
    if (has_trial) {
        start_date = moment().add(trial_days,'days').format('DD/MM/YYYY');
        end_date = moment().add(trial_days,'days').add(1, interval).format('DD/MM/YYYY');
    } else {
        start_date = moment().format('DD/MM/YYYY');
        end_date = moment().add(1, interval).format('DD/MM/YYYY');
    }

    let sub_total = 0;
    let tax = 0;
    let total = parseFloat(getPlanPrice(subscription_type, subscription_interval, qty)).toFixed(2);
    let gst_percentage = config.gst_percentage;

    if (total && gst_percentage) {
        tax = parseFloat((gst_percentage * total) / (100 + gst_percentage)).toFixed(2);
        sub_total = parseFloat(total - tax).toFixed(2);
    }

    if (discount_per) {
        discount = (total * discount_per) / 100;
        discount = parseFloat(discount).toFixed(2);
    }

    if (discount) {
        if (discount > total) {
            discount = total;
        }
        total = parseFloat(total - discount).toFixed(2);
        if (total < 0) {
            total = 0;
        }
    }

    return {
        subscription_type: subscription_type,
        subscription_interval: subscription_interval,
        has_trial: has_trial,
        start_date: start_date,
        end_date: end_date,
        qty: qty,
        sub_price: sub_total,
        tax: tax,
        discount: discount,
        discount_per: discount_per,
        total: total,
    };
};

export const downloadReport = (data) => {

    let pdf_data = data.pdf;
    let fileName = 'Properlytics-Report-' + data.address + '.pdf';

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        let byteCharacters = atob(pdf_data);
        let byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        let blob = new Blob([byteArray], {
            type: 'application/pdf'
        });
        window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
        // decode base64 string, remove space for IE compatibility
        let binary = atob(pdf_data.replace(/\s/g, ''));
        let len = binary.length;
        let buffer = new ArrayBuffer(len);
        let view = new Uint8Array(buffer);
        for (let i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }

        // create the blob object with content-type "application/pdf"
        let blob = new Blob([view], {type: "application/pdf"});
        window.URL = window.URL || window.webkitURL;
        let url = window.URL.createObjectURL(blob);
        //if want to open the pdf then uncomment below line
        //window.open(url);

        //to download new invoice file
        let downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = fileName;  //Name the file here
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }
};