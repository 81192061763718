import React from 'react';
import NumberFormat from 'react-number-format';

function limit(val, max) {
    if (val.length === 1 && val[0] > max[0]) {
        val = '0' + val;
    }

    if (val.length === 2) {
        if (Number(val) === 0) {
            val = '01';

            //this can happen when user paste number
        } else if (val > max) {
            val = max;
        }
    }

    return val;
}

function cardExpiry(val) {
    let day = limit(val.substring(0, 2), '31');
    let month = limit(val.substring(2, 4), '12');
    let year = val.substring(4, 8);

    return day + (month.length ? '/' + month : '') + (year.length ? '/' + year : '');
}

export default class DOBField extends React.Component {

    constructor(props) {
        super(props);
        this.onValueChange = this.onValueChange.bind(this);
    }

    onValueChange(values) {
        let event = {
            target: {
                name: this.props.name,
                value: values.formattedValue,
            }
        }
        this.props.onChange(event)
    }

    render() {
        let name = (this.props.name) || '';
        let value = (this.props.value) || '';
        let className = (this.props.className) || '';

        if (name === '') {
            return '';
        }

        return (
            <NumberFormat
                format={cardExpiry}
                name={name}
                id={name}
                className={className || "form-control"}
                placeholder="DD/MM/YYYY"
                mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                value={value}
                onValueChange={(values) => this.onValueChange(values)}
            />
        );
    }
}