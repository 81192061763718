import Dashboard from '../views/dashboard/dashboard';
import ProppertyDetail from '../views/property/tabs/property-detail';
import AcquisitionCosts from "../views/property/tabs/acquisition-costs";
import FundingStructure from "../views/property/tabs/funding-structure";
import InvestmentOutcomes from "../views/property/tabs/investment-outcomes";
import InvestmentExpenses from "../views/property/tabs/investment-expenses";
import EconomicAssumption from "../views/property/tabs/economic-assumption";
import Income from "../views/property/tabs/income";
import Deduction from "../views/property/tabs/deduction";
import Cashflow from "../views/property/tabs/cashflow";
import config from "config/config";

import dashboard_img from '../assets/images/icon/dashboard@2x.png';
import dashboard_white_img from '../assets/images/icon/dashboard@2x-white.png';
import prop_img from '../assets/images/icon/property-details@2x.png';
import prop_white_img from '../assets/images/icon/property-details@2x-white.png';
import acqui_img from '../assets/images/icon/acquisition-costs@2x.png';
import acqui_white_img from '../assets/images/icon/acquisition-costs@2x-white.png';
import funding_img from '../assets/images/icon/funding-structure@2x.png';
import funding_white_img from '../assets/images/icon/funding-structure@2x-white.png';
import investment_img from '../assets/images/icon/investment-outcome@2x.png';
import investment_white_img from '../assets/images/icon/investment-outcome@2x-white.png';
import expense_img from '../assets/images/icon/investment-expenses@2x.png';
import expense_white_img from '../assets/images/icon/investment-expenses@2x-white.png';
import economic_img from '../assets/images/icon/economic-assumptions@2x.png';
import economic_white_img from '../assets/images/icon/economic-assumptions@2x-white.png';
import deduction_img from '../assets/images/icon/deductions@2x.png';
import deduction_white_img from '../assets/images/icon/deductions@2x-white.png';
import income_img from '../assets/images/icon/income@2x.png';
import income_white_img from '../assets/images/icon/income@2x-white.png';
import cashflow_img from '../assets/images/icon/cashflow-returns@2x.png';
import cashflow_white_img from '../assets/images/icon/cashflow-returns@2x-white.png';


let ThemeRoutes = [

    /*non display routes*/
    {
        collapse: false,
        path: config.site_url.property_detail + '/:id',
        name: "Property Details",
        display: false,
        property_tab: true,
        component: ProppertyDetail
    },
    {
        collapse: false,
        path: config.site_url.acquisition_cost + '/:id',
        name: "Acquisition Costs",
        display: false,
        property_tab: true,
        component: AcquisitionCosts
    },
    {
        collapse: false,
        path: config.site_url.funding_structure + '/:id',
        name: "Funding Structure",
        display: false,
        property_tab: true,
        component: FundingStructure,
    },
    {
        collapse: false,
        path: config.site_url.investment_outcome + '/:id',
        name: "Investment Outcomes",
        display: false,
        property_tab: true,
        component: InvestmentOutcomes,
    },
    {
        collapse: false,
        path: config.site_url.investment_expenses + '/:id',
        name: "Investment Expenses",
        display: false,
        property_tab: true,
        component: InvestmentExpenses,
    },
    {
        collapse: false,
        path: config.site_url.economic_assumption + '/:id',
        name: "Economic Assumptions",
        display: false,
        property_tab: true,
        component: EconomicAssumption,
    },
    {
        collapse: false,
        path: config.site_url.deduction + '/:id',
        name: "Deductions",
        display: false,
        property_tab: true,
        component: Deduction,
    },
    {
        collapse: false,
        path: config.site_url.income + '/:id',
        name: "Income",
        display: false,
        property_tab: true,
        component: Income,
    },
    {
        collapse: false,
        path: config.site_url.cashflow + '/:id',
        name: "Cashflow & Returns",
        display: false,
        property_tab: true,
        component: Cashflow,
    },

    /*display routes which will display in the sidebar*/
    {
        collapse: false,
        path: config.site_url.dashboard,
        imgUrl: dashboard_img,
        imgUrl2: dashboard_white_img,
        name: "Dashboard",
        display: true,
        property_tab: false,
        component: Dashboard,
    },
    {
        collapse: false,
        path: config.site_url.property_detail,
        imgUrl: prop_img,
        imgUrl2: prop_white_img,
        name: "Property Details",
        display: true,
        property_tab: true,
        component: ProppertyDetail,
    },
    {
        collapse: false,
        path: config.site_url.acquisition_cost,
        imgUrl: acqui_img,
        imgUrl2: acqui_white_img,
        name: "Acquisition Costs",
        display: true,
        property_tab: true,
        component: AcquisitionCosts
    },
    {
        collapse: false,
        path: config.site_url.funding_structure,
        imgUrl: funding_img,
        imgUrl2: funding_white_img,
        name: "Funding Structure",
        display: true,
        property_tab: true,
        component: FundingStructure,
    },
    {
        collapse: false,
        path: config.site_url.investment_outcome,
        imgUrl: investment_img,
        imgUrl2: investment_white_img,
        name: "Investment Outcomes",
        display: true,
        property_tab: true,
        component: InvestmentOutcomes,
    },
    {
        collapse: false,
        path: config.site_url.investment_expenses,
        imgUrl: expense_img,
        imgUrl2: expense_white_img,
        name: "Investment Expenses",
        display: true,
        property_tab: true,
        component: InvestmentExpenses,
    },
    {
        collapse: false,
        path: config.site_url.economic_assumption,
        imgUrl: economic_img,
        imgUrl2: economic_white_img,
        name: "Economic Assumptions",
        display: true,
        property_tab: true,
        component: EconomicAssumption,
    },
    {
        collapse: false,
        path: config.site_url.deduction,
        imgUrl: deduction_img,
        imgUrl2: deduction_white_img,
        name: "Deductions",
        display: true,
        property_tab: true,
        component: Deduction,
    },
    {
        collapse: false,
        path: config.site_url.income,
        imgUrl: income_img,
        imgUrl2: income_white_img,
        name: "Income",
        display: true,
        property_tab: true,
        component: Income,
    },
    {
        collapse: false,
        path: config.site_url.cashflow,
        imgUrl: cashflow_img,
        imgUrl2: cashflow_white_img,
        name: "Cashflow & Returns",
        display: true,
        property_tab: true,
        component: Cashflow,
    },
];

export default ThemeRoutes;
