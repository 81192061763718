import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import authRoutes from '../routes/authroutes.jsx';
import LoginHeader from "../components/header/login-header";


class Loginlayout extends React.Component {

    /*constructor(props) {
        super(props);

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll(event) {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            document.getElementById("top-header").classList.add('fixed-header');
        } else {
            document.getElementById("top-header").classList.remove('fixed-header');
        }
    }*/

    render() {
        return (
            <div className="authentications">

                <LoginHeader/>

                <Switch>
                    {authRoutes.map((prop, key) => {
                        if (prop.redirect)
                            return (
                                <Redirect from={prop.path} to={prop.pathTo} key={key}/>
                            );
                        return (
                            <Route path={prop.path} component={prop.component} key={key}/>
                        );
                    })}
                </Switch>
            </div>

        )
    }
}

export default Loginlayout;