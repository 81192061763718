import React from 'react';
import {
    Row,
    Col,
    Form,
    Progress,
} from 'reactstrap';
import {
    getBedroomsType,
    getBathroomsType,
    getCarsType,
    getDefaultMapLat,
    getDefaultMapLng,
    getClientId,
    getDefaultDPLife,
    getDefaultDiminishingValue,
    getDefaultDepreciationOfBuilding,
    getLoanType,
    getDefaultDepreciationMethod,

} from "../../../services/config-helper";
import PropertySnapshot from "../sections/property-snapshot";
import Projection from "../sections/projection";
import {
    addEditDeduction, calDP, getDeduction,
    getProperty
} from "../../../services/services";
import config from "config/config";
import {
    getMyProfile, getNotificationObject, setDeductionTabState,
} from "../../../services/helper";
import {Redirect} from 'react-router';
import ReactNotification from "react-notifications-component";
import RightButton from "../../../components/properlytics/right-button";
import LeftButton from "../../../components/properlytics/left-button";
import DepreciationOfFittings from "./dialogs/depreciation-of-fitting";
import ExtraRepayment from "../sections/extra-repayment";
import PriceField from "../../../components/numbers/PriceField";
import PercentageField from "../../../components/numbers/PercentageField";

export default class Deduction extends React.Component {

    constructor(props) {
        super(props);
        let lat = getDefaultMapLat();
        let long = getDefaultMapLng();
        let bed = getBedroomsType().value;
        let bath = getBathroomsType().value;
        let car = getCarsType().value;
        let lifes = getDefaultDPLife();
        this.state = {
            is_edit: false,
            property_id: '',
            cash_deduction: 0,
            depreciation_of_building: getDefaultDepreciationOfBuilding(),
            building_cost: 0,
            depreciation_amount: 0,
            depreciation_fitting: 0,
            loan_cost: 0,
            //dp
            furniture_value: 0,
            furniture_life: lifes.furniture_life,
            general_value: 0,
            general_life: lifes.general_life,
            curtains_value: 0,
            curtains_life: lifes.curtains_life,
            carpets_value: 0,
            carpets_life: lifes.carpets_life,
            hot_water_sys_value: 0,
            hot_water_sys_life: lifes.hot_water_sys_life,
            lawn_mowor_value: 0,
            lawn_mowor_life: lifes.lawn_mowor_life,
            refrigerator_value: 0,
            refrigerator_life: lifes.refrigerator_life,
            stove_value: 0,
            stove_life: lifes.stove_life,
            law_value_pool_value: 0,
            law_value_pool_life: lifes.law_value_pool_life,
            total: 0,
            year_one_depreciation: 0,
            depreciation_method: getDefaultDepreciationMethod(),
            diminishing_value: getDefaultDiminishingValue(),

            next: false,
            previous: false,

            progress: 0,

            /*snap shot*/
            latitude: lat,
            longitude: long,
            property_address: '',
            purchase_price: '',
            bedrooms: bed,
            bathrooms: bath,
            car_space: car,
            adv_link: '',
            images: [],

            /*extra repayment*/
            loan_amount: 0,
            additional_loan_amount: 0,
            interest_rate: 0,
            to_year: 40,
            loan_type: getLoanType().value,
            loan_amount_back: 0,
            additional_loan_amount_back: 0,
            interest_rate_back: 0,
            to_year_back: 40,
            loan_type_back: getLoanType().value,

            /*projection*/
            overview: {},

            property: {},
            loading: false,
            loading_previous: false,
            show_dialog: false,
            redirect_to_dashboard: false,
        }

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();

        this.onInputChange = this.onInputChange.bind(this);
        this.hideShowDialog = this.hideShowDialog.bind(this);
        this.setStateData = this.setStateData.bind(this);
        this.onNext = this.onNext.bind(this);
        this.onPrevious = this.onPrevious.bind(this);
        this.getDeduction = this.getDeduction.bind(this);
        this.calDepreciationAmount = this.calDepreciationAmount.bind(this);
        this.calDP = this.calDP.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    hideShowDialog() {
        if (this.state.property_id === '') {
            this.showNotification('Please first enter property detail', 'danger')
        } else {
            this.setState({
                show_dialog: (!this.state.show_dialog)
            })
            window.scrollTo(0, 0);
        }
    }

    onBlur(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.calDP(name, value);
    }

    onInputChange(event) {
        let name = event.target.name;
        let value = event.target.value;
        if (!isNaN(value) || value === '') {
            this.setState({
                [name]: value
            });

            if (name === 'depreciation_of_building') {
                this.calDepreciationAmount(value, this.state.building_cost);
            }
            if (name === 'building_cost') {
                this.calDepreciationAmount(this.state.depreciation_of_building, value);
            }

            //change dp function call
            if (name === 'depreciation_method') {
                this.calDP(name, value);
            }
        }
    }

    getDeduction(property_id) {
        if (property_id !== '') {
            let postData = new FormData();
            postData.append('property_id', property_id);
            getDeduction(postData).then(res => {
                if (res.success) {
                    this.setState({
                        cash_deduction: res.data.deduction_details.cash_deduction,
                        loan_cost: res.data.deduction_details.loan_cost,
                    })
                }
            });
        }
    }

    calDepreciationAmount(depreciation_of_building, building_cost) {
        if (depreciation_of_building !== '' && building_cost !== '') {
            let depreciation_amount = (parseFloat(building_cost) * parseFloat(depreciation_of_building)) / 100;
            this.setState({
                depreciation_amount: parseFloat(depreciation_amount).toFixed(2)
            })
        }
    }

    calDP(name, value) {
        if (this.state.is_edit) {
            let postData = new FormData();
            let dp_total = 0;
            postData.append('property_id', this.state.property_id);

            let furniture_value = (this.state.furniture_value !== '') ? parseFloat(this.state.furniture_value) : 0;
            if (name === 'furniture_value') {
                furniture_value = (value !== '') ? parseFloat(value) : 0;
            }
            postData.append('furniture_value', furniture_value);
            dp_total += parseFloat(furniture_value);

            let furniture_life = (this.state.furniture_life !== '') ? this.state.furniture_life : 0;
            if (name === 'furniture_life') {
                furniture_life = (value !== '') ? value : 0;
            }
            postData.append('furniture_life', furniture_life);

            let general_value = (this.state.general_value !== '') ? parseFloat(this.state.general_value) : 0;
            if (name === 'general_value') {
                general_value = (value !== '') ? parseFloat(value) : 0;
            }
            postData.append('general_value', general_value);
            dp_total += parseFloat(general_value);

            let general_life = (this.state.general_life !== '') ? this.state.general_life : 0;
            if (name === 'general_life') {
                general_life = (value !== '') ? value : 0;
            }
            postData.append('general_life', general_life);

            let curtains_value = (this.state.curtains_value !== '') ? parseFloat(this.state.curtains_value) : 0;
            if (name === 'curtains_value') {
                curtains_value = (value !== '') ? parseFloat(value) : 0;
            }
            postData.append('curtains_value', curtains_value);
            dp_total += parseFloat(curtains_value);

            let curtains_life = (this.state.curtains_life !== '') ? this.state.curtains_life : 0;
            if (name === 'curtains_life') {
                curtains_life = (value !== '') ? value : 0;
            }
            postData.append('curtains_life', curtains_life);

            let carpets_value = (this.state.carpets_value !== '') ? parseFloat(this.state.carpets_value) : 0;
            if (name === 'carpets_value') {
                carpets_value = (value !== '') ? parseFloat(value) : 0;
            }
            postData.append('carpets_value', carpets_value);
            dp_total += parseFloat(carpets_value);

            let carpets_life = (this.state.carpets_life !== '') ? this.state.carpets_life : 0;
            if (name === 'carpets_life') {
                carpets_life = (value !== '') ? value : 0;
            }
            postData.append('carpets_life', carpets_life);

            let hot_water_sys_value = (this.state.hot_water_sys_value !== '') ? parseFloat(this.state.hot_water_sys_value) : 0;
            if (name === 'hot_water_sys_value') {
                hot_water_sys_value = (value !== '') ? parseFloat(value) : 0;
            }
            postData.append('hot_water_sys_value', hot_water_sys_value);
            dp_total += parseFloat(hot_water_sys_value);

            let hot_water_sys_life = (this.state.hot_water_sys_life !== '') ? this.state.hot_water_sys_life : 0;
            if (name === 'hot_water_sys_life') {
                hot_water_sys_life = (value !== '') ? value : 0;
            }
            postData.append('hot_water_sys_life', hot_water_sys_life);

            let lawn_mowor_value = (this.state.lawn_mowor_value !== '') ? parseFloat(this.state.lawn_mowor_value) : 0;
            if (name === 'lawn_mowor_value') {
                lawn_mowor_value = (value !== '') ? parseFloat(value) : 0;
            }
            postData.append('lawn_mowor_value', lawn_mowor_value);
            dp_total += parseFloat(lawn_mowor_value);

            let lawn_mowor_life = (this.state.lawn_mowor_life !== '') ? this.state.lawn_mowor_life : 0;
            if (name === 'lawn_mowor_life') {
                lawn_mowor_life = (value !== '') ? value : 0;
            }
            postData.append('lawn_mowor_life', lawn_mowor_life);

            let refrigerator_value = (this.state.refrigerator_value !== '') ? parseFloat(this.state.refrigerator_value) : 0;
            if (name === 'refrigerator_value') {
                refrigerator_value = (value !== '') ? parseFloat(value) : 0;
            }
            postData.append('refrigerator_value', refrigerator_value);
            dp_total += parseFloat(refrigerator_value);

            let refrigerator_life = (this.state.refrigerator_life !== '') ? this.state.refrigerator_life : 0;
            if (name === 'refrigerator_life') {
                refrigerator_life = (value !== '') ? value : 0;
            }
            postData.append('refrigerator_life', refrigerator_life);

            let stove_value = (this.state.stove_value !== '') ? parseFloat(this.state.stove_value) : 0;
            if (name === 'stove_value') {
                stove_value = (value !== '') ? parseFloat(value) : 0;
            }
            postData.append('stove_value', stove_value);
            dp_total += parseFloat(stove_value);

            let stove_life = (this.state.stove_life !== '') ? this.state.stove_life : 0;
            if (name === 'stove_life') {
                stove_life = (value !== '') ? value : 0;
            }
            postData.append('stove_life', stove_life);

            let law_value_pool_value = (this.state.law_value_pool_value !== '') ? parseFloat(this.state.law_value_pool_value) : 0;
            if (name === 'law_value_pool_value') {
                law_value_pool_value = (value !== '') ? parseFloat(value) : 0;
            }
            postData.append('law_value_pool_value', law_value_pool_value);
            dp_total += parseFloat(law_value_pool_value);

            let law_value_pool_life = (this.state.law_value_pool_life !== '') ? this.state.law_value_pool_life : 0;
            if (name === 'law_value_pool_life') {
                law_value_pool_life = (value !== '') ? value : 0;
            }
            postData.append('law_value_pool_life', law_value_pool_life);

            let total = dp_total;
            if (name === 'total') {
                total = (value !== '') ? value : 0;
            }
            postData.append('total', total);
            let depreciation_method = this.state.depreciation_method;
            if (name === 'depreciation_method') {
                depreciation_method = value;
            }
            postData.append('depreciation_method', depreciation_method);
            let diminishing_value = (this.state.diminishing_value !== '') ? this.state.diminishing_value : 0;
            if (name === 'diminishing_value') {
                diminishing_value = (value !== '') ? value : 0;
            }
            postData.append('diminishing_value', diminishing_value);

            if (name !== 'total' && name !== 'diminishing_value' && name !== 'depreciation_method') {
                this.setState({
                    total: parseFloat(dp_total).toFixed(2)
                })
            }

            calDP(postData).then(res => {
                if (res.success) {
                    this.setState({
                        year_one_depreciation: res.data.depreciation_fitting,
                        depreciation_fitting: res.data.depreciation_fitting,
                    })
                } else {
                    this.showNotification(res.message, 'danger')
                }
            });
        }
    }

    onNext() {
        if (this.state.is_edit) {
            this.saveData(true);
        } else {
            this.showNotification('Please first enter property detail', 'danger')
        }
    }

    onPrevious() {
        if (this.state.is_edit) {
            this.saveData(false);
        } else {
            this.setState({
                previous: true,
            })
        }
    }

    saveData(isNext = true) {
        let loadingState = (isNext) ? 'loading' : 'loading_previous';
        let goToState = (isNext) ? 'next' : 'previous';

        this.setState({
            [loadingState]: true
        })

        let postData = new FormData();
        postData.append('property_id', this.state.property_id);
        postData.append('cash_deduction', (this.state.cash_deduction) ? this.state.cash_deduction : 0);
        postData.append('depreciation_of_building', (this.state.depreciation_of_building) ? this.state.depreciation_of_building : 0);
        postData.append('building_cost', (this.state.building_cost) ? this.state.building_cost : 0);
        postData.append('depreciation_amount', (this.state.depreciation_amount) ? this.state.depreciation_amount : 0);
        postData.append('depreciation_fitting', (this.state.depreciation_fitting) ? this.state.depreciation_fitting : 0);
        postData.append('loan_cost', (this.state.loan_cost) ? this.state.loan_cost : 0);

        //dp
        postData.append('furniture_value', (this.state.furniture_value) ? this.state.furniture_value : 0);
        postData.append('furniture_life', (this.state.furniture_life) ? this.state.furniture_life : 0);
        postData.append('general_value', (this.state.general_value) ? this.state.general_value : 0);
        postData.append('general_life', (this.state.general_life) ? this.state.general_life : 0);
        postData.append('curtains_value', (this.state.curtains_value) ? this.state.curtains_value : 0);
        postData.append('curtains_life', (this.state.curtains_life) ? this.state.curtains_life : 0);
        postData.append('carpets_value', (this.state.carpets_value) ? this.state.carpets_value : 0);
        postData.append('carpets_life', (this.state.carpets_life) ? this.state.carpets_life : 0);
        postData.append('hot_water_sys_value', (this.state.hot_water_sys_value) ? this.state.hot_water_sys_value : 0);
        postData.append('hot_water_sys_life', (this.state.hot_water_sys_life) ? this.state.hot_water_sys_life : 0);
        postData.append('lawn_mowor_value', (this.state.lawn_mowor_value) ? this.state.lawn_mowor_value : 0);
        postData.append('lawn_mowor_life', (this.state.lawn_mowor_life) ? this.state.lawn_mowor_life : 0);
        postData.append('refrigerator_value', (this.state.refrigerator_value) ? this.state.refrigerator_value : 0);
        postData.append('refrigerator_life', (this.state.refrigerator_life) ? this.state.refrigerator_life : 0);
        postData.append('stove_value', (this.state.stove_value) ? this.state.stove_value : 0);
        postData.append('stove_life', (this.state.stove_life) ? this.state.stove_life : 0);
        postData.append('law_value_pool_value', (this.state.law_value_pool_value) ? this.state.law_value_pool_value : 0);
        postData.append('law_value_pool_life', (this.state.law_value_pool_life) ? this.state.law_value_pool_life : 0);
        postData.append('total', (this.state.total) ? this.state.total : 0);
        postData.append('year_one_depreciation', (this.state.year_one_depreciation) ? this.state.year_one_depreciation : 0);
        postData.append('depreciation_method', this.state.depreciation_method);
        postData.append('diminishing_value', (this.state.diminishing_value) ? this.state.diminishing_value : 0);

        addEditDeduction(postData).then(res => {
            if (res.success) {
                this.showNotification(res.message, 'success')
                this.setState({
                    [goToState]: true,
                    property: res.data.property
                })
            } else {
                this.showNotification(res.message, 'danger')
            }
            this.setState({
                [loadingState]: false
            })
        });
    }

    setStateData(property) {
        let stateData = setDeductionTabState(property);
        this.setState(stateData);
        this.setState({
            is_edit: true,
        })
        this.getDeduction(stateData.property_id);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.match.path === config.site_url.deduction + '/:id') {
            //is edit call
            let property_id = this.props.match.params.id;
            if (property_id) {

                if (typeof this.props.location.property !== 'undefined' && this.props.location.property) {
                    this.setStateData(this.props.location.property);
                } else {
                    let postData = {
                        property_id: property_id
                    }

                    getProperty(postData).then(res => {
                        if (res.success) {
                            this.setStateData(res.data.property);
                        } else {
                            if (res.error) {
                                if (typeof res.error !== 'undefined' && typeof res.error.auth_code !== 'undefined' &&
                                    res.error.auth_code === 401
                                ) {
                                    this.setState({
                                        redirect_to_dashboard: true,
                                    })
                                }
                            }
                            this.showNotification(res.message, 'danger')
                        }
                    });
                }

            } else {
                this.setState({
                    redirect_to_dashboard: true,
                })
            }
        } else {
            let profile = getMyProfile();
            if (profile) {
                if (profile.account_type === 2) {
                    let client_id = (getClientId()) || '';
                    if (client_id === '') {
                        this.setState({
                            redirect_to_dashboard: true,
                        })
                    }
                }
            }
        }
    }

    render() {

        //if business account and client value not pass as params then redirect to dashboard
        if (this.state.redirect_to_dashboard) {
            return <Redirect to={config.site_url.dashboard} />;
        }

        //if next success then redirect to next tab
        if (this.state.next) {
            return <Redirect
                to={{
                    pathname: config.site_url.income + '/' + this.state.property_id,
                    property: this.state.property
                }}
            />;
        }

        //if next success then redirect to next tab
        if (this.state.previous) {
            return <Redirect
                to={{
                    pathname: config.site_url.economic_assumption + '/' + this.state.property_id,
                    property: this.state.property
                }}
            />;
        }


        return <div className="">

            <ReactNotification ref={this.notificationDOMRef} />

            <Row>
                <Col md="6">
                    {/*--------------------------------------------------------------------------------*/}
                    {/*ACQUISITION COST DETAIL*/}
                    {/*--------------------------------------------------------------------------------*/}
                    <div className="prpty_flipper blocks">
                        <div className="block">
                            <div className={(this.state.show_dialog) ? "plisting_form rect_bx flip_bx go" : "plisting_form rect_bx flip_bx"}>
                                <div className="head_tron d-flex">
                                    <h2 className="head  fx_w t_green">Deductions</h2>

                                    <Progress value={this.state.progress} />

                                    <div className="progress-no t_green">{this.state.progress}%</div>
                                </div>

                                <div className="inner_bx">
                                    <Form id="prp_detail">

                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Cash Deductions (P/Y)</h4>
                                                <PriceField
                                                    placeholder="$0"
                                                    name="cash_deduction"
                                                    value={this.state.cash_deduction}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>

                                        <div className="tp_row pr-0 pt-0 pb-0 height60">
                                            <div className="hd_row height60 d-flex pr-40 justify-content-between">
                                                <h4 className="sm_tit fx_w t_black t_upper">Non-cash deductions</h4>
                                            </div>

                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Depreciation of Building
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <PercentageField
                                                                className="form-control text-right"
                                                                placeholder="0%"
                                                                name="depreciation_of_building"
                                                                value={this.state.depreciation_of_building}
                                                                onChange={this.onInputChange}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Purchase Price
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <PriceField
                                                                className="form-control text-right"
                                                                placeholder="$0"
                                                                name="purchase_price"
                                                                value={this.state.purchase_price}
                                                                readOnly={true}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Building Cost
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <PriceField
                                                                className="form-control text-right"
                                                                placeholder="$0"
                                                                name="building_cost"
                                                                value={this.state.building_cost}
                                                                onChange={this.onInputChange}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Depreciation Amount
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <PriceField
                                                                className="form-control text-right"
                                                                placeholder="$0"
                                                                name="depreciation_amount"
                                                                value={this.state.depreciation_amount}
                                                                readOnly={true}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Depreciation of Fittings
                                                        <span className="flipbtn t_black rotate" onClick={this.hideShowDialog}>
                                                        <i className="icon-arrow-up-circle"/>
                                                    </span>
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <PriceField
                                                                className="form-control text-right"
                                                                placeholder="$0"
                                                                name="depreciation_fitting"
                                                                value={this.state.depreciation_fitting}
                                                                readOnly={true}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Loan Costs
                                                    </h4>

                                                    <span className="d-flex  height60">
                                                        <span className="value t_black pmedium">
                                                            <PriceField
                                                                className="form-control text-right"
                                                                placeholder="$0"
                                                                name="loan_cost"
                                                                value={this.state.loan_cost}
                                                                readOnly={true}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="tp_row_btm d-flex justify-content-between">
                                            <LeftButton text="Previous" onClick={this.onPrevious}  loading={this.state.loading_previous}/>

                                            <RightButton text="Next" onClick={this.onNext} loading={this.state.loading} />
                                        </div>
                                    </Form>
                                </div>
                            </div>

                            <DepreciationOfFittings
                                furniture_value={this.state.furniture_value}
                                furniture_life={this.state.furniture_life}
                                general_value={this.state.general_value}
                                general_life={this.state.general_life}
                                curtains_value={this.state.curtains_value}
                                curtains_life={this.state.curtains_life}
                                carpets_value={this.state.carpets_value}
                                carpets_life={this.state.carpets_life}
                                hot_water_sys_value={this.state.hot_water_sys_value}
                                hot_water_sys_life={this.state.hot_water_sys_life}
                                lawn_mowor_value={this.state.lawn_mowor_value}
                                lawn_mowor_life={this.state.lawn_mowor_life}
                                refrigerator_value={this.state.refrigerator_value}
                                refrigerator_life={this.state.refrigerator_life}
                                stove_value={this.state.stove_value}
                                stove_life={this.state.stove_life}
                                law_value_pool_value={this.state.law_value_pool_value}
                                law_value_pool_life={this.state.law_value_pool_life}
                                total={this.state.total}
                                year_one_depreciation={this.state.year_one_depreciation}
                                depreciation_method={this.state.depreciation_method}
                                diminishing_value={this.state.diminishing_value}
                                show_dialog={this.state.show_dialog}
                                onInputChange={this.onInputChange}
                                onBlur={this.onBlur}
                                hideShowDialog={this.hideShowDialog}
                            />
                        </div>
                    </div>


                    <PropertySnapshot
                        property_id={this.state.property_id}
                        lat={this.state.latitude}
                        lng={this.state.longitude}
                        address={this.state.property_address}
                        price={this.state.purchase_price}
                        bed={this.state.bedrooms}
                        bath={this.state.bathrooms}
                        car={this.state.car_space}
                        adv_link={this.state.adv_link}
                        images={this.state.images}
                        showNotification={this.showNotification}
                    />
                </Col>

                <Col md={6}>
                    <Projection
                        property_id={this.state.property_id}
                        progress={this.state.progress}
                        overview={this.state.overview}
                        showNotification={this.showNotification}
                    />

                    <ExtraRepayment
                        property_id={this.state.property_id}
                        loan_amount={this.state.loan_amount}
                        additional_loan_amount={this.state.additional_loan_amount}
                        interest_rate={this.state.interest_rate}
                        to_year={this.state.to_year}
                        loan_type={this.state.loan_type}
                        loan_amount_back={this.state.loan_amount_back}
                        additional_loan_amount_back={this.state.additional_loan_amount_back}
                        interest_rate_back={this.state.interest_rate_back}
                        to_year_back={this.state.to_year_back}
                        loan_type_back={this.state.loan_type_back}
                    />
                </Col>
            </Row>
        </div>

    }
}