import React from 'react'
import {Redirect} from 'react-router';
import {isLogin} from "../services/helper";
import config from 'config/config';
import {getProfile} from "../services/services";

export default function (ComposedComponent) {

    class AuthMiddleware extends React.Component {

        state = {
            authenticate: true
        }

        // Push to login route if not authenticated on mount
        UNSAFE_componentWillMount() {
            if (!isLogin()) {
                // Use your router to redirect them to login page
                this.setState({
                    authenticate: false
                })
            }
            getProfile();
        }

        // Push to login route if not authenticated on update
        UNSAFE_componentWillUpdate(nextProps) {
            if (!isLogin()) {
                // Use your router to redirect them to login page
                this.setState({
                    authenticate: false
                })
            }
            getProfile();
        }

        // Otherwise render the original component
        render() {
            if (this.state.authenticate === true) {
                return <ComposedComponent {...this.props}/>
            } else {
                return <Redirect to={config.site_url.login}/>;
            }
        }

    }

    return AuthMiddleware

}