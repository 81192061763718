import React from 'react';
import {
  NavLink,
  Row,
  Col
} from 'reactstrap';

import config from 'config/config';

/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/
import brandicon from '../../assets/images/logo_icon.png';

class LoginHeader extends React.Component {
    render() {
        return (
            <header id="top-header" className="plain-bar">
                <Row>
                    <Col md="12">
                        <div className="d-flex">
                            <img src={brandicon} alt="homepage" width="50" height="50" className="brand-logo" />

                            <NavLink href={config.back_to_website} className=" txt-dgreen link d-md-block">
                                  Back
                            </NavLink>
                        </div>
                    </Col>
                </Row>
            </header>
      );
   }
}

export default LoginHeader;