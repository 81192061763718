import {getConfig, getSettings} from "./helper";

export const getHouseTypesOptions = () => {
    let config = getConfig();
    let data = [];
    if (config.property_type) {
        for (let [key, item] of Object.entries(config.property_type)) {
            data.push({
                value: key,
                label: item,
                color: '#ccf4e6'
            })
        }
    }
    return data;
};

export const getSelectedHouseType = (value = '') => {
    let options = getHouseTypesOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

export const displayPropertySubType = (value) => {
    let config = getConfig();
    if (config.property_extra_display_type) {
        return (config.property_extra_display_type.toString() === value.toString());
    }
    return false
};

export const getPropertySubTypesOptions = () => {
    let config = getConfig();
    let data = [];
    if (config.property_type) {
        for (let [key, item] of Object.entries(config.property_sub_type)) {
            data.push({
                value: key,
                label: item,
                color: '#ccf4e6'
            })
        }
    }
    return data;
};

export const getPropertySubHouseType = (value = '') => {
    let options = getPropertySubTypesOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

export const getBedroomsOptions = () => {
    let config = getConfig();
    let data = [];
    if (config.beds) {
        for (let [key, item] of Object.entries(config.beds)) {
            data.push({
                value: item,
                label: item,
                color: '#ccf4e6'
            })
        }
    }
    return data;
};

export const getBedroomsType = (value = '') => {
    let options = getBedroomsOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

export const getBathroomsOptions = () => {
    let config = getConfig();
    let data = [];
    if (config.bath) {
        for (let [key, item] of Object.entries(config.bath)) {
            data.push({
                value: item,
                label: item,
                color: '#ccf4e6'
            })
        }
    }
    return data;
};

export const getBathroomsType = (value = '') => {
    let options = getBathroomsOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

export const getCarsOptions = () => {
    let config = getConfig();
    let data = [];
    if (config.cars) {
        for (let [key, item] of Object.entries(config.cars)) {
            data.push({
                value: item,
                label: item,
                color: '#ccf4e6'
            })
        }
    }
    return data;
};

export const getCarsType = (value = '') => {
    let options = getCarsOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

export const projectionYearOptions = [
    {value: 'YR', label: 'YR', color: '#ccf4e6', isFixed: true},
    {value: '5', label: 'YR5', color: '#ccf4e6',},
    {value: '10', label: 'YR10', color: '#ccf4e6'},
    {value: '15', label: 'YR15', color: '#ccf4e6'},
];

export const getSelectedYearOption = (value = '') => {
    let options = projectionYearOptions;
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

export const getYear = () => {
    let settings = getSettings();
    if (settings.year) {
        return settings.year;
    }
    return '';
};

export const setYear = (year) => {
    let settings = getSettings();
    if (settings.year) {
        settings.year = year;
    } else {
        settings.year = year;
    }
    window.localStorage.setItem('app.settings', JSON.stringify(settings));
};

export const getDefaultMapLat = () => {
    let config = getConfig();
    if (config.default_map_address) {
        if (config.default_map_address.lat) {
            return parseFloat(config.default_map_address.lat);
        }
    }
    return 0.000000;
};

export const getDefaultMapLng = () => {
    let config = getConfig();
    if (config.default_map_address) {
        if (config.default_map_address.long) {
            return parseFloat(config.default_map_address.long);
        }
    }
    return 0.000000;
};

export const getClientId = () => {
    let settings = getSettings();
    if (settings.client_id) {
        return settings.client_id;
    }
    return '';
};

export const setClientId = (client_id) => {
    let settings = getSettings();
    if (settings.client_id) {
        settings.client_id = client_id;
    } else {
        settings.client_id = client_id;
    }
    window.localStorage.setItem('app.settings', JSON.stringify(settings));
};


/*Tab 2*/
export const getHouseTypeOptions = () => {
    let config = getConfig();
    let data = [];
    if (config.house_type) {
        for (let [key, item] of Object.entries(config.house_type)) {
            data.push({
                value: key,
                label: item,
                color: '#ccf4e6'
            })
        }
    }
    return data;
};

export const getHouseType = (value = '') => {
    let options = getHouseTypeOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

export const getPropertyUsedOptions = () => {
    let config = getConfig();
    let data = [];
    if (config.property_used) {
        for (let [key, item] of Object.entries(config.property_used)) {
            data.push({
                value: key,
                label: item,
                color: '#ccf4e6'
            })
        }
    }
    return data;
};

export const getPropertyUsed = (value = '') => {
    let options = getPropertyUsedOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

export const getPropertyUsedText = (value = '') => {
    let options = getPropertyUsedOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option.label;
            }
        }

        return options[0].label;
    }
    return '';
};

export const getBuyerTypeOptions = () => {
    let config = getConfig();
    let data = [];
    if (config.buyer_type) {
        for (let [key, item] of Object.entries(config.buyer_type)) {
            data.push({
                value: key,
                label: item,
                color: '#ccf4e6'
            })
        }
    }
    return data;
};

export const getBuyerType = (value = '') => {
    let options = getBuyerTypeOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

export const getStateOptions = () => {
    let config = getConfig();
    let data = [];
    if (config.states) {
        for (let [key, item] of Object.entries(config.states)) {
            data.push({
                value: key,
                label: item,
                color: '#ccf4e6'
            })
        }
    }
    return data;
};

export const getState = (value = '') => {
    let options = getStateOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

export const getRepaymentLoanType = [
    {value: '', label: 'Select Loan', color: '#ccf4e6', isFixed: true},
    {value: '1', label: 'Deposit & Costs Loan', color: '#ccf4e6',},
    {value: '2', label: 'Purchase Loan', color: '#ccf4e6'},
];

export const getRepaymentLoan = (value = '') => {
    let options = getRepaymentLoanType;
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

export const getRepaymentCalculateByOption = [
    {value: '1', label: 'PM', color: '#ccf4e6',},
    {value: '2', label: 'PW', color: '#ccf4e6'},
];

export const getRepaymentCalculateBy = (value = '') => {
    let options = getRepaymentCalculateByOption;
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

/*Tab 3*/
export const getLoanTypeOptions = () => {
    let config = getConfig();
    let data = [];
    data.push({
        value: '',
        label: 'Loan Type',
        color: '#ccf4e6'
    })
    if (config.loan_type) {
        for (let [key, item] of Object.entries(config.loan_type)) {
            data.push({
                value: key,
                label: item,
                color: '#ccf4e6'
            })
        }
    }
    return data;
};

export const getLoanType = (value = '') => {
    let options = getLoanTypeOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

export const getDefaultLoanCosts = () => {
    let config = getConfig();
    if (config.default_loan_costs) {
        return config.default_loan_costs;
    }
    return 0;
};

export const getLoanTermOptions = () => {
    let data = [];
    data.push({
        value: '',
        label: 'Term',
        color: '#ccf4e6'
    });
    for (let i = 1; i <= 50; i++) {
        data.push({
            value: i,
            label: i,
            color: '#ccf4e6'
        })
    }
    return data;
};

export const getLoanTerm = (value = '') => {
    let options = getLoanTermOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

/*Tab 4*/
export const getRentOptions = () => {
    let config = getConfig();
    let data = [];
    if (config.rent_duration) {
        for (let [key, item] of Object.entries(config.rent_duration)) {
            data.push({
                value: key,
                label: item,
                color: '#ccf4e6'
            })
        }

        let new_data = [];
        let order = config.rent_duration_order;

        for (let i = 0;i<order.length;i++) {
            for (let j = 0;j<data.length;j++) {
                if (parseInt(order[i]) === parseInt(data[j].value)) {
                    new_data.push(data[j]);
                    break;
                }
            }
        }

        data = new_data;
    }
    return data;
};

export const getRent = (value = '') => {
    let options = getRentOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

/*Tab 5*/
export const getDefaultAgentComissionRate = () => {
    let config = getConfig();
    if (config.default_agent_commission) {
        return config.default_agent_commission;
    }
    return 0;
};

/*Tab 6*/
export const getDefaultCapitalGrowth = () => {
    let config = getConfig();
    if (config.default_capital_growth_rate) {
        return config.default_capital_growth_rate;
    }
    return 0;
};

export const getDefaultInflation = () => {
    let config = getConfig();
    if (config.default_inflation) {
        return config.default_inflation;
    }
    return 0;
};

export const getSelectedYearsOption = () => {
    let data = [];
    for (let i = 11; i <= 50; i++) {
        data.push({
            value: i,
            label: 'YR ' + i,
            color: '#ccf4e6'
        })
    }
    return data;
};

export const getSelectedYear = (value = '') => {
    let options = getSelectedYearsOption();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

/*Tab 7*/
export const getDefaultDPLife = () => {
    let config = getConfig();
    if (config.default_dp_lifes) {
        return config.default_dp_lifes;
    }
    return {};
};

export const getDefaultDepreciationOfBuilding = () => {
    let config = getConfig();
    if (config.default_depreciation_of_building) {
        return config.default_depreciation_of_building;
    }
    return 0;
};

export const getDefaultDiminishingValue = () => {
    let config = getConfig();
    if (config.diminishing_value) {
        return config.diminishing_value;
    }
    return 0;
};


export const getDefaultDepreciationMethod = () => {
    let config = getConfig();
    if (config.default_depreciation_methods) {
        return config.default_depreciation_methods;
    }
    return 0;
};

export const getDepreciationMethodOptions = () => {
    let config = getConfig();
    let data = [];
    if (config.depreciation_methods) {
        for (let [key, item] of Object.entries(config.depreciation_methods)) {
            data.push({
                value: key,
                label: item,
                color: '#ccf4e6'
            })
        }
    }
    return data;
};

export const getDepreciationMethod = (value = '') => {
    let options = getDepreciationMethodOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

/*Tab 8*/
export const getITOptions = () => {
    let config = getConfig();
    let data = [];
    if (config.it_options) {
        for (let [key, item] of Object.entries(config.it_options)) {
            data.push({
                value: key,
                label: item,
                color: '#ccf4e6'
            })
        }
    }
    return data;
};

export const getIT = (value = '') => {
    let options = getITOptions();
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};


/*Tab 9*/
export const getWhoPayYearOptions = [
    {value: '1', label: 'YR 1', color: '#ccf4e6', isFixed: true},
    {value: '2', label: 'YR 2', color: '#ccf4e6',},
    {value: '3', label: 'YR 3', color: '#ccf4e6'},
    {value: '4', label: 'YR 4', color: '#ccf4e6'},
    {value: '5', label: 'YR 5', color: '#ccf4e6'},
    {value: '6', label: 'YR 6', color: '#ccf4e6'},
    {value: '7', label: 'YR 7', color: '#ccf4e6'},
    {value: '8', label: 'YR 8', color: '#ccf4e6'},
    {value: '9', label: 'YR 9', color: '#ccf4e6'},
    {value: '10', label: 'YR 10', color: '#ccf4e6'},
];

export const getWhoPayYear = (value = '') => {
    let options = getWhoPayYearOptions;
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};

/*Subscription Page*/
export const getLicenceOptions = [
    {value: '1', label: '1', color: '#ccf4e6', isFixed: true},
    {value: '2', label: '2', color: '#ccf4e6',},
    {value: '3', label: '3', color: '#ccf4e6'},
    {value: '4', label: '4', color: '#ccf4e6'},
    {value: '5', label: '5', color: '#ccf4e6'},
];

export const getLicence = (value = '') => {
    let options = getLicenceOptions;
    if (options) {
        for (let option of options) {
            if (option.value.toString() === value.toString()) {
                return option;
            }
        }

        return options[0];
    }
    return {};
};