import React from 'react';
import config from 'config/config';

export default class LeftLink extends React.Component {

	render() {
        let {text, url} = this.props;

        if (url === '' && text === '') {
            return '';
        }

		return (
            <div className="right_bx">
                <a href={config.hash + url}>
                    <i aria-hidden="true" className="icon-arrow-left f10" /> { text }
                </a>
            </div>
		);
	}
}