import React from 'react';
import {
    Form,
    CustomInput,
    Input,
} from 'reactstrap';
import RightButton from "components/properlytics/right-button";
import config from "config/config";
import PriceField from "../../../../components/numbers/PriceField";
import LeftButton from "components/properlytics/left-button";

export default class DepreciationOfFittings extends React.Component {
    render() {
        const {
            onInputChange, furniture_value, furniture_life, general_value, general_life, curtains_value, curtains_life,
            carpets_value, carpets_life, hot_water_sys_value, hot_water_sys_life, lawn_mowor_value, lawn_mowor_life,
            refrigerator_value, refrigerator_life, stove_value, stove_life, law_value_pool_value, law_value_pool_life,
            total, year_one_depreciation, depreciation_method, diminishing_value, onBlur
        } = this.props;

        return <div className={(this.props.show_dialog) ? "grd_bx popup-wrap flip_bx1 dp go" : "grd_bx popup-wrap flip_bx1 dp"}>
            <div className="rect_bx flip_bx1">
                <div className="head_tron d-flex">
                    <span className="flipbtn t_black rotate">
                        <i className="icon-arrow-up-circle"/>
                    </span>
                    <h2 className="head pl-2 t_black">Depreciation of Fittings</h2>
                </div>

                <div className="inner_bx">
                    <Form>
                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span>Item</span>
                                    <span>Value($)</span>
                                    <span className="text-right">Life(Year)</span>
                                </div>
                            </div>
                        </div>

                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span>
                                        Furniture
                                    </span>
                                    <span>
                                        <PriceField
                                            placeholder="$0"
                                            name="furniture_value"
                                            value={furniture_value}
                                            onChange={onInputChange.bind(this)}
                                            onBlur={onBlur.bind(this)}
                                        />
                                    </span>
                                    <span>
                                        <Input
                                            type="text"
                                            className="form-control text-right"
                                            placeholder="0"
                                            name="furniture_life"
                                            value={furniture_life}
                                            maxLength={config.number_max_length}
                                            onChange={onInputChange.bind(this)}
                                            onBlur={onBlur.bind(this)}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span>
                                        General
                                    </span>
                                    <span>
                                        <PriceField
                                            placeholder="$0"
                                            name="general_value"
                                            value={general_value}
                                            onChange={onInputChange.bind(this)}
                                            onBlur={onBlur.bind(this)}
                                        />
                                    </span>
                                    <span>
                                        <Input
                                            type="text"
                                            className="form-control text-right"
                                            placeholder="0"
                                            name="general_life"
                                            value={general_life}
                                            maxLength={config.number_max_length}
                                            onChange={onInputChange.bind(this)}
                                            onBlur={onBlur.bind(this)}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span>
                                        Curtains
                                    </span>
                                    <span>
                                        <PriceField
                                            placeholder="$0"
                                            name="curtains_value"
                                            value={curtains_value}
                                            onChange={onInputChange.bind(this)}
                                            onBlur={onBlur.bind(this)}
                                        />
                                    </span>
                                    <span>
                                        <Input
                                            type="text"
                                            className="form-control text-right"
                                            placeholder="0"
                                            name="curtains_life"
                                            value={curtains_life}
                                            maxLength={config.number_max_length}
                                            onChange={onInputChange.bind(this)}
                                            onBlur={onBlur.bind(this)}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span>
                                        Carpets
                                    </span>
                                    <span>
                                        <PriceField
                                            placeholder="$0"
                                            name="carpets_value"
                                            value={carpets_value}
                                            onChange={onInputChange.bind(this)}
                                            onBlur={onBlur.bind(this)}
                                        />
                                    </span>
                                    <span>
                                        <Input
                                            type="text"
                                            className="form-control text-right"
                                            placeholder="0"
                                            name="carpets_life"
                                            value={carpets_life}
                                            maxLength={config.number_max_length}
                                            onChange={onInputChange.bind(this)}
                                            onBlur={onBlur.bind(this)}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span>
                                        Hot Water System
                                    </span>
                                    <span>
                                        <PriceField
                                            placeholder="$0"
                                            name="hot_water_sys_value"
                                            value={hot_water_sys_value}
                                            onChange={onInputChange.bind(this)}
                                            onBlur={onBlur.bind(this)}
                                        />
                                    </span>
                                    <span>
                                        <Input
                                            type="text"
                                            className="form-control text-right"
                                            placeholder="0"
                                            name="hot_water_sys_life"
                                            value={hot_water_sys_life}
                                            maxLength={config.number_max_length}
                                            onChange={onInputChange.bind(this)}
                                            onBlur={onBlur.bind(this)}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span>
                                        Lawn Mower
                                    </span>
                                    <span>
                                        <PriceField
                                            placeholder="$0"
                                            name="lawn_mowor_value"
                                            value={lawn_mowor_value}
                                            onChange={onInputChange.bind(this)}
                                            onBlur={onBlur.bind(this)}
                                        />
                                    </span>
                                    <span>
                                        <Input
                                            type="text"
                                            className="form-control text-right"
                                            placeholder="0"
                                            name="lawn_mowor_life"
                                            value={lawn_mowor_life}
                                            maxLength={config.number_max_length}
                                            onChange={onInputChange.bind(this)}
                                            onBlur={onBlur.bind(this)}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span>
                                        Refrigerator
                                    </span>
                                    <span>
                                        <PriceField
                                            placeholder="$0"
                                            name="refrigerator_value"
                                            value={refrigerator_value}
                                            onChange={onInputChange.bind(this)}
                                            onBlur={onBlur.bind(this)}
                                        />
                                    </span>
                                    <span>
                                        <Input
                                            type="text"
                                            className="form-control text-right"
                                            placeholder="0"
                                            name="refrigerator_life"
                                            value={refrigerator_life}
                                            maxLength={config.number_max_length}
                                            onChange={onInputChange.bind(this)}
                                            onBlur={onBlur.bind(this)}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                        <span>
                                            Stove
                                        </span>
                                    <span>
                                        <PriceField
                                            placeholder="$0"
                                            name="stove_value"
                                            value={stove_value}
                                            onChange={onInputChange.bind(this)}
                                            onBlur={onBlur.bind(this)}
                                        />
                                    </span>
                                    <span>
                                        <Input
                                            type="text"
                                            className="form-control text-right"
                                            placeholder="0"
                                            name="stove_life"
                                            value={stove_life}
                                            maxLength={config.number_max_length}
                                            onChange={onInputChange.bind(this)}
                                            onBlur={onBlur.bind(this)}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span>
                                        Low Value Pool
                                    </span>
                                    <span>
                                        <PriceField
                                            placeholder="$0"
                                            name="law_value_pool_value"
                                            value={law_value_pool_value}
                                            onChange={onInputChange.bind(this)}
                                            onBlur={onBlur.bind(this)}
                                        />
                                    </span>
                                    <span>
                                        <Input
                                            type="text"
                                            className="form-control text-right"
                                            placeholder="0"
                                            name="law_value_pool_life"
                                            value={law_value_pool_life}
                                            maxLength={config.number_max_length}
                                            onChange={onInputChange.bind(this)}
                                            onBlur={onBlur.bind(this)}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span>
                                        Total
                                    </span>
                                    <span>
                                        <PriceField
                                            placeholder="$0"
                                            name="total"
                                            value={total}
                                            onChange={onInputChange.bind(this)}
                                            onBlur={onBlur.bind(this)}
                                        />
                                    </span>
                                    <span>

                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span>
                                        Year One Depreciation
                                    </span>
                                    <span>
                                        <PriceField
                                            placeholder="$0"
                                            name="year_one_depreciation"
                                            value={year_one_depreciation}
                                            readOnly={true}
                                        />
                                    </span>
                                    <span>

                                    </span>
                                </div>
                            </div>
                        </div>



                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span>
                                        <CustomInput
                                            type="radio"
                                            id="depreciation_method1"
                                            name="depreciation_method"
                                            label="Diminishing Value"
                                            value={2}
                                            checked={(parseInt(depreciation_method) === 2)}
                                            onChange={onInputChange.bind(this)}
                                        />
                                    </span>
                                    <span>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            placeholder="0"
                                            name="diminishing_value"
                                            value={diminishing_value}
                                            maxLength={config.percentage_max_length}
                                            onChange={onInputChange.bind(this)}
                                            onBlur={onBlur.bind(this)}
                                        />
                                    </span>
                                    <span/>
                                </div>
                            </div>
                        </div>

                        <div className="tp_row table_row  d-flex  height60">
                            <div className="hd_row w100">
                                <div className="d-flex w100 tab_grid">
                                    <span>
                                        <CustomInput
                                            type="radio"
                                            id="depreciation_method2"
                                            name="depreciation_method"
                                            label="Prime Cost"
                                            value={1}
                                            checked={(parseInt(depreciation_method) === 1)}
                                            onChange={onInputChange.bind(this)}
                                        />
                                    </span>
                                    <span/>
                                    <span/>
                                </div>
                            </div>
                        </div>


                        <div className="tp_row_btm d-flex justify-content-between">
                            <LeftButton text="Close" onClick={this.props.hideShowDialog} />

                            <RightButton text="Save" onClick={this.props.hideShowDialog} />
                        </div>
                    </Form>
                </div>
            </div>`
        </div>

    }
}