const validator = {

    email: {
        rules: [
            {
                test: /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i,
                message: 'Please Enter Valid Email',
            },
        ],
        errors: [],
        valid: false,
        state: '',
    },
    password: {
        rules: [
            {
                test: (value) => {
                    return value.length >= 5;
                },
                message: 'Password can not be < 5 characters',
            },
        ],
        errors: [],
        valid: false,
        state: ''
    },

    confirm_password: {
        rules: [
            {
                test: (value) => {
                    return value.length >= 5;
                },
                message: 'Confirm password can not be < 5 characters',
            },
        ],
        errors: [],
        valid: false,
        state: ''
    },
};

export default validator;