import React from 'react';
import config from 'config/config';

export default class MiddleLink extends React.Component {

	render() {
        let {text, url} = this.props;

        let classes = this.props.className || 'center_bx';

        if (url === '' && text === '') {
            return '';
        }

		return (
            <div className={classes}>
                <a href={config.hash + url} className="dlt_btn" block>
                    {text}
                </a>
            </div>
		);
	}
}