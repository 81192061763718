import React from 'react';
import {
    Row,
    Col,
    Card,
    CardTitle,
    CardBody,
    Button,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import { getNotificationObject, getSubscriptionPageData} from "../../services/helper";
import ReactNotification from "react-notifications-component";
import {Redirect} from 'react-router';
import config from 'config/config';
import classnames from 'classnames';
import RightButton from "../../components/properlytics/right-button";

class Subscription extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            subscription_type: 1, //1 =personal, 2=business
            subscription_interval: 1, // 1=monthly, 2=yearly

            //plan prices
            personal_monthly: 0,
            personal_yearly: 0,
            business_monthly: 0,
            business_yearly: 0,
            has_trial: true,
            redirect_to_purchase: false,
            redirect_to_manage: false,
            redirect_to_dashboard: false,
            loading: false,
        }


        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();

        this.onContinue = this.onContinue.bind(this);
        this.changeSubscriptionType = this.changeSubscriptionType.bind(this);
        this.handleSwitch = this.handleSwitch.bind(this);
    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    onContinue() {
        this.setState({
            redirect_to_purchase: true
        });
    }

    handleSwitch() {
        let subscription_interval = 1;
        if (parseInt(this.state.subscription_interval) === 2) {
            subscription_interval = 1;
        } else {
            subscription_interval = 2;
        }

        this.setState({
            subscription_interval: subscription_interval
        })
    }

    changeSubscriptionType = (tab) => {
        if (this.state.subscription_type !== tab) {
            this.setState({
                subscription_type: tab
            });
        }
    }

    componentDidMount() {
        let subscription = getSubscriptionPageData();
        if (subscription) {
            this.setState(subscription);
        }
    }

    render() {

        if (this.state.redirect_to_dashboard) {
            return <Redirect to={config.site_url.dashboard} />;
        }

        if (this.state.redirect_to_purchase) {
            return <Redirect
                to={{
                    pathname: config.site_url.subscription_purchase,
                    subscription_type: this.state.subscription_type,
                    subscription_interval: this.state.subscription_interval,
                    has_trial: this.state.has_trial
                }}
            />;
        } else {
            if (this.state.redirect_to_manage) {
                return <Redirect to={config.site_url.subscription_manage} />;
            }
        }

        return <div className="">
            {/*--------------------------------------------------------------------------------*/}
            {/* Start Notification */}
            {/*--------------------------------------------------------------------------------*/}
            <ReactNotification ref={this.notificationDOMRef} />
            {/*--------------------------------------------------------------------------------*/}
            {/* End Notification */}
            {/*--------------------------------------------------------------------------------*/}

            {/*--------------------------------------------------------------------------------*/}
            {/*Login Cards*/}
            {/*--------------------------------------------------------------------------------*/}
            <div className="auth-wrapper no-block justify-content-center align-items-center">

                <div className="auth-box">
                    <Row>
                        <Col md="12">
                            <CardTitle className="f32 text-center mb-0">
                                Choose your subscription
                            </CardTitle>
                            <p className="mt-3 txt-gray text-center">There's something for everyone.</p>
                        </Col>
                    </Row>

                    <div id="subscription">
                        <Card>
                            <CardBody className="">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                'active': this.state.subscription_type === 1
                                            })}
                                            onClick={this.changeSubscriptionType.bind(this, 1)}
                                        >
                                            Personal
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                'active': this.state.subscription_type === 2
                                            })}
                                            onClick={this.changeSubscriptionType.bind(this, 2)}
                                        >
                                            Business
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={this.state.subscription_type}>
                                    <TabPane tabId={1}>
                                        <Row>
                                            <Col xs="12">
                                                <div className="sub_bx text-center">

                                                    <div className="field check-round slide-inverse">
                                                        <span className="sts">MONTHLY </span>
                                                        <input
                                                            type="checkbox"
                                                            id="check-slide-i"
                                                            checked={(parseInt(this.state.subscription_interval) === 2)}
                                                        />
                                                        <label for="check-slide-i">
                                                            <span onClick={this.handleSwitch}/>
                                                        </label>
                                                        <span className="sts">ANNUAL <br/><span className="t_gray">17% Discount*</span> </span>
                                                    </div>

                                                    <h4 className="charge">
                                                        <span className="dlr">$</span>
                                                        <span className="amnt">{this.state.subscription_interval === 1 ? this.state.personal_monthly : this.state.personal_yearly}</span>
                                                        <span className="per"> per <br/> {this.state.subscription_interval === 1 ? 'month' : 'year'} </span>
                                                    </h4>

                                                    <h3 className="sub_tit plight f32 t_gray">Personal</h3>
                                                    <ul className="plan_list">
                                                        <li>7 days free trial</li>
                                                        <li>Supports 3 devices</li>
                                                        <li>Up to 10 properties</li>
                                                        <li>Manage personal portfolio</li>
                                                    </ul>

                                                    <Button
                                                        size="lg btn jumbo_btn"
                                                        type="button"
                                                        onClick={this.onContinue}
                                                        block
                                                    >
                                                        choose personal
                                                    </Button>

                                                </div>
                                                <div className="d-flex no-block align-items-cente auth-footer">
                                                    <RightButton text="Continue" onClick={this.onContinue} loading={this.state.loading} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId={2}>
                                        <Row>
                                            <Col xs="12">
                                                <div className="sub_bx text-center">

                                                    <div className="field check-round slide-inverse">
                                                        <span className="sts">MONTHLY </span>
                                                        <input
                                                            type="checkbox"
                                                            id="check-slide-i"
                                                            checked={(parseInt(this.state.subscription_interval) === 2)}
                                                        />
                                                        <label for="check-slide-i">
                                                            <span onClick={this.handleSwitch}/>
                                                        </label>
                                                        <span className="sts">ANNUAL <br/><span className="t_gray">17% Discount*</span> </span>
                                                    </div>

                                                    <h4 className="charge">
                                                        <span className="dlr">$</span>
                                                        <span className="amnt">{this.state.subscription_interval === 1 ? this.state.business_monthly : this.state.business_yearly}</span>
                                                        <span className="per"> per <br/> {this.state.subscription_interval === 1 ? 'month' : 'year'} <br/>/user </span>
                                                    </h4>

                                                    <h3 className="sub_tit plight f32 t_gray">Business</h3>
                                                    <ul className="plan_list">
                                                        <li>7 days free trial</li>
                                                        {/*<li>Up to 5 users</li>*/}
                                                        <li>Supports 3 devices</li>
                                                        <li>Unlimited clients</li>
                                                        <li>Unlimited properties</li>
                                                        <li>Unlimited reports</li>
                                                    </ul>

                                                    <Button
                                                        size="lg btn jumbo_btn"
                                                        type="button"
                                                        onClick={this.onContinue}
                                                        block
                                                    >
                                                        choose business
                                                    </Button>

                                                </div>
                                                <div className="d-flex no-block align-items-cente auth-footer">
                                                    <RightButton text="Continue" onClick={this.onContinue} loading={this.state.loading} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>

                        <p className="mt-1 txt-gray text-center">
                            <a className="custom-ac-link" href={config.hash + config.site_url.subscription_custom}>Need custom account?</a><br/>
                            Need help? Contact us {config.contact_no}
                        </p>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default Subscription;
