import React from 'react';
import {
    Row,
    Col,
    Form,
    Input,
    Progress,
} from 'reactstrap';
import Select, {components} from "react-select";
import {
    getBedroomsType,
    getBathroomsType,
    getCarsType,
    getDefaultMapLat,
    getDefaultMapLng,
    getClientId,
    getWhoPayYear,
    getWhoPayYearOptions, getLoanType,
} from "../../../services/config-helper";
import PropertySnapshot from "../sections/property-snapshot";
import Projection from "../sections/projection";
import {
    addEditCashflow, exportReport,
    getProperty,
    getWhoPay
} from "../../../services/services";
import config from "config/config";
import {
    downloadReport,
    getMyProfile,
    getNotificationObject,
    getOverviewMoney,
    setCashflowTabState,
    setWhoPayState,
    getWhoPayMoney,
} from "../../../services/helper";
import {Redirect} from 'react-router';
import ReactNotification from "react-notifications-component";
import RightButton from "../../../components/properlytics/right-button";
import LeftButton from "../../../components/properlytics/left-button";
import { Doughnut } from 'react-chartjs-2';
import ExtraRepayment from "../sections/extra-repayment";

/*------------------------------------*/
/* For Select Plugin - (4)            */
/*------------------------------------*/
const groupStyles = {
    borderRadius: '0px',
    background: '#ffffff',
    padding: "0"
};

const Group = (props) => (
    <div style={groupStyles}>
        <components.Group {...props} />
    </div>
);

const style1 = {
    control: (base, state) => ({
        ...base,
        background: state.isFocused ? 0 : 0,
        padding: "0 !important",
        margin: "0 !important",
        border: "0 !important",
        boxShadow: "0 !important",
        "&:hover": {
            border: "0 !important"
        }
    })
};

export default class Cashflow extends React.Component {

    constructor(props) {
        super(props);
        let lat = getDefaultMapLat();
        let long = getDefaultMapLng();
        let bed = getBedroomsType().value;
        let bath = getBathroomsType().value;
        let car = getCarsType().value;
        this.state = {
            is_edit: false,
            property_id: '',
            after_cashflow: 0,
            your_cost: 0,
            year: getWhoPayYear().value,
            principal_amount: 0,
            interest_cost: 0,
            rental_expenses: 0,
            total_cost: 0,
            rent: 0,
            tax_benefit: 0,
            cash: 0,
            cash_weekly: 0,
            total: 0,
            rent_percentage: 0,
            tax_benefit_percentage: 0,
            cash_percentage: 0,
            chart_data: {
                labels: [
                    'You',
                    'Tax Credit',
                    'Tenant',
                ],

                datasets: [{
                    data: [0],
                    backgroundColor: [
                        config.who_pay_color.you,
                        config.who_pay_color.tax_credit,
                        config.who_pay_color.tenant,
                    ],
                    hoverBackgroundColor: [
                        config.who_pay_color.you,
                        config.who_pay_color.tax_credit,
                        config.who_pay_color.tenant,
                    ]
                }]
            },


            previous: false,

            progress: 0,

            /*snap shot*/
            latitude: lat,
            longitude: long,
            property_address: '',
            purchase_price: '',
            bedrooms: bed,
            bathrooms: bath,
            car_space: car,
            adv_link: '',
            images: [],

            /*extra repayment*/
            loan_amount: 0,
            additional_loan_amount: 0,
            interest_rate: 0,
            to_year: 40,
            loan_type: getLoanType().value,
            loan_amount_back: 0,
            additional_loan_amount_back: 0,
            interest_rate_back: 0,
            to_year_back: 40,
            loan_type_back: getLoanType().value,

            /*projection*/
            overview: {},

            property: {},
            loading: false,
            loading_previous: false,
            show_dialog: false,
            redirect_to_dashboard: false,
        }

        //Notification
        this.showNotification = this.showNotification.bind(this);
        this.notificationDOMRef = React.createRef();

        this.onYearChange = this.onYearChange.bind(this);
        this.getWhoPay = this.getWhoPay.bind(this);
        this.setStateData = this.setStateData.bind(this);
        this.onGenerateReport = this.onGenerateReport.bind(this);
        this.generateReport = this.generateReport.bind(this);
        this.onPrevious = this.onPrevious.bind(this);
    }

    showNotification(message, type) {
        this.notificationDOMRef.current.addNotification(getNotificationObject(type, message));
    }

    onYearChange(selected) {
        this.setState({
            year: selected.value,
        });
        this.getWhoPay(this.state.property_id, selected.value);
    }

    getWhoPay(property_id, year) {
        if (property_id !== '') {
            let postData = new FormData();
            postData.append('property_id', property_id);
            postData.append('year', year);
            getWhoPay(postData).then(res => {
                if (res.success) {
                    this.setState(setWhoPayState(res.data))
                }
            });
        }
    }

    generateReport() {

        if (this.state.property_id) {
            let postData = {
                property_id: this.state.property_id
            };

            exportReport(postData).then(res => {
                if (res.success) {
                    downloadReport(res.data);
                } else {
                    this.showNotification(res.message, 'danger');
                }
                this.setState({
                    loading: false
                })
            });
        } else {
            this.showNotification('Please add property detail first, then you can generate report.', 'danger')
        }
    }

    onGenerateReport() {
        if (this.state.is_edit) {
            this.saveData(true);
        } else {
            this.showNotification('Please first enter property detail', 'danger')
        }
    }

    onPrevious() {
        if (this.state.is_edit) {
            this.saveData(false);
        } else {
            this.setState({
                previous: true,
            })
        }
    }

    saveData(isNext = true) {
        let loadingState = (isNext) ? 'loading' : 'loading_previous';

        this.setState({
            [loadingState]: true
        })

        let postData = new FormData();
        postData.append('property_id', this.state.property_id);
        postData.append('after_cf', (this.state.after_cashflow) ? this.state.after_cashflow : 0);
        postData.append('your_cost', (this.state.your_cost) ? this.state.your_cost : 0);
        addEditCashflow(postData).then(res => {
            if (isNext) {
                if (res.success) {
                    this.setState({
                        property: res.data.property
                    })
                    //download report
                    this.generateReport();
                } else {
                    this.showNotification(res.message, 'danger')
                    this.setState({
                        loading: false
                    })
                }
            } else {
                if (res.success) {
                    this.setState({
                        property: res.data.property,
                        previous: true
                    })
                } else {
                    this.showNotification(res.message, 'danger')
                }
                this.setState({
                    [loadingState]: false
                })
            }
        });
    }

    setStateData(property) {
        let stateData = setCashflowTabState(property);
        this.setState(stateData);
        this.setState({
            is_edit: true,
        })
        this.getWhoPay(stateData.property_id, 1);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.match.path === config.site_url.cashflow + '/:id') {
            //is edit call
            let property_id = this.props.match.params.id;
            if (property_id) {

                if (typeof this.props.location.property !== 'undefined' && this.props.location.property) {
                    this.setStateData(this.props.location.property);
                } else {
                    let postData = {
                        property_id: property_id
                    }

                    getProperty(postData).then(res => {
                        if (res.success) {
                            this.setStateData(res.data.property);
                        } else {
                            if (res.error) {
                                if (typeof res.error !== 'undefined' && typeof res.error.auth_code !== 'undefined' &&
                                    res.error.auth_code === 401
                                ) {
                                    this.setState({
                                        redirect_to_dashboard: true,
                                    })
                                }
                            }
                            this.showNotification(res.message, 'danger')
                        }
                    });
                }

            } else {
                this.setState({
                    redirect_to_dashboard: true,
                })
            }
        } else {
            let profile = getMyProfile();
            if (profile) {
                if (profile.account_type === 2) {
                    let client_id = (getClientId()) || '';
                    if (client_id === '') {
                        this.setState({
                            redirect_to_dashboard: true,
                        })
                    }
                }
            }
        }
    }

    render() {

        //if business account and client value not pass as params then redirect to dashboard
        if (this.state.redirect_to_dashboard) {
            return <Redirect to={config.site_url.dashboard} />;
        }

        //redirect to previous tab
        if (this.state.previous) {
            return <Redirect
                to={{
                    pathname: config.site_url.income + '/' + this.state.property_id,
                    property: this.state.property
                }}
            />;
        }


        return <div className="">

            <ReactNotification ref={this.notificationDOMRef} />

            <Row>
                <Col md="6">
                    {/*--------------------------------------------------------------------------------*/}
                    {/*ACQUISITION COST DETAIL*/}
                    {/*--------------------------------------------------------------------------------*/}
                    <div className="prpty_flipper blocks">
                        <div className="block">
                            <div className="plisting_form rect_bx flip_bx">
                                <div className="head_tron d-flex">
                                    <h2 className="head  fx_w t_green">Cashflow & Returns</h2>

                                    <Progress value={this.state.progress} />

                                    <div className="progress-no t_green">{this.state.progress}%</div>
                                </div>

                                <div className="inner_bx">
                                    <Form id="prp_detail">

                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">After-tax Cashflow (P/Y)</h4>
                                                <Input
                                                    type="text"
                                                    className={(this.state.after_cashflow < 0) ? 'form-control t_pink' : 'form-control t_green'}
                                                    placeholder="$0"
                                                    value={getWhoPayMoney(this.state.after_cashflow, 0)}
                                                />
                                            </div>
                                        </div>

                                        <div className="tp_row">
                                            <div className="hd_row d-flex">
                                                <h4 className="sm_tit fx_w">Cost / Income (P/W)</h4>
                                                <Input
                                                    type="text"
                                                    className={(this.state.your_cost < 0) ? 'form-control t_pink' : 'form-control t_green'}
                                                    placeholder="$0"
                                                    value={getWhoPayMoney(this.state.your_cost, 0)}
                                                />
                                            </div>
                                        </div>

                                        <div className="tp_row pr-0 pt-0 pb-0 height60">
                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w who_pay_header">
                                                    WHO PAYS
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        <span>
                                                            <div className="crita">
                                                                <Select
                                                                    defaultValue={getWhoPayYear()}
                                                                    options={getWhoPayYearOptions}
                                                                    components={{ Group }}
                                                                    styles={style1}
                                                                    value={getWhoPayYear(this.state.year)}
                                                                    onChange={this.onYearChange}
                                                                />
                                                            </div>
                                                        </span>
                                                        <div className="whopay-chart-legend">
                                                            <div className="legend">
                                                                <div
                                                                    style={{background: config.who_pay_color.you}}
                                                                    className="color-dot"
                                                                />
                                                                <span>You {this.state.cash_percentage}%</span>
                                                            </div>

                                                            <div className="legend">
                                                                <div
                                                                    style={{background: config.who_pay_color.tax_credit}}
                                                                    className="color-dot"
                                                                />
                                                                <span>Tax Credit {this.state.tax_benefit_percentage}%</span>
                                                            </div>

                                                            <div className="legend">
                                                                <div
                                                                    style={{background: config.who_pay_color.tenant}}
                                                                    className="color-dot"
                                                                />
                                                                <span>Tenant {this.state.rent_percentage}%</span>
                                                            </div>
                                                        </div>
                                                    </h4>

                                                    <span className="d-flex height60 lpad0 ">
                                                        <span className="value t_black pmedium rpad0">
                                                            <div className="who-pay-chart">
                                                                <Doughnut
                                                                    data={this.state.chart_data}
                                                                    options={{ maintainAspectRatio: false, legend: { display: false, labels: { fontFamily: "ProximaNova" } } }}
                                                                />
                                                            </div>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Principal Amount
                                                    </h4>

                                                    <span className="d-flex height60">
                                                        <span className="value t_black pmedium">
                                                            <Input
                                                                type="text"
                                                                className={(this.state.principal_amount < 0) ? 'form-control text-right' : 'form-control text-right'}
                                                                placeholder="$0"
                                                                value={getOverviewMoney(this.state.principal_amount, 0)}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Interest Cost
                                                    </h4>

                                                    <span className="d-flex height60">
                                                        <span className="value t_black pmedium">
                                                            <Input
                                                                type="text"
                                                                className={(this.state.interest_cost < 0) ? 'form-control text-right' : 'form-control text-right'}
                                                                placeholder="$0"
                                                                value={getOverviewMoney(this.state.interest_cost, 0)}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Rental Expenses
                                                    </h4>

                                                    <span className="d-flex height60">
                                                        <span className="value t_black pmedium">
                                                            <Input
                                                                type="text"
                                                                className={(this.state.rental_expenses < 0) ? 'form-control text-right' : 'form-control text-right'}
                                                                placeholder="$0"
                                                                value={getOverviewMoney(this.state.rental_expenses, 0)}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Total Costs
                                                    </h4>

                                                    <span className="d-flex height60">
                                                        <span className="value t_black pmedium">
                                                            <Input
                                                                type="text"
                                                                className={(this.state.total_cost < 0) ? 'form-control text-right' : 'form-control text-right'}
                                                                placeholder="$0"
                                                                value={getOverviewMoney(this.state.total_cost, 0)}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Rent (Tenant)
                                                    </h4>

                                                    <span className="d-flex height60">
                                                        <span className="value t_black pmedium">
                                                            <Input
                                                                type="text"
                                                                className={(this.state.rent < 0) ? 'form-control text-right' : 'form-control text-right'}
                                                                placeholder="$0"
                                                                value={getOverviewMoney(this.state.rent, 0)}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        Tax Credit
                                                    </h4>

                                                    <span className="d-flex height60">
                                                        <span className="value t_black pmedium">
                                                            <Input
                                                                type="text"
                                                                className={(this.state.tax_benefit < 0) ? 'form-control text-right' : 'form-control text-right'}
                                                                placeholder="$0"
                                                                value={getOverviewMoney(this.state.tax_benefit, 0)}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="hd_row d-flex inner_row">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        You (+/-) per annum
                                                    </h4>

                                                    <span className="d-flex height60">
                                                        <span className="value t_black pmedium">
                                                            <Input
                                                                type="text"
                                                                className={(this.state.cash < 0) ? 'form-control text-right t_pink' : 'form-control text-right t_green'}
                                                                placeholder="$0"
                                                                value={getWhoPayMoney(this.state.cash, 0)}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="hd_row d-flex inner_row pad-cash-flow">
                                                <h4 className="sm_tit fx_w">
                                                </h4>

                                                <div className="d-flex w100 height38 brd-top justify-content-between">
                                                    <h4 className="sm_tit fx_w">
                                                        You (+/-) per week
                                                    </h4>

                                                    <span className="d-flex height60">
                                                        <span className="value t_black pmedium">
                                                            <Input
                                                                type="text"
                                                                className={(this.state.cash_weekly < 0) ? 'form-control text-right t_pink' : 'form-control text-right t_green'}
                                                                placeholder="$0"
                                                                value={getWhoPayMoney(this.state.cash_weekly, 0)}
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="tp_row_btm d-flex justify-content-between">
                                            <LeftButton text="Previous" onClick={this.onPrevious} loading={this.state.loading_previous}/>

                                            <RightButton text="Generate Report" onClick={this.onGenerateReport} loading={this.state.loading} bigBtn={true} />
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>


                    <PropertySnapshot
                        property_id={this.state.property_id}
                        lat={this.state.latitude}
                        lng={this.state.longitude}
                        address={this.state.property_address}
                        price={this.state.purchase_price}
                        bed={this.state.bedrooms}
                        bath={this.state.bathrooms}
                        car={this.state.car_space}
                        adv_link={this.state.adv_link}
                        images={this.state.images}
                        showNotification={this.showNotification}
                    />
                </Col>

                <Col md={6}>
                    <Projection
                        property_id={this.state.property_id}
                        progress={this.state.progress}
                        overview={this.state.overview}
                        showNotification={this.showNotification}
                    />

                    <ExtraRepayment
                        property_id={this.state.property_id}
                        loan_amount={this.state.loan_amount}
                        additional_loan_amount={this.state.additional_loan_amount}
                        interest_rate={this.state.interest_rate}
                        to_year={this.state.to_year}
                        loan_type={this.state.loan_type}
                        loan_amount_back={this.state.loan_amount_back}
                        additional_loan_amount_back={this.state.additional_loan_amount_back}
                        interest_rate_back={this.state.interest_rate_back}
                        to_year_back={this.state.to_year_back}
                        loan_type_back={this.state.loan_type_back}
                    />
                </Col>
            </Row>
        </div>

    }
}