import React from 'react';
import {
    Form,
    CustomInput
} from 'reactstrap';
import RightButton from "components/properlytics/right-button";
import LeftButton from "components/properlytics/left-button";
import {addTaxOffsetScales, getTaxOffsetScales} from "../../../../services/services";
import PriceField from "../../../../components/numbers/PriceField";
import PercentageField from "../../../../components/numbers/PercentageField";

export default class TaxOffset extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            tax_offset_slab: {},
            base: 0,
        }
        this.changeTaxOffset = this.changeTaxOffset.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onCheckBoxClick = this.onCheckBoxClick.bind(this);
        this.onInputOtherChange = this.onInputOtherChange.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    changeTaxOffset(key,name,value) {
        let tax_offset_slab = this.state.tax_offset_slab;

        if (typeof tax_offset_slab.slab !== 'undefined') {
            if (typeof tax_offset_slab.slab[key] !== 'undefined') {
                if (name === 'more_than') {
                    value = (value === '') ? '' : parseInt(value);
                    if (typeof tax_offset_slab.slab[key] !== 'undefined') {
                        tax_offset_slab.slab[key].more_than = value;
                    }
                }
                if (name === 'rate') {
                    value = (value === '') ? 0.00 : value;
                    if (typeof tax_offset_slab.slab[key] !== 'undefined') {
                        tax_offset_slab.slab[key].rate = value;
                    }
                }
                if (name === 'is_flat') {
                    if (typeof tax_offset_slab.slab[key] !== 'undefined') {
                        tax_offset_slab.slab[key].is_flat = (value) ? 1 : 0;
                    }
                }
            }
        }

        this.setState({
            tax_offset_slab: tax_offset_slab
        })
    }

    getTaxOffsetFormData(name) {
        let tax_offset_slab = this.state.tax_offset_slab;

        let data = [];
        if (typeof tax_offset_slab.slab !== 'undefined') {
            for (let i=0;i<tax_offset_slab.slab.length;i++) {
                if (name === 'more_than') {
                    data.push(tax_offset_slab.slab[i].more_than);
                }
                if (name === 'rate') {
                    data.push(tax_offset_slab.slab[i].rate);
                }
                if (name === 'is_flat') {
                    data.push(tax_offset_slab.slab[i].is_flat);
                }
            }
        }
        return data;
    }

    onInputChange = (key, name, event) => {
        if (!isNaN(event.target.value) || event.target.value === '') {
            this.changeTaxOffset(key, name, event.target.value)
        }
    }

    onInputOtherChange = (event) => {
        if (!isNaN(event.target.value) || event.target.value === '') {
            this.setState({
                [event.target.name]: event.target.value
            })
        }
    }

    onCheckBoxClick = (key, event) => {
        this.changeTaxOffset(key, 'is_flat', event.target.checked)
    }

    onSave() {
        if (this.props.property_id !== '') {
            this.setState({
                loading: true
            })
            let postData = new FormData();
            postData.append('property_id', this.props.property_id);
            postData.append('tax_option', this.props.it_type);
            let more_than = this.getTaxOffsetFormData('more_than');
            for (let i = 0; i < more_than.length; i++) {
                postData.append('more_than[]', more_than[i]);
            }
            let rate = this.getTaxOffsetFormData('rate');
            for (let i = 0; i < rate.length; i++) {
                postData.append('rate[]', rate[i]);
            }
            let is_flat = this.getTaxOffsetFormData('is_flat');
            for (let i = 0; i < is_flat.length; i++) {
                postData.append('is_flat[]', is_flat[i]);
            }
            postData.append('base', (this.state.base) ? this.state.base : 0);

            addTaxOffsetScales(postData).then(res => {
                if (res.success) {
                    this.props.showNotification(res.message, 'success')
                    this.props.changeITType(this.props.it_type);
                    this.props.hideShowDialog4();
                } else {
                    this.props.showNotification(res.message, 'danger')
                }
                this.setState({
                    loading: false
                })
            });
        } else {
            this.props.showNotification('Please first enter property detail', 'danger')
        }
    }

    getTaxOffset(it_type) {
        if (this.props.property_id !== '') {
            let postData = {
                property_id: this.props.property_id,
                tax_option: it_type
            }

            getTaxOffsetScales(postData).then(res => {
                if (res.success) {
                    this.setState({
                        tax_offset_slab: res.data.tax_offset_slab,
                        base: res.data.tax_offset_slab.base,
                    })
                } else {
                    this.props.showNotification(res.message, 'danger')
                    this.props.hideShowDialog4();
                }
            });
        }
    }

    componentDidMount() {
        this.getTaxOffset(this.props.it_type);
    }

    componentDidUpdate(props) {
        const { it_type, show_dialog4 } = this.props;
        if (props.it_type !== it_type) {
            if (this.props.it_type) {
                this.getTaxOffset(this.props.it_type);
            }
        }

        if (props.show_dialog4 !== show_dialog4) {
            if (this.props.show_dialog4) {
                this.getTaxOffset(this.props.it_type);
            }
        }
    }

    render() {
        let classnames = 'grd_bx popup-wrap flip_bx1 tax_offset';
        if (this.props.show_dialog4) {
            classnames += ' go';
        }

        return <div className={classnames}>
            <div className="rect_bx flip_bx1">
                <div className="head_tron d-flex">
                    <span className="flipbtn t_black rotate">
                        <i className="icon-arrow-up-circle"/>
                    </span>
                    <h2 className="head pl-2 t_black">Low Tax Offset</h2>
                </div>

                <div className="inner_bx">
                    <Form>

                        <div className="tp_row pr-0 pt-0 pb-0 height60">
                            <div className="hd_row d-flex justify-content-between">
                                <h4 className="sm_tit fx_w">Slab</h4>

                                <div className="d-flex w100 height60 pr-40 justify-content-between">
                                    <span className="padl10 t_white">
                                        More Than
                                    </span>
                                    <span className="lmm50 t_white">
                                        Rate
                                    </span>
                                    <span className="text-right rpad0 t_white">
                                        Flat
                                    </span>
                                </div>
                            </div>

                            <MapForm
                                onInputChange={this.onInputChange}
                                onCheckBoxClick={this.onCheckBoxClick}
                                tax_offset_slab={this.state.tax_offset_slab}
                            />
                        </div>

                        <div className="tp_row pr-0 pt-0 pb-0 height60">
                            <div className="hd_row height60 d-flex pr-40 justify-content-between">
                                <div className="sm_tit fx_w">Base</div>

                                <div className="d-flex w100 loc_tab justify-content-between">
                                    <span/>
                                    <span/>
                                    <span className="text-right">
                                        <PriceField
                                            className="form-control text-right rpad0"
                                            placeholder="$0"
                                            name="base"
                                            value={this.state.base}
                                            onChange={this.onInputOtherChange}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tp_row_btm d-flex justify-content-between">
                            <LeftButton text="Close" onClick={this.props.hideShowDialog4} />

                            <RightButton text="Save" onClick={this.onSave} loading={this.state.loading} />
                        </div>
                    </Form>
                </div>
            </div>
        </div>

    }
}

const MapForm = ({tax_offset_slab, onInputChange, onCheckBoxClick}) => {
    if((typeof tax_offset_slab.slab !== 'undefined') && tax_offset_slab.slab.length > 0){
        return tax_offset_slab.slab.map( (item, key) => {
            return (
                <div className="hd_row d-flex justify-content-between inner_row">
                    <div className="sm_tit fx_w"/>
                    <div className="d-flex w100 height60 pr-40 justify-content-between brd-top">
                        <span>
                            <PriceField
                                className="form-control lpad0"
                                placeholder="$0"
                                name="more_than[]"
                                value={item.more_than}
                                onChange={onInputChange.bind(this, key, 'more_than')}
                            />
                        </span>
                        <span>
                            <PercentageField
                                className="form-control lpad0"
                                placeholder="0%"
                                name="rate[]"
                                value={item.rate}
                                onChange={onInputChange.bind(this, key, 'rate')}
                            />
                        </span>
                        <span className="text-right">
                            <Form>
                                <div className="form-check form-check-inline">
                                    <CustomInput
                                        type="checkbox"
                                        name="is_flat[]"
                                        id={"is_flat" + key}
                                        checked={(item.is_flat)}
                                        onClick={onCheckBoxClick.bind(this, key)}
                                    />
                                </div>
                            </Form>
                        </span>
                    </div>
                </div>
            )
        })
    }
    return null;
}

