import React from 'react';
import {
    Input,
} from 'reactstrap';
import Select, { components } from "react-select";
import {
    getRepaymentCalculateBy,
    getRepaymentCalculateByOption,
    getRepaymentLoan,
    getRepaymentLoanType,
} from "../../../services/config-helper";
import Chart from 'react-c3-component';
import 'c3/c3.css';
import {calExtraRepayment} from "../../../services/services";
import {formatMoney} from "../../../services/helper";
import PriceField from "../../../components/numbers/PriceField";

const groupStyles1 = {
    borderRadius: '0px',
    background: '#ffffff',
    padding: "0"
};

const Group1 = (props) => (
    <div style={groupStyles1}>
        <components.Group {...props} />
    </div>
);

const style1 = {
    control: (base, state) => ({
        ...base,
        background: state.isFocused ? 0 : 0,
        padding: "0 !important",
        margin: "0 !important",
        border: "0 !important",
        boxShadow: "0 !important",
        "&:hover": {
            border: "0 !important"
        }
    })
};

export default class ExtraRepayment extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            repayment_loan_type: getRepaymentLoan().value,
            repayment_extra_repayment: 0,
            calculate_by: getRepaymentCalculateBy().value,
            repayment_extra_repayment_start_year: 0,

            loan_amount: props.loan_amount,
            additional_loan_amount: props.additional_loan_amount,
            interest_rate: props.interest_rate,
            to_year: props.to_year,
            loan_type: props.loan_type,
            loan_amount_back: props.loan_amount_back,
            additional_loan_amount_back: props.additional_loan_amount_back,
            interest_rate_back: props.interest_rate_back,
            to_year_back: props.to_year_back,
            loan_type_back: props.loan_type_back,


            column_1: ["Regular", 0],
            column_2: ["Extra", 0],
            interest_saved: 0,
            time_saved: '0yrs',
            increased_repayment_amount: 0,
        }

        this.onLoanTypeChange = this.onLoanTypeChange.bind(this);
        this.onCalculateByChange = this.onCalculateByChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.calculateExtraRepayment = this.calculateExtraRepayment.bind(this);
    }

    onLoanTypeChange(selected) {
        this.setState({
            repayment_loan_type: selected.value
        })

        this.calculateExtraRepayment('repayment_loan_type', selected.value);
    }

    onCalculateByChange(selected) {
        this.setState({
            calculate_by: selected.value
        })

        this.calculateExtraRepayment('calculate_by', selected.value);
    }

    onInputChange(event) {
        let name = event.target.name;
        let value = event.target.value;
        if (!isNaN(value) || value === '') {
            this.setState({
                [name]: value
            });
            this.calculateExtraRepayment(name, value);
        }
    }

    arrangeChartData(data) {
        let column_1 = [];
        let column_2 = [];
        column_1.push("Regular");
        column_2.push("Extra");
        if (data) {

            for (let i=0;i<data.length;i++) {
                if (typeof data[i].normal_repayment !== 'undefined') {
                    column_1.push(data[i].normal_repayment);
                }

                if (typeof data[i].early_repayment !== 'undefined') {
                    column_2.push(data[i].early_repayment);
                }
            }

            this.setState({
                column_1: column_1,
                column_2: column_2,
            });
        }
    }

    calculateExtraRepayment(name, value) {
        if (this.props.property_id !== '') {


            let repayment_loan_type = this.state.repayment_loan_type;
            if(name === 'repayment_loan_type') {
                repayment_loan_type = value;
            }
            let repayment_extra_repayment = (this.state.repayment_extra_repayment !== '') ? this.state.repayment_extra_repayment : 0;
            if(name === 'repayment_extra_repayment') {
                repayment_extra_repayment = (value !== '') ? value : 0;
            }
            let calculate_by = (this.state.calculate_by !== '') ? this.state.calculate_by : 0;
            if(name === 'calculate_by') {
                calculate_by = (value !== '') ? value : 0;
            }
            let repayment_extra_repayment_start_year = (this.state.repayment_extra_repayment_start_year !== '') ? this.state.repayment_extra_repayment_start_year : 0;
            if(name === 'repayment_extra_repayment_start_year') {
                repayment_extra_repayment_start_year = (value !== '') ? value : 0;
            }
            let loan_amount = (this.state.loan_amount !== '') ? this.state.loan_amount : 0;
            if(name === 'loan_amount') {
                loan_amount = (value !== '') ? value : 0;
            }
            let additional_loan_amount = (this.state.additional_loan_amount !== '') ? this.state.additional_loan_amount : 0;
            if(name === 'additional_loan_amount') {
                additional_loan_amount = (value !== '') ? value : 0;
            }
            let interest_rate = (this.state.interest_rate !== '') ? this.state.interest_rate : 0;
            if(name === 'interest_rate') {
                interest_rate = (value !== '') ? value : 0;
            }
            let to_year = (this.state.to_year !== '') ? this.state.to_year : 0;
            if(name === 'to_year') {
                to_year = (value !== '') ? value : 0;
            }
            let loan_type = (this.state.loan_type !== '') ? this.state.loan_type : 0;
            if(name === 'loan_type') {
                loan_type = (value !== '') ? value : 0;
            }
            let loan_amount_back = (this.state.loan_amount_back !== '') ? this.state.loan_amount_back : 0;
            if(name === 'loan_amount_back') {
                loan_amount_back = (value !== '') ? value : 0;
            }
            let additional_loan_amount_back = (this.state.additional_loan_amount_back !== '') ? this.state.additional_loan_amount_back : 0;
            if(name === 'additional_loan_amount_back') {
                additional_loan_amount_back = (value !== '') ? value : 0;
            }
            let interest_rate_back = (this.state.interest_rate_back !== '') ? this.state.interest_rate_back : 0;
            if(name === 'interest_rate_back') {
                interest_rate_back = (value !== '') ? value : 0;
            }
            let to_year_back = (this.state.to_year_back !== '') ? this.state.to_year_back : 0;
            if(name === 'to_year_back') {
                to_year_back = (value !== '') ? value : 0;
            }
            let loan_type_back = (this.state.loan_type_back !== '') ? this.state.loan_type_back : 0;
            if(name === 'loan_type_back') {
                loan_type_back = (value !== '') ? value : 0;
            }


            if (repayment_loan_type !== '') {
                let postData = new FormData();
                postData.append('property_id', this.props.property_id);
                postData.append('repayment_loan_type', repayment_loan_type);
                postData.append('repayment_extra_repayment', repayment_extra_repayment);
                postData.append('calculate_by', calculate_by);
                postData.append('repayment_extra_repayment_start_year', repayment_extra_repayment_start_year);

                postData.append('loan_amount', loan_amount);
                postData.append('additional_loan_amount', additional_loan_amount);
                postData.append('interest_rate', interest_rate);
                postData.append('to_year', to_year);
                postData.append('loan_type', loan_type);
                postData.append('loan_amount_back', loan_amount_back);
                postData.append('additional_loan_amount_back', additional_loan_amount_back);
                postData.append('interest_rate_back', interest_rate_back);
                postData.append('to_year_back', to_year_back);
                postData.append('loan_type_back', loan_type_back);

                calExtraRepayment(postData).then(res => {
                    if (res.success) {
                        this.setState({
                            interest_saved: res.data.extra_repayment.interest_saved,
                            time_saved: res.data.extra_repayment.time_saved,
                            increased_repayment_amount: res.data.extra_repayment.increased_repayment_amount,
                        })
                        this.arrangeChartData(res.data.extra_repayment.chart_data);
                    }
                });
            }
        }
    }

    componentDidUpdate(props) {
        const {
            loan_amount, interest_rate, to_year, loan_type,
            loan_amount_back, interest_rate_back, to_year_back, loan_type_back,
        } = this.props;
        if (props.loan_amount !== loan_amount) {
            this.setState({
                loan_amount: this.props.loan_amount
            });
            this.calculateExtraRepayment('loan_amount', this.props.loan_amount);
        }

        if (props.interest_rate !== interest_rate) {
            this.setState({
                interest_rate: this.props.interest_rate
            });
            this.calculateExtraRepayment('interest_rate', this.props.interest_rate);
        }

        if (props.to_year !== to_year) {
            this.setState({
                to_year: this.props.to_year
            });
            this.calculateExtraRepayment('to_year', this.props.to_year);
        }

        if (props.loan_type !== loan_type) {
            this.setState({
                loan_type: this.props.loan_type
            });
            this.calculateExtraRepayment('loan_type', this.props.loan_type);
        }

        if (props.loan_amount_back !== loan_amount_back) {
            this.setState({
                loan_amount_back: this.props.loan_amount_back
            });
            this.calculateExtraRepayment('loan_amount_back', this.props.loan_amount_back);
        }

        if (props.interest_rate_back !== interest_rate_back) {
            this.setState({
                interest_rate_back: this.props.interest_rate_back
            });
            this.calculateExtraRepayment('interest_rate_back', this.props.interest_rate_back);
        }

        if (props.to_year_back !== to_year_back) {
            this.setState({
                to_year_back: this.props.to_year_back
            });
            this.calculateExtraRepayment('to_year_back', this.props.to_year_back);
        }

        if (props.loan_type_back !== loan_type_back) {
            this.setState({
                loan_type_back: this.props.loan_type_back
            });
            this.calculateExtraRepayment('loan_type_back', this.props.loan_type_back);
        }
    }

    render() {

        //at the time of the add property
        return (
            <div className="prpty_projection blocks ui-sortable">
                <div className="block">
                    <div className="plisting_form rect_bx flip_bx">
                        <div className="head_tron d-flex">
                            <h2 className="head  fx_w t_green">Extra Repayments</h2>
                        </div>

                        <div className="extra_repayment_bx">
                            <div className="tp_row pbtm0 ptop0">
                                <div className="hd_row d-flex pbtm0 ptop0">
                                    <div className="left_bx slct_bx">
                                        <Select
                                            defaultValue={getRepaymentLoan()}
                                            options={getRepaymentLoanType}
                                            components={{ Group1 }}
                                            styles={style1}
                                            onChange={this.onLoanTypeChange}
                                            value={getRepaymentLoan(this.state.repayment_loan_type)}
                                        />
                                    </div>

                                    <div className="right_bx">
                                        <h4 className="sm_tit fx_w">Interest Saved</h4>
                                        <span>${formatMoney(this.state.interest_saved)}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="tp_row pbtm0 ptop0">
                                <div className="hd_row d-flex pbtm0 ptop0">
                                    <div className="left_bx repayment-amount slct_bx">
                                        <div className="label-txt">Amount</div>
                                        <div className="crita">
                                            <Select
                                                defaultValue={getRepaymentCalculateBy()}
                                                options={getRepaymentCalculateByOption}
                                                components={{ Group1 }}
                                                styles={style1}
                                                value={getRepaymentCalculateBy(this.state.calculate_by)}
                                                onChange={this.onCalculateByChange}
                                            />
                                        </div>
                                        <PriceField
                                            className="form-control repayment-input"
                                            placeholder="$0"
                                            name="repayment_extra_repayment"
                                            value={this.state.repayment_extra_repayment}
                                            onChange={this.onInputChange}
                                        />
                                    </div>

                                    <div className="right_bx">
                                        <h4 className="sm_tit fx_w">Time Saved</h4>
                                        <span>{this.state.time_saved}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="tp_row pbtm0 ptop0">
                                <div className="hd_row d-flex pbtm0 ptop0">
                                    <div className="left_bx repayment-amount-start slct_bx">
                                        <div className="label-txt">Starting On Year</div>
                                        <Input
                                            type="text"
                                            className="repayment-input"
                                            placeholder="1"
                                            name="repayment_extra_repayment_start_year"
                                            id="repayment_extra_repayment_start_year"
                                            value={this.state.repayment_extra_repayment_start_year}
                                            onChange={this.onInputChange}
                                        />
                                    </div>

                                    <div className="right_bx">
                                        <h4 className="sm_tit fx_w">New {(parseInt(this.state.calculate_by) === 1) ? 'Monthly' : 'Weekly'} Amount</h4>
                                        <span>${formatMoney(this.state.increased_repayment_amount)}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="repayment-chart">
                                <Chart
                                    config={{
                                        data: {
                                            columns: [
                                                this.state.column_1,
                                                this.state.column_2
                                            ],
                                            types: { Regular: "area-spline", Extra: "area-spline" }
                                        },
                                        rid: { y: { show: !0 } },
                                        size: { height: 400 },
                                        point: { r: 3 },
                                        color: { pattern: ["#00c880", "#007f4e"] },
                                        axis: {
                                            x: {
                                                label: {
                                                    text: 'Years',
                                                    position: 'outer-center'
                                                }
                                            },
                                            y: {
                                                tick: {
                                                    format: function (y) { return y + 'k'; }
                                                },
                                                label: {
                                                    text: 'Amounts',
                                                    position: 'outer-middle'
                                                }
                                            },
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
